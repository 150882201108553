import React from 'react'

const AvatarImg = ({
    src,
    alt,
    size
}) => {
    return (
        <div className="avatar-sm icon-effect">
            <div className="avatar-title bg-transparent text-success rounded-circle h1">
                {src ?
                    <img src={src} alt={alt} width={size} height={size} style={{borderRadius: src}} />
                    : <i className="ri-user-follow-line" style={{fontSize: size}}></i>}
            </div>
        </div>
    )
}

export default AvatarImg