import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "reactstrap";
import { useUserStore } from "../../../store/store";
import styled from "styled-components";

const PhoneLi = styled.li`
  list-style: none;
  padding: 5px 0px;
  background-color: #f5f5f5;
  border-radius: 5px;
  display: inline-block;
  margin: 5px;
  padding: 5px 8px;
  font-size: 12px;
  cursor: pointer;
`;

const DeleteBtn = styled.button`
  background-color: transparent;
  color: #ff5a5f;
  font-weight: bold;
  border: none;
  /* border-radius: 50%; */
  width: 15px;
  height: 15px;
  font-size: 10px;
  margin-left: 10px;
  cursor: pointer;
`;

function telValidator(args) {
  console.log("telValidator", args);
  if (/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(args)) return true;
  return false;
}
const confirm = (message = null, onConfirm, onCancel) => {
  if (!onConfirm || typeof onConfirm !== "function") return;
  if (onCancel && typeof onCancel !== "function") return;
  const confirmAction = () => {
    if (window.confirm(message)) onConfirm();
    else onCancel();
  };
  return confirmAction;
};

export default function GiftB2BList({ info, setInfo, visible, setVisible, needRefresh, sendItem, sendCount = 1 }) {
  const [phoneNum, setPhoneNum] = useState("");
  const [user] = useUserStore((state) => [state.user]);
  const [hashTags, setHashTags] = useState([]);
  const [showDescription, setShowDescription] = useState(false);

  const handleInputChange = (event) => {
    setPhoneNum(event.target.value);
  };

  const handleEnterKeyPress = (event) => {
    event.preventDefault();
    extractPhoneNumbers();
  };

  const extractPhoneNumbers = () => {
    const phoneNumberList = phoneNum
      .split(/[\s,]+/) // 콤마와 공백으로 분리
      .map((number) => {
        if (isValidPhoneNumber(number)) {
          return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        }
        return null;
      })
      .filter((number) => number !== null);

    // console.log("phoneNumberList", phoneNumberList);
    const remainingStock = info.stock - hashTags.length * sendCount <= 0 ? 0 : info.stock - hashTags.length * sendCount;
    const numbersToAdd = phoneNumberList.slice(0, remainingStock);

    setHashTags((prevTags) => {
      // remove duplicates
      const uniqueNumbers = new Set([...prevTags, ...numbersToAdd]);
      return [...uniqueNumbers];
    });
    setPhoneNum("");
  };

  const handleDeleteTag = (index) => {
    setHashTags((prevTags) => prevTags.filter((tag, i) => i !== index));
  };

  const isValidPhoneNumber = (number) => {
    const regex1 = /^010\d{8}$/; // 010으로 시작하는 11자리 숫자
    const regex2 = /^010-\d{4}-\d{4}$/; // 010-XXXX-XXXX 형식

    if (regex1.test(number)) {
      return true;
    }
    if (regex2.test(number)) {
      return true;
    }

    return false;
  };

  // const isValidPhoneNumber = (number) => {
  //   if (number.length === 10) {
  //     setPhoneNum(number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
  //     return true;
  //   }
  //   if (number.length === 13) {
  //     setPhoneNum(number.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
  //     return true;
  //   }

  //   return false;
  // };

  // useEffect(() => {
  //   console.log("phoneNum", phoneNum);
  // }, [phoneNum]);

  return (
    <div>
      <div className="mx-3 my-3">
        <div className="d-flex justify-content-start cursor-pointer btn-ghost" onClick={() => setShowDescription((prev) => !prev)}>
          {showDescription ? "사용방법 닫기 >" : "사용방법 보기 >"}
        </div>
        {showDescription ? (
          <>
            <div className="my-3 d-flex justify-content-center">
              <h4 className="mb-sm-0" style={{ fontFamily: "Gmarket-M", fontSize: "16px" }}>
                B2B 대량 선물
              </h4>
            </div>
            <div>
              <p style={{ fontSize: "14px", lineHeight: "24px", textAlign: "center" }}>
                입력하신 전화번호가 기존의 가입된 회원일 경우 쿠폰이 선물됩니다.
                <br />
                가입되지 않은 전화번호일 경우, 초대장이 전송됩니다.
                <br />
                *하단에 전화번호를 붙여넣기 후 생성버튼을 눌러주세요.
                <br />
                ex) 010-1234-5678, 010-1234-5678, 010-1234-5678
              </p>
            </div>
          </>
        ) : null}

        <textarea
          placeholder="전화번호를 입력후 생성버튼 (콤마 또는 줄바꿈으로 번호 구분)"
          value={phoneNum}
          onChange={handleInputChange}
          style={{ width: "100%", border: "1px solid #feedea", height: "80px", padding: "10px" }}
        />
        <Button
          style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f", fontSize: "12px", float: "right" }}
          color="light ms-2 my-2"
          onClick={handleEnterKeyPress}
        >
          생성
        </Button>
        <Form>
          <ul style={{ paddingLeft: 0, border: "1px solid #feedea", height: "60px", overflowY: "scroll" }}>
            {hashTags.map((tag, index) => (
              <PhoneLi key={index} onClick={() => handleDeleteTag(index)}>
                {tag} <DeleteBtn>X</DeleteBtn>
              </PhoneLi>
            ))}
          </ul>

          <div style={{ color: "GrayText", marginLeft: "5px" }}>
            (총 <span style={{ color: hashTags.length * sendCount > info.stock ? "#ff5a5f" : "#808080" }}>{hashTags.length * sendCount}</span>개 / {info.stock}개)
          </div>
        </Form>
      </div>
      <div className="d-flex justify-content-center mx-1 mb-4">
        <Button
          style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
          color="light ms-2 my-2"
          onClick={() => {
            setPhoneNum("");
            setVisible(false);
            setInfo({});
          }}
        >
          닫기
        </Button>

        <Button
          style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
          onClick={() => {
            if (hashTags.length === 0) {
              alert("보내실 번호를 입력해주세요.");
              return;
            }

            if (hashTags.length * sendCount > info.stock) {
              alert("총 발송 가능한 개수는 " + info.stock + "개 입니다.");
              return;
            }

            confirm(
              `쿠폰을 선물하시겠습니까?`,
              () => {
                if (info) {
                  const phone_num = hashTags.map((phoneNum) => phoneNum.replaceAll("-", ""));

                  sendItem(info.id, phone_num)
                    .then((data) => {
                      if (data.status === "ok") {
                        setPhoneNum("");
                        setInfo({});
                        setVisible(false);
                        needRefresh();
                      } else {
                        alert("전송 실패 " + JSON.stringify(data));
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                      alert("전송 실패");
                    });
                }
              },
              () => {}
            )();
          }}
          className="btn btn-success ms-2 my-2"
        >
          선물하기
        </Button>
      </div>
    </div>
  );
}
