import React from "react";
import { Modal, Button, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import { API, BACKEND_HOST } from "../../api";
import { getAccessToken } from "../../api/auth";

const PurchaseRecordModal = (props) => {
  const [checkModal, setCheckModal] = React.useState(false);
  const [checkQrCode, setCheckQrCode] = React.useState("");

  const { info } = props;

  const togModal = () => {
    props.setVisible(!props.visible);
    setCheckQrCode("");
  };

  const togCheckModal = () => {
    setCheckModal(!checkModal);
  };

  const confirm = (message = null, onConfirm, onCancel) => {
    if (!onConfirm || typeof onConfirm !== "function") {
      return;
    }
    if (onCancel && typeof onCancel !== "function") {
      return;
    }

    const confirmAction = () => {
      if (window.confirm(message)) {
        onConfirm();
      } else {
        onCancel();
      }
    };

    return confirmAction;
  };

  const cancelReserv = () => {
    const accessToken = getAccessToken();

    API.post(
      "/package/cancel-reservation/",
      { id: info.id },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((data) => {
        console.log(data);
        if (data.status === 200) {
          alert("취소 요청이 처리 되었습니다.");
          props.setInfo({});
          props.setVisible(false);
          props.needRefresh();
        } else if (data["error"]) {
          alert("취소 요청이 처리되지 않았습니다. " + data["error"]);
        } else {
          alert("취소 요청이 처리되지 않았습니다. " + data);
        }
      })
      .catch((error) => console.log(error));
  };

  const changeReserv = async () => {
    try {
      const accessToken = getAccessToken();
      const response = await API.post(
        `/package/change-reservation/`,
        { id: info.id, reservation: "입장" },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      console.log(error);
      // 오류 처리를 추가하거나 throw를 사용하여 상위 호출자에게 전달할 수 있습니다.
    }
  };

  const getQrCode = async () => {
    const accessToken = getAccessToken();
    const _res = await API.get(`${BACKEND_HOST}/package/reservation-qr/${info.id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = _res.data;
    return data;
  };

  return (
    <React.Fragment>
      <Modal toggle={togModal} isOpen={props.visible} modalClassName="fadeInUp" centered>
        <div className="mx-3 my-4">
          {/* qr코드 받아올 시에 그려야함 */}
          {!info.depositRequired ? (
            <div className="my-3 d-flex justify-content-center  ">
              {checkQrCode ? (
                <QRCodeSVG value={checkQrCode} />
              ) : (
                <Button
                  onClick={() => {
                    getQrCode().then((obj) => {
                      console.log("qr", obj);
                      setCheckQrCode(obj.qr);
                    });
                  }}
                  className="btn btn-warning"
                >
                  QR 생성하기
                </Button>
              )}
            </div>
          ) : null}
          <ul className="list-group">
            <li className="list-group-item">예약ID : {info.id}</li>
            <li className="list-group-item">예약상품 : {info.name}</li>
            <li className="list-group-item">예약일 : {info.reservationDate}</li>
            <li className="list-group-item">자리 : {info.spot}</li>
            {info.options && info.options.length ? (
              <li className="list-group-item">
                <span>옵션: </span>
                {info.options.map((item, idx, obj) => {
                  return (
                    <span key={idx}>
                      {item.name}
                      {idx !== obj.length - 1 ? ", " : ""}
                    </span>
                  );
                })}
              </li>
            ) : null}
            {/* <li className="list-group-item">결제금액 : {info.amount}</li> */}
            <li className="list-group-item">
              {/* {console.log("info", info)} */}
              결제금액{info.options && info.options.length ? "(옵션포함)" : ""} :
              {info.amount +
                (info.options
                  ? info.options
                      .map((item) => {
                        return parseInt(item.price);
                      })
                      .reduce((sum, currValue) => {
                        return sum + currValue;
                      }, 0)
                  : 0)}
            </li>
            <li className="list-group-item">
              예약취소시 취소금액{info.options && info.options.length ? "(옵션포함)" : ""} :
              {info.cancelAmount +
                (info.options
                  ? info.options
                      .filter((item) => item.type !== "deposit")
                      .map((item) => {
                        return parseInt(item.price);
                      })
                      .reduce((sum, currValue) => {
                        return sum + currValue;
                      }, 0)
                  : 0) +
                (!!info.cancelAmount && info.options && info.options.find((item) => item.type === "deposit") ? info.options.find((item) => item.type === "deposit").price : 0)}
            </li>
            <li className="list-group-item">상태 : {info.state}</li>
          </ul>
          {info.depositRequired && !props.isCancel ? (
            <div className="mt-4">
              <p style={{ fontFamily: "Gmarket-M" }}>체크인 하기전에 청소보증금을 결제해 주세요.</p>
              <p style={{ textAnchor: "middle", lineHeight: "26px", fontSize: "14px" }}>
                <i className="ri ri-information-line label-icon align-middle fs-20" style={{ color: "#ff5a5f" }}></i>&nbsp;{" "}
                <span style={{ color: "#ff5a5f", fontFamily: "Gmarket-M" }}>청소보증금이란?</span>
                <br />
                청결한 시설 관리 및 비품 관리를 위해 패키지 상품 구매 시 청소보증금이 결제됩니다.
                <br />
                시설 이용 후 정리를 완료하고 체크아웃 버튼을 누르면 관리자가 정리 사항을 확인합니다.
                <br />
                <span style={{ color: "red" }}>체크아웃 요청이 승인 되면 자동 환불됩니다.</span>
              </p>
            </div>
          ) : null}
        </div>
        <div className="d-flex justify-content-center mx-1 pb-4">
          <Button
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
            color="light ms-2 my-2"
            onClick={() => {
              props.setInfo({});
              props.setVisible(!props.visible);
              setCheckQrCode("");
            }}
          >
            닫기
          </Button>
          {props.isCancel ? (
            <Button style={{ fontFamily: "Gmarket-M" }} color="danger ms-2 my-2" onClick={confirm(`정말로 취소하시겠습니까?`, cancelReserv, () => {})} disabled={info.cancelAmount === 0}>
              {info.cancelAmount === 0 ? "당일 취소 불가" : "예약취소"}
            </Button>
          ) : info.depositRequired ? (
            <Button
              style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f" }}
              onClick={() => {
                window.location.href = `/add-options-order?id=${info.id}&deposit=true`;
              }}
              className="btn btn-success ms-2 my-2"
            >
              청소보증금 결제
            </Button>
          ) : (
            <Button
              style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f" }}
              // onClick={() => {
              //   props.setVisible(!props.visible);
              //   setCheckModal(!checkModal);
              // }}
              onClick={() => {
                const confirmReserv = window.confirm("정말 사용하시겠습니까?");
                if (confirmReserv) {
                  changeReserv().then((data) => {
                    if (data.result === "ok") {
                      alert("예약 상태가 변경되었습니다!");
                      props.setVisible(!props.visible);
                      // alert("예약 상태가 변경되었습니다! " + JSON.stringify(obj))
                      props.needRefresh();
                    } else {
                      alert("예약 상태 변경 실패");
                    }
                  });
                }
              }}
              className="btn btn-success ms-2 my-2"
            >
              체크인
            </Button>
          )}
        </div>
      </Modal>
      {/* <Modal toggle={togCheckModal} isOpen={checkModal} centered>
        <div className="my-4 mx-2 px-3">
          <h5 className="modal-title mb-3" id="staticBackdropLabel">
            예약상품 사용
          </h5>
          <p className="text-muted mb-4">정말 사용하시겠습니까?</p>
          <p className="text mb-4">체크아웃 요청을 하지 않으면 청소보증금 환불이 불가할 수 있습니다.</p>
          <div className="hstack gap-2 justify-content-center">
            <Button
              style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
              to="#"
              className="btn fw-medium"
              onClick={() => {
                setCheckModal(false);
              }}
            >
              <i className="ri-close-line me-1 align-middle"></i> 사용안함{" "}
            </Button>
            <Button
              to="#"
              style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
              className="btn btn-success"
              onClick={() => {
                changeReserv().then((obj) => {
                  // console.log
                  alert("예약 상태가 변경되었습니다!");
                  // alert("예약 상태가 변경되었습니다! " + JSON.stringify(obj))
                  props.needRefresh();
                });
                setCheckModal(false);
              }}
            >
              <i className="ri-check-line me-1 align-middle "></i>체크인
            </Button>
          </div>
        </div>
      </Modal> */}
    </React.Fragment>
  );
};
export default PurchaseRecordModal;
