import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigation, useParams, useRoutes } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  UncontrolledCarousel,
  InputGroup,
  InputGroupText,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";

import classnames from "classnames";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import { DetailImgModal, DetailInfoModal, DetailUseAdviceModal } from "../../Components/Common/ManagerModal";
import { API, BACKEND_HOST } from "../../api";
import Navbar from "../../Components/Common/navbar";
import QuantityButtonInput from "../../Components/Common/QuantityButtonInput";
SwiperCore.use([FreeMode, Navigation, Thumbs]);

function ShoppingDetail(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  // const product = location.state?.product;

  const [product, setProduct] = useState(location.state?.product);

  const [quantity, setQuantity] = useState(1);
  const [selectOptions, setSelectOptions] = useState([]); // 옵션

  // const [detailImageEditModal, setDetailImageEditModal] = useState(false);
  // const [detailInfoModal, setDetailInfoModal] = useState(false);
  // const [detailUseAdviceModal, setDetailUseAdviceModal] = useState(false);

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get(`/shop/product/${id}/`);
        const data = response.data;

        console.log("shop product id", id);
        setProduct(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    console.log("product", product);
    if (product?.quantity) setQuantity(product.quantity);
  }, [product]);

  const [optionOpen, setOptionOpen] = useState("1");
  const optionToggle = (id) => {
    if (optionOpen === id) {
      setOptionOpen();
    } else {
      setOptionOpen(id);
    }
  };

  const handleSelectOption = (checked, id, price, value, quantity, is_required) => {
    if (checked) {
      setSelectOptions([...selectOptions, { id: id, price: price, value: value, quantity: quantity, is_required: is_required }]);
    } else {
      setSelectOptions(selectOptions.filter((option) => option.id !== id));
    }
  };

  useEffect(() => {
    console.log("selectOptions", selectOptions);
  }, [selectOptions]);
  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
          <Navbar />

          <Container fluid style={{ minHeight: "100vh", paddingBottom: "300px" }}>
            <Row>
              <Col lg={12}>
                {/* {(!!props.permission && props.permission.permission > 0) ?
                            <Button className="btn btn-soft-warning mb-3 border-0 position-absolute top-25 end-0"
                                style={{ zIndex: "2" }}
                                onClick={() => setDetailImageEditModal(!detailImageEditModal)}
                            >
                                상세이미지 수정
                            </Button>
                            : null
                        } */}
                <Card>
                  <CardBody className="px-0">
                    <Row className="gx-lg-5">
                      <Col xl={12} md={12} className="mx-auto" style={{ padding: 0, maxWidth: "640px" }}>
                        <div className="product-img-slider sticky-side-div">
                          {product?.images?.length ? (
                            <UncontrolledCarousel
                              interval={4000}
                              items={
                                product.images
                                  ? product.images.map((img) => ({
                                      src: `${img.image}`,
                                      // altText: img.name,
                                      // caption: img.name,
                                      key: img.id,
                                    }))
                                  : []
                              }
                            />
                          ) : (
                            <img src={`${product.thumbnail}`} style={{ width: "100%", height: "100%", resize: "contain" }} />
                          )}
                        </div>
                      </Col>

                      <Col xl={12}>
                        <div className="mt-xl-2 mt-5">
                          <div className="d-flex">
                            <div className="flex-grow-1 mt-3">
                              <h3 style={{ fontFamily: "Gmarket-B" }}>{product.name}</h3>
                            </div>
                          </div>
                          <div className="product-meta">
                            <ul className="list-unstyled mb-0">
                              {/* <li className="mb-1">
                                                                <i className="uil uil-clipboard-notes me-1"></i>
                                                                <b>카테고리</b> : {product.category}
                                                            </li> */}
                              {/* <li className="mb-3">
                                {product.summery || ""}{" "}
                                <span className="" style={{ color: "gray" }}>
                                  (<b>남은 수량</b> : {product.remaining_stock}개)
                                </span>
                              </li> */}
                              {product.no_sale_price ? (
                                <li className="mb-1 d-flex">
                                  <h5 className="mb-0" style={{ textDecorationLine: "line-through", fontFamily: "Gmarket-M" }}>
                                    {new Intl.NumberFormat().format(product.no_sale_price)}원
                                  </h5>
                                  <h5 className="mb-0 ms-2" style={{ color: "red" }}>
                                    {Math.round(product.sale_rate) || "0"}%
                                  </h5>
                                </li>
                              ) : null}
                              <li className="mb-1">
                                <h2 className="mb-4" style={{ fontFamily: "Gmarket-M", fontSize: "20px" }}>
                                  <b>{new Intl.NumberFormat().format(product.price)}원</b>
                                </h2>
                              </li>
                            </ul>

                            {product.variations?.length ? (
                              <UncontrolledAccordion open={optionOpen} toggle={optionToggle}>
                                {product.variations.map((variation, idx) => (
                                  <AccordionItem key={idx}>
                                    <AccordionHeader targetId={idx} style={{ fontFamily: "Gmarket-M" }}>
                                      {variation.is_required ? "(*필수선택) " + variation.name : variation.name}
                                    </AccordionHeader>
                                    <AccordionBody accordionId={idx}>
                                      <div>
                                        {variation.items.map((optionItem, idx) => {
                                          return (
                                            <div
                                              key={optionItem.id}
                                              className="form-check my-2 mx-2"
                                              dir="ltr"
                                              style={{ borderBottom: "1px solid #ededed", paddingBottom: "10px", paddingTop: "10px" }}
                                            >
                                              <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={`${optionItem.id}`}
                                                onChange={(e) => {
                                                  handleSelectOption(e.currentTarget.checked, optionItem.id, optionItem.price, optionItem.value, 1, variation.is_required);
                                                }}
                                              />
                                              <Label for={`${optionItem.id}`} style={{ fontSize: "12px", fontFamily: "Gmarket-M", color: "#1d1d1d" }}>
                                                {optionItem.value} <br />
                                                {optionItem.price > 0 ? "+" + optionItem.price : optionItem.price}원
                                              </Label>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </AccordionBody>
                                  </AccordionItem>
                                ))}
                              </UncontrolledAccordion>
                            ) : null}
                          </div>

                          <div className="product-content mt-5">
                            {/* <h5 className="fs-14 mb-3">Information :</h5> */}

                            <Nav tabs className="nav-tabs-custom nav-primary">
                              <NavItem>
                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "1" })} onClick={() => toggleCustom("1")}>
                                  상세정보
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "2" })} onClick={() => toggleCustom("2")}>
                                  상품정보
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "3" })} onClick={() => toggleCustom("3")}>
                                  요약정보
                                </NavLink>
                              </NavItem>
                            </Nav>
                            {/* 이거 없애면됨 */}
                            <TabContent activeTab={customActiveTab} className="pt-4" id="nav-tabContent">
                              <TabPane id="nav-detail" tabId="1">
                                {/* {!!props.permission && props.permission.permission > 0 ?
                                                            <Button className="btn btn-soft-info mb-3 border-0 position-absolute top-25 end-0"
                                                                style={{ zIndex: "2" }}
                                                                onClick={() => setDetailInfoModal(!detailInfoModal)}
                                                            >
                                                                상세정보 수정
                                                            </Button>
                                                            : null
                                                        } */}
                                {product.detail_img ? (
                                  <div
                                    style={{
                                      width: "100%",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <img src={`${product.detail_img}`} style={{ width: "100%" }} alt="detail_img" />
                                  </div>
                                ) : null}
                                {/* <div dangerouslySetInnerHTML={{ __html: product.descript }} /> */}
                              </TabPane>
                              <TabPane id="nav-speci" tabId="2" className="px-3">
                                {/* {!!props.permission && props.permission.permission > 0 ?
                                                            <Button className="btn btn-soft-info mb-3 border-0 position-absolute top-25 end-0"
                                                                style={{ zIndex: "2" }}
                                                                onClick={() => setDetailUseAdviceModal(!detailUseAdviceModal)}
                                                            >
                                                                이용안내 수정
                                                            </Button>
                                                            : null
                                                        } */}
                                <div dangerouslySetInnerHTML={{ __html: product["info_notice"] }} />
                                {/* <div>{JSON.stringify(product["info_notice_list"])}</div> */}
                                <div className="mt-3">
                                  {/* <h5 style={{ fontFamily: "Gmarket-M", paddingBottom: "15px" }}>상품정보 제공고시</h5> */}
                                  {product["info_notice_list"].map((item, idx) => {
                                    // 이용안내 리스트
                                    return (
                                      <div
                                        key={idx}
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          borderBottom: "1px solid #efefef",
                                          // padding: "8px 0",
                                          gap: "20px",
                                        }}
                                      >
                                        <p style={{ color: "#6d6d6d", fontWeight: "500", width: "120px", backgroundColor: "#fafafa", padding: "20px", margin: 0, fontSize: "13px" }}>{item.key}</p>
                                        <p style={{ color: "#1d1d1d", margin: 0, fontSize: "13px" }}>{item.value}</p>
                                      </div>
                                    );
                                  })}
                                </div>
                              </TabPane>

                              <TabPane id="nav-speci" tabId="3" className="px-3">
                                <div className="mt-4" dangerouslySetInnerHTML={{ __html: product.descript }} />
                              </TabPane>
                            </TabContent>
                          </div>

                          <div style={{ height: "80px" }}></div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {product.variations?.length ? (
              <Row
                className="position-fixed d-flex flex-row justify-content-center"
                //   style={{ bottom: "20px", left: "calc(50vw + 6px)", width: "calc(100vw - 40px)", transform: "translate(-50%)", zIndex: 999 }}
                style={{
                  maxWidth: "640px",
                  bottom: "0px",
                  width: "100%",
                  zIndex: 999,
                  backgroundColor: "#fff",
                  paddingBottom: "25px",
                  paddingTop: "25px",
                  alignItems: "center",
                  borderTop: "1px solid #ededed",
                  // boxShadow: "-8px 2px 11px 0px #e2e2e2",
                }}
              >
                {selectOptions.length ? (
                  <div style={{ maxHeight: "200px", overflowY: "scroll", width: "100%" }}>
                    {selectOptions.map((option, idx) => {
                      return (
                        <div
                          key={idx}
                          style={{
                            width: "95%",
                            padding: "20px 20px",
                            margin: "8px auto",
                            borderRadius: "10px",
                            borderBottom: "1px solid #ededed",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            backgroundColor: "#f4f4f4",
                          }}
                        >
                          <div>
                            <span>{option.value} </span>

                            <span>({option.price > 0 ? "+" + option.price.toLocaleString() : option.price.toLocaleString()}원)</span>
                          </div>
                          <QuantityButtonInput
                            size={28}
                            quantity={selectOptions[idx].quantity}
                            onChange={(value) => {
                              const _newSelectOptions = [...selectOptions];
                              if (value > product.remaining_stock) {
                                alert("구매 가능 개수를 초과하였습니다.");
                                return;
                              }
                              if (value < 1) {
                                alert("1개 이상 선택해주세요.");
                                return;
                              }
                              _newSelectOptions[idx].quantity = value;
                              setSelectOptions(_newSelectOptions);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                <Button
                  color="danger"
                  className="btn fw-bold fs-15"
                  style={{ width: "90%", backgroundColor: "#F6475F", border: "none", padding: "14px 0px", borderRadius: "12px", fontFamily: "Gmarket-M" }}
                  size="lg"
                  disabled={product.remaining_stock <= 0}
                  onClick={() => {
                    if (selectOptions.some((option) => option.is_required)) {
                      navigate(`/order-shop`, {
                        state: {
                          products: [{ ...product, selectVariation: selectOptions }],
                        },
                      });
                    } else {
                      alert("필수옵션을 선택해주세요.");
                      return;
                    }
                  }}
                >
                  {product.remaining_stock <= 0 ? "품절" : "구매하기"}
                </Button>
              </Row>
            ) : (
              <Row
                className="position-fixed d-flex flex-row justify-content-center"
                //   style={{ bottom: "20px", left: "calc(50vw + 6px)", width: "calc(100vw - 40px)", transform: "translate(-50%)", zIndex: 999 }}
                style={{
                  maxWidth: "640px",
                  bottom: "0px",
                  width: "100%",
                  zIndex: 999,
                  backgroundColor: "#fff",
                  paddingBottom: "25px",
                  paddingTop: "25px",
                  alignItems: "center",
                  borderTop: "1px solid #ededed",
                  // boxShadow: "-8px 2px 11px 0px #e2e2e2",
                }}
              >
                {product.remaining_stock <= 0 ? null : (
                  <div style={{ width: "25%", display: "inline-block", paddingInline: "8px", marginRight: "25px" }}>
                    {/* <InputGroup>
                                    <InputGroupText addonType="prepend">수량</InputGroupText>
                                    <Input value={quantity} type="number" step={1} min={1} onChange={(e) => { setQuantity(e.currentTarget.value) }} />
                                </InputGroup> */}
                    <QuantityButtonInput
                      size={28}
                      quantity={quantity}
                      onChange={(value) => {
                        if (value > product.remaining_stock) {
                          alert("구매 가능 개수를 초과하였습니다.");
                          return;
                        }
                        setQuantity(value);
                      }}
                    />
                  </div>
                )}

                <Button
                  color="danger"
                  className="btn fw-bold fs-15"
                  style={{ width: product.remaining_stock <= 0 ? "90%" : "60%", backgroundColor: "#F6475F", border: "none", padding: "14px 0px", borderRadius: "12px", fontFamily: "Gmarket-M" }}
                  size="lg"
                  disabled={product.remaining_stock <= 0}
                  onClick={() => {
                    navigate(`/order-shop`, {
                      state: {
                        products: [{ ...product, quantity: quantity }],
                      },
                    });
                  }}
                >
                  {product.remaining_stock <= 0 ? "품절" : "구매하기"}
                </Button>
              </Row>
            )}
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ShoppingDetail;
