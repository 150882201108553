import React, { useEffect } from "react";
import Navbar from "../Components/Common/navbar";
import { Container } from "reactstrap";
import { isMobile, isAndroid, isIOS } from "react-device-detect";

function MainAppDownload({ path }) {
  const [defaultLink, setDefaultLink] = React.useState("#");

  const android_link = "https://play.google.com/store/apps/details?id=com.yargeu.AmazingCamp&hl=ko-KR";
  const ios_link = "https://apps.apple.com/us/app/amzingticket/id6447417241";

  useEffect(() => {
    if (isMobile) {
      if (isAndroid) {
        setDefaultLink(android_link);
      } else if (isIOS) {
        setDefaultLink(ios_link);
      }
    }
  }, []);

  const contentWidth = window.innerWidth > 540 ? "540px" : "100%";

  return (
    <div className="layout-wrapper landing">
      <Container fluid style={{ backgroundColor: "white", justifyContent: "center" }}>
        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          {/* <a href={defaultLink} target="_self" rel="noreferrer" style={{ border: "none" }}>
            <img
              src="https://total.amazingticket.site/media/amazingticket_app_banner.jpg"
              // width={window.innerWidth > 540 ? "540px" : "100%"}
              style={{ width: contentWidth }}
              alt="app download"
            />
          </a> */}
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px", marginBottom: "10px" }}>
          <div style={{ width: contentWidth, textAlign: "center" }}>
            <div className="mt-5">
              <strong style={{ fontFamily: "Gmarket-M", fontSize: "22px", textAlign: "center", color: "#f6475f", display: "block" }}>앱 다운로드</strong>
              <p style={{ fontFamily: "Gmarket-M", fontSize: "16px", textAlign: "center", color: "#1d1d1d" }}>앱에서 더 많은 혜택을 받아보세요!</p>
            </div>

            <div
              style={{
                backgroundColor: "#fff9fa",
                borderRadius: "50%",
                maxWidth: "450px",
                maxHeight: "450px",
                width: "90vw",
                height: "90vw",
                position: "relative",
                overflow: "hidden",
                margin: "30px auto",
              }}
            >
              <img
                src="https://www.amazingticket.site/img/app_img1.png"
                alt="app"
                style={{ position: "absolute", width: "50vw", maxWidth: "280px", top: "12%", left: "50%", transform: "translateX(-50%)" }}
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0", marginBottom: "90px" }}>
          <div style={{ width: contentWidth, display: "flex", justifyContent: "center", alignItems: "center" }}>
            {!isIOS ? (
              <a href={android_link} style={{ display: "inline-block", width: "160px", height: "60px", borderRadius: "10px", backgroundColor: "#ffe9e9a5", marginRight: "20px" }}>
                <img alt="Get it on Google Play" src="https://www.amazingticket.site/img/google_icon.svg" style={{ margin: "17px auto", width: "110px", display: "block" }} />
              </a>
            ) : null}
            {!isAndroid ? (
              <a href={ios_link} style={{ display: "inline-block", width: "160px", height: "60px", borderRadius: "10px", backgroundColor: "#ffe9e9a5" }}>
                <img alt="Download on the App Store" src="https://www.amazingticket.site/img/apple_icon.svg" style={{ margin: "17px auto", width: "110px", display: "block" }} />
              </a>
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default MainAppDownload;
