import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { useLocation } from "react-router-dom";
import AvatarImg from "../../../Components/Common/Avatar";
import { KAKAO_EXTRA_AUTH_URL, getKakaoAccessToken } from "../../../api/auth";

export default function GiftKakaoList({ info, setInfo, visible, setVisible, needRefresh, sendItemKakao }) {
  const location = useLocation();

  const [kakaoFailed, setKakaoFailed] = useState(false);
  const [friendsList, setFriendsList] = useState([]);

  const handleLoadKakaoFriends = () => {
    setFriendsList([]);
    const ktoken = getKakaoAccessToken();
    if (ktoken) {
      fetch("https://kapi.kakao.com/v2/user/scopes", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ktoken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log("kakao scopes", data)
          let userConsent = false;
          data["scopes"].forEach((element) => {
            if (element.id === "friends" && element.agreed) {
              userConsent = true;
            }
            // console.log(element.id, element.agreed, element)
          });

          if (userConsent) {
            console.log("confirm user consent for sharing friends list!");
            fetch("https://kapi.kakao.com/v1/api/talk/friends", {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${ktoken}`,
              },
            })
              .then((res) => res.json())
              .then((data) => {
                // console.log(data)
                if (data["elements"]) {
                  setFriendsList(data["elements"]);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            // alert("카카오 계정에서 추가 동의가 필요한 기능입니다. 카카오 재로그인이 필요합니다!");
            setKakaoFailed(true);
          }
        })
        .catch((e) => {
          console.log(e);
          // alert("카카오 친구 목록을 불러오기를 실패했습니다. 카카오 재로그인이 필요합니다!");
          setKakaoFailed(true);
        });
    } else {
      // alert("카카오 친구 목록을 불러오기를 실패했습니다. 카카오 로그인이 필요합니다!");
      setKakaoFailed(true);
    }
  };

  useEffect(() => {
    if (visible) {
      handleLoadKakaoFriends();
    }
  }, [visible]);

  const handleSendGift = async (info, friendId) => {
    const results = [];
    try {
      const result = await sendItemKakao(info.id, friendId);
      results.push({ ...result, id: info.id });
      console.log("send gift done:", info.id, result);
    } catch (error) {
      results.push({ error, id: info.id });
      console.log("send gift error:", info.id, error);
    }
    if (results.filter((item) => !!item["error"]).length) alert("선물하기 오류.." + JSON.stringify(results));
    else alert("선물하기 완료!");
    needRefresh();
  };

  if (kakaoFailed) {
    window.localStorage.setItem("redirectTo", `${location.pathname}`);
  } else {
    if (window.localStorage.getItem("redirectTo") === location.pathname) {
      window.localStorage.removeItem("redirectTo");
    }
  }

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h5>
          <span className="text-dark mb-1 px-2" style={{ fontFamily: "Gmarket-M" }}>
            내 카카오 친구 목록에서 선택하기
          </span>
        </h5>
      </div>
      {!kakaoFailed ? (
        <div style={{ height: "40vh", overflowY: "scroll" }}>
          <ul className="list-group">
            {friendsList.map((friend, idx) => {
              return (
                <li className="list-group-item" key={idx}>
                  <div className="d-flex justify-content-between">
                    <AvatarImg src={friend.profile_thumbnail_image} alt="" size={"32px"} />
                    <span className="text-dark mt-3 mb-1" style={{}}>
                      {friend.profile_nickname}
                    </span>

                    <span style={{ padding: "8px" }}></span>
                    <Button
                      style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
                      onClick={() => {
                        // console.log("kakao friend:", friend.id, friend.uuid)
                        handleSendGift(info, friend.id);
                        setInfo({});
                        setVisible(false);
                      }}
                      className="btn btn-success ms-2 my-2"
                    >
                      선물하기
                    </Button>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <>
          <div style={{ padding: "8px" }}>
            <div style={{ fontSize: "14px", lineHeight: "24px", textAlign: "center" }}>
              친구 목록 불러오기를 실패했습니다. <br />
              카카오 로그인을 재시도 해주세요. <br />
              친구 목록 보기 권한 요청 시 수락하여 주세요.
            </div>
          </div>
          <Button
            style={{ fontFamily: "Gmarket-M", width: "100%" }}
            color="btn btn-warning mt-4"
            onClick={() => {
              window.location.replace(KAKAO_EXTRA_AUTH_URL);
            }}
          >
            카카오 재로그인
          </Button>
        </>
      )}
      <div style={{ marginTop: "18px" }}></div>
      <div className="d-flex justify-content-center mx-1 pb-4">
        <Button
          color="btn btn-light ms-2 my-2"
          style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
          onClick={() => {
            setInfo({});
            setVisible(false);
          }}
        >
          닫기
        </Button>
      </div>
    </div>
  );
}
