import React, { useCallback } from "react";
import { Card, Table, Button, ButtonGroup, Input, Row, Col, Spinner, Alert } from "reactstrap";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { API, BACKEND_HOST } from "../../../api";
import { getAccessToken } from "../../../api/auth";
import { useInfiniteQuery } from "react-query";

const Item = styled.div`
  border-radius: 0.5vh;
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  border: 1px solid #e5e7eb;
  /* max-width: 480px; */
  padding: 10px 20px;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

function B2BVoucherSentList({ todayOnly }) {
  const navigate = useNavigate();

  const [vouchers, setVouchers] = React.useState([]);

  const [selectVoucher, setSelectVoucher] = React.useState({});

  const accessToken = getAccessToken();

  const fetchData = useCallback(
    async ({ pageParam = 1 }) => {
      const response = await API.get(`/voucher/stock/sent/?page=${pageParam}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = response.data;
      console.log("data", data);
      return { ...data, results: data.results };
    },
    [accessToken]
  );

  const handelCancelGift = (voucherId) => {
    if (window.confirm("선물을 취소하시겠습니까?")) {
      const accessToken = getAccessToken();

      API.put(
        `/voucher/stock/cancel/`,
        {
          user_voucher_ids: [voucherId],
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
        .then((response) => {
          const data = response.data;
          console.log(data);
          refetch();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const { status, data, error, isSuccess, isFetching, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, refetch } = useInfiniteQuery(
    ["voucher", "B2BSent"],
    async ({ pageParam = 1 }) => {
      // console.log("pageParam", pageParam);
      const data = await fetchData({ pageParam });
      // console.log("data", data);
      return data;
    },
    {
      getNextPageParam: (lastPage) => (lastPage.next ? lastPage.next  : undefined),
      // getPreviousPageParam: (firstPage) => undefined,
    }
  );

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12 d-flex flex-wrap justify-content-center gap-4" style={todayOnly && vouchers.length ? { marginBottom: "16px" } : {}}>

          {data && isSuccess ? (
            <>
              {data?.pages.map((page, i) => (
                <React.Fragment key={i}>
                  {page.results.map((voucher, index) => (
                    <Col lg={12} xxl={12} md={12} sm={12} xs={12} key={voucher.id} className="">
                      <Item visible={true} className="w-md-480" style={{ backgroundColor: "#fff", opacity: voucher.downloaded ? 0.5 : 1 }}>
                        <div className="d-flex justify-content-between px-2 border-bottom align-items-center">
                          <div className="card-title pt-3" style={{ fontWeight: "600", maxWidth: "65%" }}>
                            {voucher.name}
                          </div>
                          <p
                            className="fw-bold mt-2 btn btn-primary"
                            style={{ opacity: voucher.state !== "사용안함" ? 0.5 : 1 }}
                            onClick={() => {
                              if (voucher.state === "사용안함") {
                                handelCancelGift(voucher.id);
                              } else {
                                return;
                              }
                            }}
                          >
                            {voucher.state !== "사용안함" ? "전송 완료" : "전송 취소"}
                          </p>
                        </div>

                        {/* <div className="d-flex justify-content-between px-2 border-bottom">
                          <div className="card-title pt-3" style={{ fontWeight: "600", color: "#f64757" }}>
                            {voucher.voucher.discount_type === "price" ? `${voucher.voucher.discount_amount.toLocaleString()}원 할인쿠폰` : `${voucher.voucher.discount_rate}% 할인쿠폰`}
                          </div>
                        </div> */}
                        {/* <div className="d-flex justify-content-between px-2 border-bottom">
                          <div className="card-title pt-3" style={{ fontWeight: "400", color: "#5d5d5d" }}>
                            {voucher.voucher.min_purchase.toLocaleString()}원 이상 구매시 사용가능
                          </div>
                        </div> */}
                        <div className="d-flex justify-content-between px-2 border-bottom">
                          <div className="card-title pt-3" style={{ fontWeight: "400", color: "#5d5d5d" }}>
                            {voucher.username}에게 보낸 쿠폰
                          </div>
                        </div>
                        <div className="d-flex px-2">
                          <div className="mt-1" style={{ width: "100%" }}>
                            <p className="card-text py-2" style={{ width: "100%" }}>
                              {/* <FiCalendar size={18} color={"#1d1d1d"} /> */}
                              <span style={{ lineHeight: "32px" }}>만료기한 {voucher.expiration_date === null ? "없음" : voucher.expiration_date} </span>
                            </p>
                            {/* {voucher.voucher.bound_type === "reservation" ? (
                              <>
                                <p className="card-text mb-1">예약가능기간</p>
                                <p className="card-text pb-3">
                                  {voucher.start_date} ~ {voucher.end_date}
                                </p>
                              </>
                            ) : null} */}
                          </div>
                        </div>
                      </Item>
                    </Col>
                  ))}
                </React.Fragment>
              ))}
            </>
          ) : null}
        </Col>

        {!isFetching && data?.pages.length === 0 ? (
          <div className="d-flex justify-content-center" style={{ alignItems: "center", height: 150 }}>
            보낸 B2B 쿠폰이 없습니다.
          </div>
        ) : null}

        {isFetching ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : null}

        {hasNextPage ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
            <div
              className="fw-bold mt-2 btn"
              style={{ color: "#f64757", border: "1px solid #f64757" }}
              onClick={() => {
                fetchNextPage();
              }}
            >
              다음
            </div>
          </div>
        ) : null}
      </Row>
    </React.Fragment>
  );
}

export default B2BVoucherSentList;
