import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import { API, updateAccessToken } from "../../api";

const SecretLogin = () => {
  const navigate = useNavigate();

  const [phonenum, setPhonnum] = useState("");
  const [password, setPassword] = useState("");

  const loginHandler = (ev) => {
    ev.preventDefault();
    if (!phonenum || !password) {
      return;
    }

    API.post(
      `/api/token/admin/`,
      {
        phone_num: phonenum,
        password: password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.data)
      .then((data) => {
        console.log("RESPONSE from login success ", data);
        updateAccessToken(data["access_token"]);

        const redirectTo = window.localStorage.getItem("redirectTo");
        if (redirectTo) {
          window.localStorage.removeItem("redirectTo");
          navigate(`${redirectTo}`);
        } else {
          navigate("/my-page");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (

    <div className="layout-wrapper landing" style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
      <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff", minHeight: "100vh" }}>
        <div className="auth-page-content">
          <Container>
            <Row>
              <div style={{
                textAlign: "center",
                marginTop: "40px",
                marginBottom: "20px",

              }}>
                <h1>로그인</h1>
              </div>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Form onSubmit={loginHandler}>
                      <FormGroup className="pb-2 mr-sm-2 mb-sm-0">
                        <Label for="formPhonenum" className="mr-sm-2">
                          전화번호
                        </Label>
                        <Input
                          type="tel"
                          name="phonenum"
                          id="formPhonenum"
                          placeholder="phone number"
                          onChange={(ev) => setPhonnum(ev.currentTarget.value)}
                        />
                      </FormGroup>
                      <FormGroup className="pb-2 mr-sm-2 mb-sm-0">
                        <Label for="formPassword" className="mr-sm-2">
                          비밀번호
                        </Label>
                        <Input
                          type="password"
                          name="password"
                          id="formPassword"
                          placeholder="********"
                          onChange={(ev) => setPassword(ev.currentTarget.value)}
                        />
                      </FormGroup>
                      <Button type="submit" color="primary" style={{
                        width: "100%",
                        marginTop: "20px",
                      }}>
                        Login
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default SecretLogin;
