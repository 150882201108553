import React, { useEffect, useState } from "react";
import { Button, Modal, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { API, BACKEND_HOST, SITE_URL } from "../../api";
import classnames from "classnames";
import GiftKakaoList from "../../pages/UserPage/purchaseList/GiftKakaoList";
import GiftPhoneNumList from "../../pages/UserPage/purchaseList/GiftPhoneNumList";
import { getAccessToken } from "../../api/auth";
import { shareKakao } from "../../common/social/kakao/api";

const UserVoucherGiftModal = ({ infos, setInfos, visible, setVisible, needRefresh }) => {
  const togModal = () => setVisible(!visible);

  const [customActiveTab, setcustomActiveTab] = useState("2");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const inviteUserForVoucher = async (userVoucherId, phoneNum) => {
    const phone_num = phoneNum.replaceAll("-", "");
    const accessToken = getAccessToken();

    try {
      const response = await API.post(
        "/voucher/invite-user-for-gift/",
        {
          userVoucherId: userVoucherId,
          phoneNum: phone_num,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data;

      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const giftUserVoucherToKakaoFriend = async (userVoucherId, kakaoId) => {
    const accessToken = getAccessToken();

    try {
      const response = await API.post(
        "/voucher/send-user-voucher-gift-kakao/",
        {
          userVoucherId: userVoucherId,
          kakaoId: kakaoId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data;

      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };


  const giftUserVoucherByUrl = async (userVoucherId) => {
    const accessToken = getAccessToken();

    try {
      const response = await API.post(
        "/voucher/send-user-voucher-gift-url/",
        {
          userVoucherId: userVoucherId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data;

      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };


  if (!infos?.length) return null;

  return (
    <React.Fragment>
      <Modal toggle={togModal} isOpen={visible} modalClassName="fadeInUp" centered>
        <div className="mx-3 my-3">
          <div className="my-3 d-flex justify-content-center">
            <h4 className="mb-sm-0">교환권 선물하기</h4>
          </div>
          {infos.length === 1 ? (
            <div>
              <ul className="list-group">
                <li className="list-group-item">ID : {infos[0].id}</li>
                <li className="list-group-item">상품 : {infos[0].name}</li>
                <li className="list-group-item">만기일 : {infos[0].expire_date}</li>
                <li className="list-group-item">상태 : {infos[0].state}</li>
              </ul>

              <div style={{ marginTop: "24px" }}></div>
              <div className="d-flex justify-content-center mx-1 mb-4">
                <Button
                  style={{
                    fontFamily: "Gmarket-M",
                    backgroundColor: "#f9e000",
                    borderWidth: 0,
                    color: "#444",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px 20px",
                    gap: "10px",
                  }}
                  onClick={() => {
                    giftUserVoucherByUrl(infos[0].id).then((data) => {
                      console.log("giftUserVoucherByUrl", data);
                      shareKakao({
                        title: `${infos[0].name}`,
                        description: `교환권을 선물 받았습니다.`,
                        imageUrl: infos[0].image ? infos[0].image : "",
                        shareUrl: `${SITE_URL}/gift/voucher/${data.gift_id}/`,
                      });
                    }).catch((error) => {
                      console.error(error);
                      alert("선물하기에 실패했습니다.");
                    }).finally(() => {
                      setVisible(false);
                      needRefresh();
                    });
                  }}
                >
                  <i className="ri-gift-fill"></i>
                  카카오톡으로 선물보내기
                </Button>
              </div>


            </div>
          ) : (
            <div style={{ height: "20vh", overflowY: "scroll" }}>
              <ul className="list-group">
                {infos.map((info, idx) => {
                  return (
                    <li key={idx} className="list-group-item">
                      ID: {info.id} 상품: {info.name} 만기일: {info.expire_date}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <div style={{ marginTop: "24px" }}></div>

          <Nav tabs className="nav nav-tabs nav-tabs-custom nav-danger nav-justified mb-3 bg-body bg-opacity-10" style={{ alignItems: "end" }}>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", height: "40px", display: "flex", flexDirection: "row", justifyContent: "center", gap: "4px", alignItems: "center" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                <span className="fs-20">
                  <i className="ri-kakao-talk-fill"></i>
                </span>{" "}
                카톡친구 선물
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", height: "40px", display: "flex", flexDirection: "row", justifyContent: "center", gap: "4px", alignItems: "center" }}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
                전화번호로 선물
              </NavLink>
            </NavItem>
          </Nav>

          {customActiveTab === "1" ? (
            <GiftKakaoList
              sendItemKakao={giftUserVoucherToKakaoFriend}
              info={infos[0]}
              setInfo={(_) => {
                setInfos([]);
              }}
              {...{ visible, setVisible, needRefresh }}
            />
          ) : null}
          {customActiveTab === "2" ? (
            <GiftPhoneNumList
              sendItem={inviteUserForVoucher}
              info={infos[0]}
              setInfo={(_) => {
                setInfos([]);
              }}
              {...{ visible, setVisible, needRefresh }}
            />
          ) : null}
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default UserVoucherGiftModal;
