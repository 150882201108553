import { SITE_UID } from "../assets/meta/meta";
import { REDIRECT_URI, REST_API_KEY } from "../common/social/kakao/api";
import jwtDecode from "jwt-decode";

export function isUserLogin() {
  const token = window.localStorage.getItem("access_token");

  if (token) return true;
  else return false;
}

export const getUserId = () => {
  const accessToken = getAccessToken();
  if (accessToken) {
    let decoded = {};
    try {
      decoded = jwtDecode(accessToken);
      return decoded["user_id"];
    } catch (e) {
      console.log(e);
      return null;
    }
  } else return null;
};

export function setAccessToken(token) {
  if (token) window.localStorage.setItem("access_token", token);
}

export function getAccessToken() {
  const token = window.localStorage.getItem("access_token");
  return token;
}

export function removeAccessToken() {
  window.localStorage.setItem("access_token", "");
}

export function setKakaoAccessToken(token) {
  // console.log("kakao token renewed!!", token)
  if (token) window.localStorage.setItem("kakao_access_token", token);
}
export function getKakaoAccessToken() {
  const token = window.localStorage.getItem("kakao_access_token");
  return token;
}

export function removeKakaoAccessToken() {
  window.localStorage.setItem("kakao_access_token", "");
}

// 카카오톡 기본 동의항목: profile, phone_number, plusfriends
export const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code&state=${SITE_UID}`;

// 카카오톡 추가 동의항목: friends (친구목록)
export const KAKAO_EXTRA_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=friends,plusfriends&state=${SITE_UID}`;

// export function fetchErrorHandling(response) {
//   // TODO: error 보고 만약 access_token이 만료돼서 error가 생겼다면 refresh_token 보내는 로직 만들기
//   console.log("res", response);
//   if (response.status === 502 || response.status === 503 || (response.status >= 500 && response.status < 600)) {
//     alert("서버가 불안정합니다. 잠시 후 다시 시도해주세요.");
//     throw new Error("Server Error");
//   } else if (response.status === 200) {
//     return response.json();
//   } else {
//     alert("예상치 못한 오류가 발생했습니다. 잠시 후 다시 시도해주세요. " + response.status + " " + response.statusText);
//     response.text().then((text) => {
//       console.log(text);
//     });
//     removeAccessToken();
//     throw new Error("Unexpected Error");
//   }
// }

export const KAKAO_JS_KEY = "2c81c51aef9522d0b4284a16d493b628"

