import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import { LoginTitleImgModal } from "../../Components/Common/ManagerModal";
import { API, updateAccessToken } from "../../api";

const PhoneLogin = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  /* 값을 index의 permission을 navbar를 통해 가지고 옴 */
  const [permission, setPermission] = React.useState(location.state ? location.state.citation : 0);
  const [loginTitleEditModal, setLoginTitleEditModal] = React.useState(false);

  const [phoneNum, setPhoneNum] = React.useState(null);
  const [isAuth, setIsAuth] = React.useState(false);

  const [authNum, setAuthNum] = React.useState(null);

  const [responseAuthNum, setResponseAuthNum] = React.useState(null);

  const handlePhoneChange = (event) => {
    setPhoneNum(event.target.value);
  };

  const handleInputAuthChange = (event) => {
    setAuthNum(event.target.value);
  };

  const handleButtonClick = () => {
    let body = { phone_num: phoneNum };

    if (responseAuthNum === null || isAuth === false) {
      API.post(`/auth/`, body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.data)
        .then((data) => {
          setResponseAuthNum(data["auth_num"]);
          setIsAuth(true);
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (responseAuthNum !== null && responseAuthNum === authNum) {
      API.post("/api/token/", body, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.data)
        .then((data) => {
          window.localStorage.setItem("refresh_token", data["refresh_token"]);
          updateAccessToken(data["access_token"]);

          // state 보고 라우팅
          if (!location.state) navigate(`/`);
          else if (location.state.from) navigate(location.state.from);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      alert("인증번호가 일치하지 않습니다.");
      setIsAuth(false);
      setAuthNum(null);
    }
  };

  return (
    <React.Fragment>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={`/amazing-logo.png`} alt="" height="120" />
                  </Link>
                </div>
              </div>
              {!!permission && permission >= 2 ? (
                <Button color="primary" className="btn mb-3 border-0 position-absolute top-35 end-0" style={{ zIndex: "2" }} onClick={() => setLoginTitleEditModal(!loginTitleEditModal)}>
                  로그인 타이틀 이미지 수정
                </Button>
              ) : null}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary fw-bold">어메이징 캠프</h5>
                    <p className="text-muted">캠프장에 어서오세요!</p>
                  </div>
                  {/* {error && error ? (<Alert color="danger"> {error} </Alert>) : null} */}
                  <div className="p-2 mt-4">
                    <input
                      name="phoneNum"
                      placeholder="전화번호를 입력해주세요.('-' 제외)"
                      type="number"
                      className="form-control form-control"
                      aria-invalid="false"
                      value={phoneNum}
                      onChange={handlePhoneChange}
                      disabled={isAuth && phoneNum !== "01027919416"}
                    />
                    {isAuth ? (
                      <input
                        name="authNum"
                        placeholder="인증번호를 입력해주세요."
                        type="number"
                        className="form-control form-control mt-2"
                        aria-invalid="false"
                        value={authNum}
                        onChange={handleInputAuthChange}
                      />
                    ) : null}
                    <button type="submit" className="btn btn-success w-100 btn btn-success mt-1" onClick={handleButtonClick}>
                      {isAuth ? "인증번호 확인" : "인증번호 받기"}
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <LoginTitleImgModal setVisible={setLoginTitleEditModal} visible={loginTitleEditModal} />
    </React.Fragment>
  );
};

export default PhoneLogin;
