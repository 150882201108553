import React from "react";
import Navbar from "../../Components/Common/navbar";
import CardSection from "./CardSection";
import NavBottomBar from "../../Components/Common/NavBottomBar";

const Ticket = () => {
  return (
    <div className="layout-wrapper landing " style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
      <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff", minHeight: "100vh" }}>
        <Navbar activeBar={2} />
        <CardSection />
        <NavBottomBar activeBar={2} />
      </div>
    </div>
  );
};

export default Ticket;
