import React from "react";
import { Container, Row } from "reactstrap";
import Navbar from "../../Components/Common/navbar";
import { useUserStore } from "../../store/store";
import { BACKEND_HOST } from "../../api";
import axios from "axios";
import { getAccessToken } from "../../api/auth";

const ApplyCampUlsan = () => {
  const [user] = useUserStore((state) => [state.user]);
  console.log("ApplyCampUlsan");

  const handleApplyVoucher = (voucher_id) => {
    axios
      .post(
        `${BACKEND_HOST}/voucher/apply-ulsan/${voucher_id}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result === "ok") {
          alert("발급이 완료되었습니다. 마이페이지에서 확인해주세요.");
        } else {
          alert(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        alert("발급에 실패하였습니다. " + error.response?.data?.message || "");
      });
  };

  return (
    <div className="layout-wrapper landing overflow-auto" style={{ height: "100vh" }}>
      <Navbar activeBar={3} />

      <div
        style={{
          marginTop: "0",
          padding: "0",
        }}
      >
        <div className="" style={{ marginBottom: "100px" }}>
          <div className="justify-content-center">
            <img src="https://total.amazingticket.site/media/events/camp-ulsan-free-voucher-2023-12_1.png" alt="Party Image" style={{ width: "100%" }} />
          </div>

          <div
            className="justify-content-center"
            style={{
              position: "relative",
            }}
          >
            <img src="https://total.amazingticket.site/media/events/camp-ulsan-free-voucher-2023-12_2.png" alt="Party Image" style={{ width: "100%" }} />
            <div
              className="apply-buttons-section"
              style={{
                // position: "absolute",
                top: "43%",
                left: "0",
                zIndex: "5",
              }}
            >
              <div
                style={{
                  width: "100vw",
                  height: "15vw",
                  cursor: "pointer",
                  backgroundColor: "rgba(0,0,0,0.5)",
                }}
                onClick={() => {
                  if (!user?.phone) {
                    alert("로그인 후 응모 가능합니다.");
                    return;
                  }
                  handleApplyVoucher(89);
                }}
              ></div>
              <div style={{ height: "4vw" }}></div>
              <div
                style={{
                  width: "100vw",
                  height: "15vw",
                  cursor: "pointer",
                  // backgroundColor: "rgba(0,0,0,0.5)"
                }}
                onClick={() => {
                  if (!user?.phone) {
                    alert("로그인 후 응모 가능합니다.");
                    return;
                  }
                  handleApplyVoucher(90);
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyCampUlsan;

// <div class="max-w-2xl mx-auto py-10 px-4">
//     <div class="bg-orange-400 p-6 rounded-lg shadow-lg text-white">
//         <h1 class="text-2xl font-bold mb-4">Join our amazing event and get a chance to win exciting prizes!</h1>
//         <div class="space-y-4">
//             <div class="bg-yellow-300 p-4 rounded-lg flex justify-between items-center">
//                 <div>
//                     <p class="font-semibold">December 9, 2023 (Saturday) 4 PM</p>
//                     <p>Free entry for the first 200 guests</p>
//                 </div>
//                 <button class="bg-yellow-500 text-white font-bold py-2 px-4 rounded hover:bg-yellow-600 transition duration-300">Details</button>
//             </div>
//             <div class="bg-yellow-300 p-4 rounded-lg flex justify-between items-center">
//                 <div>
//                     <p class="font-semibold">December 10, 2023 (Sunday) 4 PM</p>
//                     <p>Free entry for the first 200 guests</p>
//                 </div>
//                 <button class="bg-yellow-500 text-white font-bold py-2 px-4 rounded hover:bg-yellow-600 transition duration-300">Details</button>
//             </div>
//         </div>
//         <div class="mt-6">
//             <img src="https://source.unsplash.com/random/400x300?party" alt="Party Image" class="w-full h-auto rounded-lg">
//         </div>
//     </div>
// </div>
