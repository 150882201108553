import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Alert, Spinner } from "reactstrap";

import { REST_API_KEY, REDIRECT_URI } from "../../common/social/kakao/api";
import { API, updateAccessToken } from "../../api";
import axios from "axios";
import { setKakaoAccessToken } from "../../api/auth";

const KakaoPage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const KAKAO_CODE = params.get('code');
    const state = params.get('state');
    // const redirect_path = params.get('path');
    if (!KAKAO_CODE || !state) {
      console.error("Failed to parse KAKAO_CODE or state from URL");
      setError("URL 파싱에 실패했습니다. 다시 시도해주세요.");
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    // .post(`https://kauth.kakao.com/oauth/token`, `grant_type=authorization_code&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&code=${KAKAO_CODE}`, {
    axios
      .post(`https://kauth.kakao.com/oauth/token`, `grant_type=authorization_code&client_id=${REST_API_KEY}&code=${KAKAO_CODE}`, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((response) => response.data)
      .then((data) => {
        setKakaoAccessToken(data["access_token"]);
        return API.post(
          `/api/token/`,
          { kakao_token: data["access_token"] },
          {
            headers: { "Content-Type": "application/json" },
          }
        );
      })
      .then((response) => response.data)
      .then((data) => {
        if (data["has_user"]) {
          window.localStorage.setItem("refresh_token", data["token"]["refresh_token"]);
          updateAccessToken(data["token"]["access_token"]);

          const redirectTo = window.localStorage.getItem("redirectTo");
          if (redirectTo) {
            window.localStorage.removeItem("redirectTo");
            navigate(`${redirectTo}`);
          } else navigate(`/`);
        } else {
          setError("사용자 정보를 찾을 수 없습니다. 다시 시도해주세요.");
        }
      })
      .catch((error) => {
        console.log(error);
        setError("카카오 로그인에 실패했습니다. 다시 시도해주세요.");
      }).finally(() => {
        setIsLoading(false);
      });
  }, [location, navigate]);

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff", minHeight: "100vh" }}>
          <div className="auth-page-content">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center mt-sm-5 mb-4 text-white-50">
                    <div>
                      <Link to="/" className="d-inline-block auth-logo">
                        <img src={`/amazing-logo.png`} alt="" height="120" />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <Card className="bg-white rounded shadow-2">
                    <CardBody>
                      <h4 className="text-center">카카오 로그인</h4>

                      <div className="mb-4 mt-5 ">

                        {isLoading && (
                          <div className="p-2">
                            <div className="text-center">
                              <Spinner color="primary" />
                            </div>
                            <div className="text-center">
                              <p>
                                로그인을 처리중입니다. 잠시만 기다려주세요.
                              </p>
                            </div>
                          </div>
                        )}
                        
                        {!isLoading && error && (
                          <div className="text-center">
                            <Alert color="danger">{error}</Alert>
                          </div>
                        )}
                      </div>
                      <div className="p-2">
                        <Link to="/login" className="text-muted">
                          <i className="mdi mdi-account-circle"></i> 로그인 화면으로 돌아가기
                        </Link>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

            </Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default KakaoPage;
