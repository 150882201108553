import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Row, Col, Button, Table } from "reactstrap";
import { API, BACKEND_HOST } from "../../../api";

import Navbar from "../../../Components/Common/navbar";
import { getAccessToken } from "../../../api/auth";

const OrderDone = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();

  const [orders, setOrders] = React.useState([]);

  React.useEffect(() => {
    const fetchUserOrder = async () => {
      try {
        const accessToken = getAccessToken();
        if (accessToken) {
          const response = await API.get(`/package/user-order/?order_id=${orderId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const data = response.data;
          // console.log(data);
          setOrders(data.orders);
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserOrder();
  }, [orderId]);

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff", minHeight: "100vh" }}>
          <Navbar />
          <h3 className="border-success text-center my-3 fw-bold mt-4" style={{ fontFamily: "Gmarket-M" }}>
            주문완료
          </h3>

          <Card>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <div className="table-responsive">
                    <Table className="table-borderless text-center mb-0">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">상품명</th>
                          <th scope="col">자리</th>
                          <th scope="col">예약날짜</th>
                          <th scope="col">결제일</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(orders || []).map((order, key) => (
                          <tr key={key}>
                            <td>{order.name}</td>
                            <td className="d-flex justify-content-center">
                              <div>{order.spot} &nbsp;</div>
                            </td>
                            <td>{order.reservationDate}</td>
                            <td>{order.orderDate}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="d-flex justify-content-center">
            <Button onClick={() => navigate("/my-page")} color="success" style={{ fontFamily: "Gmarket-M", backgroundColor: "#f6475f", boxShadow: "none", border: "none" }}>
              주문확인하러 가기
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default OrderDone;
