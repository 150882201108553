import React from "react";

import Navbar from "../../../Components/Common/navbar";
import NoticeList from "./NoticeList";

// coupon, voucher 수신하는 페이지
const Notices = () => {

    return (
        <div className="layout-wrapper landing" style={{minHeight: "100vh"}}>
            <Navbar activeBar={5}/>
            <NoticeList />
        </div>
    );
}

export default Notices;