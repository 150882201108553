import React, { useCallback } from "react";
import { Card, Table, Button, ButtonGroup, Input, Row, Col, Spinner } from "reactstrap";
import PurchaseRecordModal from "../../../Components/modal/PurchaseRecordModal";
import ReservationGiftModal from "../../../Components/modal/ReservationGiftModal";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { API, BACKEND_HOST } from "../../../api";
import ReservationCheckoutModal from "../../../Components/modal/ReservationCheckoutModal";
import { FiCalendar } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa6";
import { IoGiftOutline } from "react-icons/io5";
import axios from "axios";
import { useInfiniteQuery } from "react-query";
import CouponGiftModal from "../../../Components/modal/CouponGiftModal";
import { useUserStore } from "../../../store/store";
import CouponInfoModal from "../../../Components/modal/CouponInfoModal";
import { getAccessToken } from "../../../api/auth";

const Item = styled.div`
  border-radius: 0.5vh;
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  border: 1px solid #e5e7eb;
  /* max-width: 480px; */
  padding: 10px 20px;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

const confirm = (message = null, onConfirm, onCancel) => {
  if (!onConfirm || typeof onConfirm !== "function") return;
  if (onCancel && typeof onCancel !== "function") return;
  const confirmAction = () => {
    if (window.confirm(message)) onConfirm();
    else onCancel();
  };
  return confirmAction;
};

function UserCouponList() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [giftModal, setGiftModal] = React.useState(false);
  const [infoModal, setInfoModal] = React.useState(false);
  const [selectCoupon, setSelectCoupon] = React.useState({});
  const [permission, setPermission, user] = useUserStore((state) => [state.permission, state.setPermission, state.user]);

  const accessToken = getAccessToken();

  const fetchData = useCallback(
    async ({ pageParam = 1 }) => {
      const response = await API.get(`/coupon/user/?page=${pageParam}&gifted=true`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = response.data;
      console.log("orderdata", data);
      return { ...data, results: data.results };
    },
    [accessToken]
  );

  const sendCancel = async (couponId) => {
    const accessToken = getAccessToken();

    try {
      const response = await API.put(`/coupon/gift/cancel/${couponId}/`, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = response.data;
      return data;
    } catch (error) {
      console.log(error);
      // 예외 처리를 원하는 방식으로 수행
      throw error;
    }
  };

  const { status, data, error, isSuccess, isFetching, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, refetch } = useInfiniteQuery(
    ["UserCouponList"],
    async ({ pageParam = 1 }) => {
      // console.log("pageParam", pageParam);
      const data = await fetchData({ pageParam });
      // console.log("data", data);
      return data;
    },
    {
      // getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.page + 1 : undefined),
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.next) {
          const match = lastPage.next.match(/page=(\d+)/);
          if (match) {
            return match[1];
          }
        }
        return undefined;
      },
      // getPreviousPageParam: (firstPage) => undefined,
    }
  );

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12 d-flex flex-wrap justify-content-center gap-4">

          {data && isSuccess ? (
            <>
              {data?.pages.map((page, i) => (
                <React.Fragment key={i}>
                  {page.results.map((coupon, index) => (
                    <React.Fragment key={"coupon" + index}>
                      <Col lg={12} xxl={12} md={12} sm={12} xs={12} key={coupon.id} className="">
                        <Item visible={true} className="w-md-480" style={{ backgroundColor: "#fff", opacity: coupon.state === "사용안함" ? 1 : 0.5 }}>
                          <div className="d-flex justify-content-between px-2 border-bottom align-items-center">
                            <div className="card-title pt-3" style={{ fontWeight: "600", maxWidth: "65%" }}>
                              {coupon.coupon.title}
                            </div>
                            <p className="fw-bold mt-2" style={{ opacity: coupon.state === "사용안함" ? 1 : 0.5, color: "#f64757" }}>
                              {coupon.state}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between px-2 border-bottom">
                            <div className="card-title pt-3" style={{ fontWeight: "600", color: "#f64757" }}>
                              {coupon.coupon.discount_type === "price" ? `${coupon.coupon.discount_amount.toLocaleString()}원 할인쿠폰` : `${coupon.coupon.discount_rate}% 할인쿠폰`}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between px-2 border-bottom">
                            <div className="card-text pt-3 pb-2" style={{}}>
                              만료기한 {coupon.coupon.expire_date !== null ? coupon.coupon.expire_date : "없음"}
                            </div>
                          </div>
                          {/* <div className="d-flex justify-content-between px-2 border-bottom">
                            <div className="card-title pt-3" style={{ fontWeight: "400", color: "#5d5d5d" }}>
                              {coupon.coupon.min_purchase.toLocaleString()}원 이상 구매시 사용가능
                            </div>
                          </div> */}

                          <div className="d-flex justify-content-between px-2">
                            <div className="card-title pt-3" style={{ fontWeight: "400", color: "#5d5d5d" }}>
                              {coupon.provider ? <>{coupon.provider.username}님께서 보내신 쿠폰입니다.</> : null}
                            </div>
                          </div>

                          <div className="d-flex justify-content-between px-2">
                            <div className="card-title pt-3" style={{ fontWeight: "400", color: "#5d5d5d" }}>
                              {coupon.gift_state === "수신함" && coupon.sender !== null && coupon.sender_phone_num === user.phone ? <>{coupon.phone_num}님께 선물하신 쿠폰입니다.</> : null}
                            </div>
                          </div>

                          <div className="d-flex px-2">
                            <div className="mt-1" style={{ width: "100%" }}>
                              {coupon.sender !== null && coupon.sender_phone_num !== user.phone ? (
                                <p className="card-text py-2" style={{ float: "right" }}>
                                  {coupon.sender_username}님께 선물받은 쿠폰입니다.
                                </p>
                              ) : null}

                              {/* {coupon.coupon.bound_type === "reservation" ? (
                                <>
                                  <p className="card-text mb-1">예약가능기간</p>
                                  <p className="card-text pb-3">
                                    {coupon.coupon.use_start_date} ~ {coupon.coupon.use_end_date}
                                  </p>
                                </>
                              ) : null} */}
                              <p
                                className="fw-bold mt-2 btn"
                                style={{ opacity: coupon.state === "사용안함" ? 1 : 0.5, color: "#f64757", border: "1px solid #f64757" }}
                                onClick={() => {
                                  setSelectCoupon(coupon);
                                  setInfoModal(true);
                                }}
                              >
                                상세보기
                              </p>

                              {/* {coupon.gift_state === "없음" ? (
                                <p
                                  className="fw-bold mt-2 btn btn-primary"
                                  style={{ opacity: coupon.state === "사용안함" ? 1 : 0.5, float: "right" }}
                                  onClick={() => {
                                    console.log("gift", coupon);
                                    setSelectCoupon(coupon);
                                    setGiftModal(true);
                                  }}
                                >
                                  선물하기
                                </p>
                              ) : null} */}

                              {/* {coupon.gift_state === "수신안함" && coupon.sender !== null && coupon.sender_phone_num === user.phone ? (
                                <p
                                  className="fw-bold mt-2 btn"
                                  style={{ opacity: coupon.state === "사용안함" ? 1 : 0.5, float: "right", color: "#f64757" }}
                                  onClick={() => {
                                    confirm(
                                      `쿠폰선물을 취소하시겠습니까?`,
                                      () => {
                                        sendCancel(coupon.id)
                                          .then((data) => {
                                            if (data.status === "cancel ok") {
                                              alert("취소되었습니다.");
                                              refetch();
                                            } else {
                                              alert("전송 실패 " + JSON.stringify(data));
                                            }
                                          })
                                          .catch((e) => {
                                            console.log(e);
                                            alert("전송 실패");
                                          });
                                      },
                                      () => {}
                                    )();
                                  }}
                                >
                                  선물취소
                                </p>
                              ) : null} */}

                              {coupon.gift_state === "수신함" && coupon.sender !== null && coupon.sender_phone_num === user.phone ? (
                                <p className="fw-bold mt-2 btn btn-primary" style={{ float: "right", opacity: 0.5 }}>
                                  선물완료
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </Item>
                      </Col>
                    </React.Fragment>
                  ))}

                  <CouponInfoModal setVisible={setInfoModal} visible={infoModal} info={selectCoupon} setInfo={setSelectCoupon} navigate={navigate} needRefresh={refetch} />
                  <CouponGiftModal setVisible={setGiftModal} visible={giftModal} info={selectCoupon} setInfo={setSelectCoupon} navigate={navigate} needRefresh={refetch} />
                </React.Fragment>
              ))}
            </>
          ) : null}
        </Col>

        {/* {!isLoading && data.pages.length === 0 ? (
            <div className="d-flex justify-content-center" style={{ alignItems: "center", height: 150 }}>
              다운받을 쿠폰이 없습니다.
            </div>
          ) : null}
           */}

        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
            <Spinner animation="border" variant="primary" />
          </div>
        ) : null}

        {hasNextPage ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
            <div
              className="fw-bold mt-2 btn"
              style={{ color: "#f64757", border: "1px solid #f64757" }}
              onClick={() => {
                fetchNextPage();
              }}
            >
              다음
            </div>
          </div>
        ) : null}
      </Row>
    </React.Fragment>
  );
}

export default UserCouponList;
