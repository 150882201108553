import React, { useEffect, useState } from "react";
import { Modal, Button, Alert, Form, Label, Input } from "reactstrap";
const CouponInfoModal = ({ info, setInfo, visible, setVisible, needRefresh }) => {
  const togModal = () => {
    setVisible(!visible);
    document.body.style.overflow = "scroll";
  };

  return (
    <React.Fragment>
      {Object.keys(info).length !== 0 ? (
        <Modal toggle={togModal} isOpen={visible} modalClassName="fadeInUp" centered onClosed={() => (document.body.style.overflow = "scroll")}>
          <div className="mx-3 my-3">
            <div className="my-3 d-flex justify-content-center  "></div>
            <ul className="list-group">
              <li className="list-group-item">쿠폰이름: {info?.coupon_name}</li>
              <li className="list-group-item">
                할인금액 : {info?.coupon.discount_type === "price" ? `${info.coupon.discount_amount.toLocaleString()}원 할인쿠폰` : `${info.coupon.discount_rate}% 할인쿠폰`}
              </li>
              <li className="list-group-item">만료기한 : {info.coupon.expire_date}</li>
              {info.coupon.bound_type === "reservation" ? (
                <li className="list-group-item">
                  예약가능기간: {info.coupon.use_start_date} ~ {info.coupon.use_end_date}
                </li>
              ) : null}

              <li className="list-group-item">사용정보 : {info.coupon.min_purchase.toLocaleString()}원 이상 구매시 사용가능</li>
              <li className="list-group-item">이용정보 : {info.state}</li>
            </ul>

            <div style={{ marginTop: "24px" }}></div>
          </div>
          <div className="d-flex justify-content-center mx-1 pb-4">
            <Button
              color="btn btn-light ms-2 my-2"
              style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
              onClick={() => {
                setInfo({});
                setVisible(false);
              }}
            >
              닫기
            </Button>
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};
export default CouponInfoModal;
