import React from "react";
import { Input, Row } from "reactstrap";
import styled from "styled-components";

const RoundButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  border: none;
  font-size: ${(props) => props.size * 0.6}px;
  line-height: ${(props) => props.size}px;
  cursor: pointer;
  user-select: none;
  padding: 0;
  margin: 0;
  &:hover {
    background-color: #fc7c8d;
  }
  &:focus {
    outline: none;
  }
  background: #f6475f;
  color: #fff;
  :hover {
    cursor: pointer;
  }
`;

function QuantityButtonInput({ quantity, onChange, size = 24, detailPage = false }) {
  return (
    <Row className="d-flex justify-content-center align-items-center gap-2">
      <RoundButton
        size={size}
        onClick={(e) => {
          if (detailPage) {
            if (quantity > 1) onChange(quantity - 1);
          } else {
            if (quantity > 0) onChange(quantity - 1);
          }
        }}
      >
        -
      </RoundButton>
      <Input
        style={{ width: "28px", fontSize: `${size * 0.6}px`, textAlign: "center", padding: "4px", border: "none", backgroundColor: "#fff" }}
        type="number"
        className="form-control"
        value={quantity}
        onChange={(e) => onChange(e.target.value)}
        maxLength="10"
        disabled
      />
      <RoundButton size={size} onClick={(e) => onChange(quantity + 1)}>
        +
      </RoundButton>
    </Row>
  );
}

export default QuantityButtonInput;
