import React from "react";
import { Spinner } from "reactstrap";

import { toast } from "react-toastify";
import { IoCloseCircleOutline } from "react-icons/io5";
import "react-toastify/dist/ReactToastify.css";

const Loader = (props) => {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center mx-2 mt-2">
        {props.loading ? (
          <Spinner color="primary"> Loading... </Spinner>
        ) : (
          <div style={{ fontFamily: "Gmarket-M", color: "#FFE2E5", padding: "50px 10px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <IoCloseCircleOutline size={30} color="#FFE2E5" />
            <p className="my-2">불러올 목록이 없습니다.</p>
          </div>
        )}
      </div>

      {props.error ? toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" }) : null}
    </React.Fragment>
  );
};

export default Loader;
