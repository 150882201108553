import React from "react";
import { useParams } from "react-router-dom";

import Navbar from "../../../Components/Common/navbar";
import UnreceivedList from "./UnreceivedList";

// coupon, voucher 수신하는 페이지
const EventProductReception = () => {
    const { type } = useParams();

    return (
        <div className="layout-wrapper landing">
            <Navbar />
            <UnreceivedList type={type} />
        </div>
    );
}

export default EventProductReception;