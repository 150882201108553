import axios from "axios";

export const imageUpload = async ({accessToken, url, file, groupKey, userId, name, onUploadProgress}) => {
    let form_data = new FormData();
    if (file) form_data.append("image", file, file.name);
    else throw new Error("image file required!")

    if (groupKey) form_data.append("group_key", groupKey)
    if (userId) form_data.append("user", userId)
    if (name) form_data.append("name", name)

    if (!accessToken) throw new Error("accessToken not found!")

    const myNewModel = await axios
        .post(url, form_data, {
            headers: {
                "Content-Type": "multipart/form-data",
                // "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`
            },
            onUploadProgress: event => {
                const percentCompleted = Math.round((event.loaded * 100) / event.total);
                // console.log('onUploadProgress', percentCompleted);
                onUploadProgress(percentCompleted)
            }
        }).then((res) => {
            return res;
        }).catch((error) => {
            return error.response;
        });

    // if (myNewModel.status === 201) {
    //     window.location.href = `/mymodels/${myNewModel.data.id}`;
    // }
    return myNewModel;
}