import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Table, Col, Row, Nav, Container } from "reactstrap";
import { API, BACKEND_HOST } from "../../../api";
import Loader from "../../../Components/Common/Loader";
import Navbar from "../../../Components/Common/navbar";
import { getAccessToken } from "../../../api/auth";

const CancelList = () => {
  const navigate = useNavigate();

  const [cancels, setCancels] = React.useState([]);

  React.useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      API.get(`/package/user-cancel/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          const data = response.data;
          console.log(data);
          setCancels(data.cancels);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      navigate(`/`);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
          <Navbar />
          <Container fluid style={{ minHeight: "100vh", backgroundColor: "white", paddingBottom: "100px" }}>
            <Row className="justify-content-center">
              <Col xxl={12} xl={12} md={12} sm={12}>
                <div className="table-responsive">
                  {(cancels || []).map((cancel, key) => (
                    <div key={key} style={{ border: "1px solid #ededed", padding: "20px", borderRadius: "5px" }} className="my-3">
                      <div style={{}}>
                        <p style={{ fontSize: "15px", fontWeight: "600", paddingBottom: "15px", borderBottom: "1px solid #ededed" }}>{cancel.name}</p>

                        <div>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #ededed", marginBottom: "15px" }}>
                            <p style={{ color: "#6d6d6d" }}>예약일</p>
                            <p>{cancel.reservationDate}</p>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #ededed", marginBottom: "15px" }}>
                            <p style={{ color: "#6d6d6d" }}>예약 좌석</p>
                            <p>{cancel.spot ? cancel.spot + "번" : null}</p>
                          </div>

                          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #ededed", marginBottom: "15px" }}>
                            <p style={{ color: "#6d6d6d" }}>취소일</p>
                            <p>{cancel.cancelDate}</p>
                          </div>

                          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #ededed", marginBottom: "15px" }}>
                            <p style={{ color: "#6d6d6d" }}>취소금액</p>
                            <p>{cancel.cancelAmount.toLocaleString()}원</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* <Table className="table-borderless text-center mb-0">
                        <thead className="table-light">
                            <tr>
                                <th scope="col">상품명</th>
                                <th scope="col">예약일</th>
                                <th scope="col">자리</th>
                                <th scope="col">취소일</th>
                                <th scope="col">취소금액</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(cancels || []).map((cancel, key) => (
                                <tr key={key} >
                                    <td>{cancel.name}</td>
                                    <td>{cancel.reservationDate}</td>
                                    <td>{cancel.spot}</td>
                                    <td>{cancel.cancelDate}</td>
                                    <td>{cancel.cancelAmount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table> */}
                  {cancels.length === 0 ? (
                    <div className="d-flex justify-content-center" style={{ alignItems: "center", height: 50 }}>
                      <Loader />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};
export default CancelList;
