import React from "react";

import Navbar from "../../../Components/Common/navbar";
import OrderProcess from "./OrderProcess";

const OrderPage = () => {
  window.onscroll = function () {
    scrollFunction();
  };
  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };
  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
          <Navbar />
          <OrderProcess />
          {/* <button onClick={() => toTop()} className="btn btn-danger btn-icon landing-back-top" id="back-to-top">
            <i className="ri-arrow-up-line"></i>
          </button> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderPage;
