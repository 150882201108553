import React, { useEffect } from "react";
import { Container, Row, Col, Card, Label, CardBody, CardHeader, Input, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import jwtDecode from "jwt-decode";

import MyEditor from "../../../Components/Common/MyEditor";
import { KakaoMapModal } from "../../../Components/Common/ManagerModal";
import { API, BACKEND_HOST } from "../../../api";
import { getAccessToken } from "../../../api/auth";

const NoticeEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = React.useState("");
  const [content, setContent] = React.useState("");

  const [kakaoMapEditModal, setKakaoMapEditModal] = React.useState(false);
  const [mapAddress, setMapAddress] = React.useState("");

  const getUserId = () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      let decoded = {};
      try {
        decoded = jwtDecode(accessToken);
        return decoded["user_id"];
      } catch (e) {
        console.log(e);
        return null;
      }
    } else return null;
  };

  const fillStates = (data) => {
    setTitle(data.title);
    setContent(data.content);
  };

  const checkData = () => {
    if (!title || !mapAddress || !content) {
      return null;
    }

    const userId = getUserId();
    if (userId === null) navigate(`/`);
    const data = {
      site: 4,
      writer: userId,
      title: title,
      address: mapAddress,
      content: content,
    };
    return data;
  };

  const sendData = async () => {
    const data = checkData();

    if (data === null) {
      alert("제목과 주소 문의내용 모두 입력해주세요.");
      return;
    }

    try {
      const accessToken = getAccessToken();

      if (accessToken) {
        const response = await API.post(`/notice/content/`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = response.data;
        alert("문의 등록 완료");
        navigate(`/notice`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateData = async () => {
    const data = checkData();

    if (data === null) {
      alert("제목과 내용 모두 입력해주세요.");
      return;
    }

    try {
      const accessToken = getAccessToken();

      if (accessToken) {
        const response = await API.put(`/notice/content/${id}/`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const responseData = response.data;
        alert("공지사항 수정 완료");
        navigate(`/notice`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id !== "0") {
        try {
          const accessToken = getAccessToken();

          if (accessToken) {
            const response = await API.get(`/notice/content/${id}/`, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            });

            const data = response.data;
            fillStates(data);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    // 함수를 호출하세요
    fetchData();
  }, [id]);

  return (
    <div className="mt-4">
      <Container>
        <Row className="g-4 mb-3">
          <div className="col-sm-auto">
            <div>
              <h4 className="card-title mb-0 flex-grow-1">가맹문의 등록 및 수정</h4>
            </div>
          </div>
          <div className="col-sm-3 ms-auto">
            <div className="d-flex justify-content-sm-end gap-2">
              {id !== "0" ? (
                <Button
                  className="btn-soft-success add-btn me-1"
                  onClick={() => {
                    updateData();
                  }}
                  id="create-btn"
                >
                  수정하기
                </Button>
              ) : (
                <Button
                  className="btn-soft-success add-btn me-1"
                  onClick={() => {
                    sendData();
                  }}
                  id="create-btn"
                >
                  등록하기
                </Button>
              )}
              <Button
                className="btn-soft-danger add-btn me-1"
                onClick={() => {
                  navigate(`/notice`);
                }}
                id="cancel-btn"
              >
                취소하기
              </Button>
            </div>
          </div>
        </Row>

        <Row className="justify-content-center">
          <Col lg={12}>
            <Card id="customerList">
              <CardBody>
                <div className="live-preview">
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="title" className="form-label">
                          제목
                        </Label>
                        <Input type="text" className="form-control" placeholder="제목" id="title" value={title} onChange={(e) => setTitle(e.target.value)} maxLength="100" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={12}>
            <Card id="customerList">
              <CardBody>
                <div className="live-preview">
                  <Row>
                    <Col md={6}>
                      <div className="d-flex justify-content-sm-end gap-2 mt-2">
                        <Button className="btn-info add-btn me-1" onClick={() => setKakaoMapEditModal(true)} id="create-btn">
                          주소입력
                        </Button>
                      </div>
                      <div className="d-flex justify-content-sm-end gap-2 mt-2">
                        <Label htmlFor="title" className="form-label">
                          {mapAddress}
                        </Label>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12}>
            <Card id="customerList">
              <CardHeader>
                <h4 className="card-title mb-0">문의내용</h4>
              </CardHeader>
              <CardBody>
                <div className="live-preview">
                  <Row>
                    <Col md={12}>
                      <MyEditor value={content} onChange={setContent} />
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <KakaoMapModal visible={kakaoMapEditModal} setVisible={setKakaoMapEditModal} setAddress={setMapAddress} navigate={navigate} isNotice />
    </div>
  );
};

export default NoticeEditor;
