import React from "react";
import { Card, Table, Button, ButtonGroup, Input, Row, Col, Spinner, Alert } from "reactstrap";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { API, BACKEND_HOST } from "../../../api";
import { getAccessToken } from "../../../api/auth";
import B2BPrecouponGiftModal from "../../../Components/modal/B2BPrecouponGiftModal";

const Item = styled.div`
  border-radius: 0.5vh;
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  border: 1px solid #e5e7eb;
  /* max-width: 480px; */
  padding: 10px 20px;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

function B2BPreCouponList({ todayOnly }) {
  const navigate = useNavigate();

  const [precoupons, setPrecoupons] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [giftModal, setGiftModal] = React.useState(false);
  const [selectPrecoupon, setSelectPrecoupon] = React.useState({});

  React.useEffect(() => {
    setdata();
  }, []);

  const setdata = () => {
    const accessToken = getAccessToken();
    setIsLoading(true);

    API.get(`/precoupon/b2b-stock/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        const data = response.data;
        console.log("fetching b2b-stock", data);

        setPrecoupons(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12 d-flex flex-wrap justify-content-center gap-4" style={todayOnly && precoupons.length ? { marginBottom: "16px" } : {}}>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
              <Spinner animation="border" variant="primary" />
            </div>
          ) : null}

          {(precoupons || []).map((precoupon, key) => (
            <Col lg={12} xxl={12} md={12} sm={12} xs={12} key={precoupon.id} className="">
              <Item visible={true} className="w-md-480" style={{ backgroundColor: "#fff", opacity: precoupon.downloaded ? 0.5 : 1 }}>
                <div className="d-flex justify-content-between px-2 border-bottom align-items-center">
                  <div className="card-title pt-3" style={{ fontWeight: "600", maxWidth: "65%" }}>
                    {precoupon.name}
                  </div>
                  <p
                    className="fw-bold mt-2 btn btn-primary"
                    style={{ opacity: precoupon.downloaded ? 0.5 : 1 }}
                    onClick={() => {
                      setSelectPrecoupon(precoupon);
                      setGiftModal(true);
                    }}
                  >
                    {precoupon.stock <= 0 ? "전송완료" : "쿠폰전송"}
                    <br />
                    {precoupon.stock > 0 ? <span>{precoupon.stock}개 전송가능</span> : null}
                  </p>
                </div>

                {/* <div className="d-flex justify-content-between px-2 border-bottom">
                  <div className="card-title pt-3" style={{ fontWeight: "600", color: "#f64757" }}>
                    {precoupon.precoupon.discount_type === "price" ? `${precoupon.precoupon.discount_amount.toLocaleString()}원 할인쿠폰` : `${precoupon.precoupon.discount_rate}% 할인쿠폰`}
                  </div>
                </div> */}
                {/* <div className="d-flex justify-content-between px-2 border-bottom">
                  <div className="card-title pt-3" style={{ fontWeight: "400", color: "#5d5d5d" }}>
                    {precoupon.precoupon.min_purchase.toLocaleString()}원 이상 구매시 사용가능
                  </div>
                </div> */}
                <div className="d-flex px-2">
                  <div className="mt-1" style={{ width: "100%" }}>
                    <p className="card-text py-2" style={{ width: "100%" }}>
                      {/* <FiCalendar size={18} color={"#1d1d1d"} /> */}
                      <span style={{ lineHeight: "32px" }}>만료기한 {precoupon.expire_date === null ? "없음" : precoupon.expire_date} </span>
                    </p>
                    {precoupon.precoupon.bound_type === "reservation" ? (
                      <>
                        <p className="card-text mb-1">예약가능기간</p>
                        <p className="card-text pb-3">
                          {precoupon.precoupon.reserv_start_date} ~ {precoupon.precoupon.reserv_end_date}
                        </p>
                      </>
                    ) : null}
                  </div>
                </div>
              </Item>
            </Col>
          ))}

          {!isLoading && precoupons.length === 0 ? (
            <div className="d-flex justify-content-center" style={{ alignItems: "center", height: 150 }}>
              보낼 B2B 쿠폰이 없습니다.
            </div>
          ) : null}
        </Col>
      </Row>
      <B2BPrecouponGiftModal setVisible={setGiftModal} visible={giftModal} info={selectPrecoupon} setInfo={setSelectPrecoupon} navigate={navigate} needRefresh={setdata} />
    </React.Fragment>
  );
}

export default B2BPreCouponList;
