import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, Label, Input, Table } from "reactstrap";
import classnames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";

import BgFrame from "../../../Components/Common/BgFrame";

import tossPayments from "../../../common/payments/toss/api";
import { SUBDOMAIN } from "../../../assets/meta/meta";
import { API, BACKEND_HOST } from "../../../api";
import CouponTicketSelectModal from "../../../Components/modal/CouponTicketSelectModal";
import { calcCouponSaleAmount } from "../../../common/utils/coupon_util";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { getAccessToken } from "../../../api/auth";

const MultiOrderProcess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [width, setWidth] = React.useState(null);

  const [user, setUser] = useState({
    phoneNum: null,
  });
  const [name, setName] = useState("");
  const [cancelRuleAgree, setCancelRuleAgree] = React.useState(false);
  const [purchaseAllAgree, setPurchaseAllAgree] = React.useState(false);
  const [purchaseOneAgree, setPurchaseOneAgree] = React.useState(false);

  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [couponModal, setCouponModal] = useState(false);
  const [selectCoupon, setSelectCoupon] = useState([]);
  const [targetTicket, setTargetTicket] = useState([]);
  const [tabList, setTabList] = useState([false, true]);

  // useEffect(() => {
  //   console.log("selectCoupon", selectCoupon);
  // }, [selectCoupon]);

  const handleTabClick = (index) => {
    setTabList((prevTabList) => {
      const newTabList = [...prevTabList];
      newTabList[index] = !newTabList[index]; // toggle 로직
      return newTabList;
    });
  };

  const toggleAllAgree = (agree) => {
    setPurchaseAllAgree(agree);
    setPurchaseOneAgree(agree);
  };
  const togglePurchaseAgreeOptions = (agree) => {
    // 임시 코드 => 하위 동의항목이 더 생기면 바꿔야함.
    setPurchaseAllAgree(agree);
    setPurchaseOneAgree(agree);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  };

  const toggleTicketDiscountCouponSelection = (product_id, coupon_id, discount_amount, discount_type, min_purchase, discount_rate, max_discount = 0) => {
    setSelectCoupon((prev) => {
      let _prev = [...prev];
      const item = { coupon_id: coupon_id, product_id: product_id, discount_amount, discount_type, min_purchase, discount_rate, max_discount };
      const selected = _prev.find((item) => item.product_id === product_id && item.coupon_id === coupon_id);
      const blocked = _prev.find((item) => item.product_id !== product_id && item.coupon_id === coupon_id);

      if (blocked) {
        return _prev;
      } else {
        _prev = _prev.filter((item) => item.product_id !== product_id);
        if (!selected) {
          return [..._prev, item];
        } else {
          return _prev;
        }
      }
    });
  };

  const totalOrderPrice = () => {
    let _total = 0;
    location.state.products.forEach((ticket) => {
      const _price = ticket.price * ticket.quantity;
      const _coupon = selectCoupon.find((coupon) => coupon.product_id === ticket.id);
      const saleAmount = _coupon ? calcCouponSaleAmount(_coupon, _price) : 0;
      _total += _price - saleAmount;
    });
    return _total;
  };

  const processPayment = async () => {
    const reqData = {
      tickets: location.state.products.map((item) => ({
        ...item,
        product_id: item.id,
        coupon_id: selectCoupon.find((coupon) => coupon.product_id === item.id) ? selectCoupon.find((coupon) => coupon.product_id === item.id).coupon_id : "",
      })),
    };

    console.log("order data:", reqData);

    const accessToken = getAccessToken();

    if (!accessToken) {
      alert("로그인이 필요한 서비스입니다.");
      navigate(`/login`);
      return;
    }

    if (totalOrderPrice() === 0) {
      alert("결제가능한 최소금액은 1,000원입니다.");
      return;
    }

    try {
      const response = await API.post(`/ticket/pre-order-multi/`, JSON.stringify(reqData), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = response.data;
      console.log("data >>> ", data);

      if ("error" in data) {
        alert(data.error);
        navigate(`${location.state.type === 0 ? "/reservation" : "/ticket"}`);
        return;
      }

      // 결제창 닫았을 때 catch error하기
      tossPayments.requestPayment("카드", {
        amount: totalOrderPrice(),
        orderId: data.orderId,
        orderName: `티켓 종합`,
        customerName: name,
        successUrl: `${BACKEND_HOST}/ticket/order-multi/`,
        failUrl: `https://${SUBDOMAIN}.amazingticket.site/ticket`,
      });
    } catch (error) {
      console.log(error);
      alert("지금은 구매가능 시간이 아니거나 품절된 상품입니다.");
      navigate(`/ticket`);
    }
  };

  const logOut = () => {
    alert("허용되지 않은 접근입니다! 다시 로그인 해주세요.");
  };

  React.useEffect(() => {
    const fetchUserData = async () => {
      const accessToken = getAccessToken();

      if (accessToken) {
        try {
          const decoded = jwtDecode(accessToken);
          const userId = decoded["user_id"];

          const response = await API.get(`/accounts/users/${userId}/`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const data = response.data;
          if (!data["전화번호"]) {
            logOut();
          } else {
            setUser({ phoneNum: data["전화번호"], name: data["이름"] });
            setName(data["이름"]);
          }
        } catch (error) {
          console.log(error);
          logOut();
        }
      } else {
        setUser({
          phoneNum: null,
        });
      }
    };

    fetchUserData();
  }, [location]);

  React.useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <BgFrame>
      <React.Fragment>
        <div>
          <Container fluid>
            <Row>
              <Col xl={12}>
                <div>
                  <div>
                    <div
                      style={{ height: "50px", border: "1px solid #e9ebec", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 20px" }}
                      onClick={() => handleTabClick(0)}
                    >
                      <h5 className="" style={{ fontFamily: "Gmarket-M", margin: 0 }}>
                        주문자
                      </h5>
                      {tabList[0] ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                    </div>
                    <div style={{ border: "1px solid #e9ebec", padding: "20px 20px", display: tabList[0] ? "block" : "none" }}>
                      <Row className="gy-3">
                        <p style={{ fontWeight: "bold", margin: "10px 0 5px 0" }}>{name === "" ? "로그인 후 구매가능합니다." : name}</p>
                        <p style={{ margin: 0, fontFamily: "Gmarket-M" }}>{user.phoneNum ? user.phoneNum.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3") : ""}</p>
                      </Row>
                    </div>
                  </div>

                  <div style={{ width: "100%", height: "10px" }}></div>

                  <div>
                    <div
                      style={{ height: "50px", border: "1px solid #e9ebec", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 20px" }}
                      onClick={() => handleTabClick(1)}
                    >
                      <h5 className="" style={{ fontFamily: "Gmarket-M", margin: 0 }}>
                        주문상품
                      </h5>
                      {tabList[1] ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                    </div>
                    <div style={{ border: "1px solid #e9ebec", padding: "20px 20px", display: tabList[1] ? "block" : "none" }}>
                      <Row className="gy-3">
                        <div className="">
                          <div className="form-check-label" htmlFor="orderInfo01">
                            <span className="mb-2 fw-semibold text-uppercase" style={{ fontFamily: "Gmarket-M" }}>
                              티켓 종합 구매
                              <span className="mb-2 text-uppercase" style={{ marginLeft: "5px" }}>
                                ({location.state.products[0].site})
                              </span>
                            </span>

                            <div className="text-muted fw-normal text-wrap my-3">
                              {location.state.products.map((product) => {
                                const couponSelected = selectCoupon.find((coupon) => coupon.product_id === product.id);
                                if (product.quantity)
                                  return (
                                    <div key={product.id} className="fw-normal text-wrap mb-1 mx-1" style={{ borderBottom: "1px solid #ededed", padding: "10px 0" }}>
                                      <span>
                                        <span style={{ fontFamily: "Gmarket-M", color: "#f6475f" }}>{product.name}</span>
                                      </span>
                                      <span className="d-block" style={{ textAlign: "right" }}>
                                        {product.price.toLocaleString()}원 X {product.quantity}개
                                      </span>
                                      <p
                                        className="fw-bold mt-2 btn btn-primary"
                                        onClick={() => {
                                          setTargetTicket(product);
                                          setCouponModal(true);
                                        }}
                                      >
                                        쿠폰선택하기
                                      </p>
                                      {couponSelected ? (
                                        <div key={couponSelected.id} className="fw-normal text-wrap mb-1 mx-1" style={{ padding: "10px 0" }}>
                                          <span className="d-block" style={{ textAlign: "right" }}>
                                            {calcCouponSaleAmount(couponSelected, product.price * product.quantity).toLocaleString()}원 할인
                                          </span>
                                        </div>
                                      ) : null}
                                    </div>
                                  );
                                else return null;
                              })}
                            </div>

                            <p style={{ textAlign: "right", marginTop: "10px" }}>
                              총 금액 <span className="w-normal text-wrap mb-1" style={{ fontFamily: "GMarket-M", color: "#F64760" }}>{` ${totalOrderPrice().toLocaleString()}원`}</span>
                            </p>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </div>

                  <div style={{ width: "100%", height: "10px" }}></div>

                  <div>
                    <div style={{ height: "50px", border: "1px solid #e9ebec", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 20px" }}>
                      <h5 className="" style={{ fontFamily: "Gmarket-M", margin: 0 }}>
                        취소 및 환불 규정
                      </h5>
                    </div>
                    <div style={{ border: "1px solid #e9ebec", padding: "20px 20px" }}>
                      <Row className="gy-3">
                        <Col sm={6}>
                          <label className="form-check-label" htmlFor="-">
                            사용기간 내의 미사용 100% 환불
                            <br />
                            (유효기간 이후 환불불가)
                          </label>
                        </Col>

                        <Col sm={12}>
                          <div className="form-check">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="cancelRuleAgree"
                              checked={cancelRuleAgree}
                              onChange={(e) => {
                                setCancelRuleAgree(e.target.checked);
                              }}
                            />
                            <label className="form-check-label" htmlFor="cancelRuleAgree">
                              동의하기
                            </label>
                          </div>
                        </Col>

                        <Col sm={12}>
                          <div className="form-check">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="purchaseOneAgree"
                              checked={purchaseOneAgree}
                              onChange={(e) => {
                                togglePurchaseAgreeOptions(e.target.checked);
                              }}
                            />
                            <label className="form-check-label" htmlFor="purchaseOneAgree">
                              (필수)구매조건 확인 및 결제진행에 동의
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="d-flex align-items-start gap-3 mt-4">
                    <button
                      type="button"
                      className="btn right ms-auto nexttab"
                      style={{ backgroundColor: "#f6475f", color: "#fff", fontFamily: "Gmarket-M", margin: "0 auto", width: "100%", padding: "12px 0" }}
                      onClick={() => {
                        processPayment();
                      }}
                      disabled={!cancelRuleAgree || !purchaseAllAgree}
                    >
                      결제하기
                      <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2" style={{ backgroundColor: "#f6475f", color: "#fff", marginLeft: "10px" }}></i>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <CouponTicketSelectModal
          setVisible={setCouponModal}
          visible={couponModal}
          ticket={targetTicket}
          selectCoupon={selectCoupon}
          setSelectCoupon={setSelectCoupon}
          toggleTicketDiscountCouponSelection={toggleTicketDiscountCouponSelection}
          navigate={navigate}
        />
      </React.Fragment>
    </BgFrame>
  );
};

export default MultiOrderProcess;
