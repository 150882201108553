function calcCouponSaleAmount(obj, price) {
    //  discount_amount discount_type min_purchase discount_rate max_discount
    // console.log("calcCouponSaleAmount: ", obj, price);
    if (price < obj.min_purchase) {
        return 0;
    }
    if (obj.discount_type === "price") {
        if (price < obj.discount_amount) {
            return price;
        }
        return obj.discount_amount;
    } else {
        let _amount = Math.floor(price * obj.discount_rate / 100);
        if (_amount > obj.max_discount) {
            return obj.max_discount;
        }
        return _amount;
    }
}

export { calcCouponSaleAmount };