import React, { useEffect } from "react";
import MyEditor from "./MyEditor";
import { Col, Row, Button, Modal, Label, Input, ModalHeader, ModalBody, ListGroup, ListGroupItem } from "reactstrap";

import { useNavigate } from "react-router-dom";
import { SITE_ID } from "../../assets/meta/meta";
import { getAccessToken } from "../../api/auth";
import { API, BACKEND_HOST } from "../../api";
import axios from "axios";
import { h } from "gridjs";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import heic2any from "heic2any";

const SloganModal = (props) => {
  const [content, setContent] = React.useState(props.origin);
  const [textcontent, setTextContent] = React.useState(props.origin);

  const togModal = () => {
    props.setVisible(!props.visible);
  };

  const togSlogan = () => {
    props.setSlogan(!props.slogan);
  };

  const updateSlogan = () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      let value = textcontent;
      if (!value) value = "";
      console.log("fetch slogan update:", props.site, value);

      API.put(
        `/sites/slogan/${props.site}/`,
        { slogan: value },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
        .then((response) => {
          const data = response.data;
          props.navigate(0);
        })
        .catch((error) => {
          alert("Update slogan fail");
          console.error(error);
        });
    }
  };

  useEffect(() => {
    setContent(props.origin);
  }, [props.origin]);

  return (
    <Modal isOpen={props.visible} toggle={togModal} modalClassName="fadeInUp" centered>
      <div className="my-3 mx-3">
        <Label htmlFor="basiInput" className="form-label fs-15">
          슬로건 수정
        </Label>
        <MyEditor
          value={content}
          onChange={setContent}
          noImage={true}
          onTextChange={(textvalue) => {
            setTextContent(textvalue);
          }}
        />
        {/* <Input type="text" className="form-control" id="basiInput" value={props.sloganText} onChange={(e)=>InputEditText(e)}/> */}
        {/* <div className="form-check form-switch form-switch-md my-2" dir="ltr">
                    <Input type="checkbox" className="form-check-input" id="sloganSwitchbox"
                        onChange={togSlogan} checked={props.slogan?true:false}
                    />
                    <Label for="sloganSwitchbox">슬로건 표시 여부</Label>
                </div> */}
        <div>내용을 빈칸으로 비우면 슬로건 컨텐츠 영역이 숨겨집니다.</div>
        <div className="d-flex justify-content-end my-3">
          <Button
            color="light"
            className="ms-2 btn float-right"
            onClick={() => {
              updateSlogan();
              props.setVisible(!props.visible);
            }}
            // disabled={!props.sloganText}
          >
            적용
          </Button>
          <Button
            color="light"
            className="ms-2 btn float-right"
            onClick={() => {
              props.setVisible(!props.visible);
            }}
          >
            닫기
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const DeleteCarouselModal = (props) => {
  const TYPE_MAP = {
    0: {
      url: `/sites/carousels/${props.carouselId}/`,
      comment: "이미지 그룹을",
    },
    1: {
      url: `/sites/link/${props.snsLinkId}/`,
      comment: "SNS 링크를",
    },
  };

  const togDeleteModal = () => {
    props.setDeleteModal(!props.deleteModal);
  };

  const deleteData = () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      API.delete(TYPE_MAP[props.type].url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          const data = response.data;
          props.navigate(0);
        })
        .catch((error) => {
          alert("Delete fail");
          console.error(error);
        });
    }
  };

  return (
    <Modal
      id="topmodal"
      isOpen={props.deleteModal}
      toggle={() => {
        togDeleteModal();
      }}
    >
      <ModalHeader
        className="modal-title"
        id="myModalLabel"
        toggle={() => {
          togDeleteModal();
        }}
      >
        삭제하기
      </ModalHeader>
      <ModalBody className="text-center p-5">
        <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" style={{ width: "120px", height: "120px" }} colors="primary:#f7b84b,secondary:#f06548"></lord-icon>
        <div className="mt-4">
          <h5 className="mb-3">{`해당 ${TYPE_MAP[props.type].comment} 삭제하시겠습니까?`}</h5>
          <div className="hstack gap-2 justify-content-center">
            <button
              className="btn bg-gradient-light fw-medium"
              onClick={() => {
                togDeleteModal();
              }}
            >
              <i className="ri-close-line me-1 align-middle"></i>닫기
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                deleteData();
                props.setDeleteModal(false);
              }}
            >
              삭제하기
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

const CarouselModal = (props) => {
  const carouselInputRef = React.useRef(null);
  const [carousel, setCarousel] = React.useState([]);

  const [existingCarousel, setExistingCarousel] = React.useState([]);

  const [deleteModal, setDeleteModal] = React.useState(false);
  const [nowCarouselList, setNowCarouselList] = React.useState([]);

  useEffect(() => {
    if (props.carouselData) {
      // console.log("props.carouselData", props.carouselData);
      setNowCarouselList(props.carouselData);
    }
  }, [props.carouselData]);

  const togModal = () => {
    setCarousel([]);
    props.setVisible(!props.visible);
  };

  const handleUpdateCarouselOrder = () => {
    const modifiedData = nowCarouselList.map((item, index) => {
      const { id, images, ...rest } = item;
      return {
        id: item.id,
        display_level: index, // 수정하려는 속성을 인덱스 값으로 변경
      };
    });

    let _carouselList = {
      carousels: modifiedData,
    };
    updateCarouselOrder(_carouselList);
  };

  const updateCarouselOrder = (data) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      API.put("/sites/carousels/update-order/", data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          const responseData = response.data;
          props.navigate(0);
        })
        .catch((error) => {
          alert("Update carousel fail");
          console.error(error);
        });
    }
  };

  const handleImageFiles = async (e) => {
    const files = e.target.files;
    console.log("files", files);
    if (files.length !== 0) {
      let imgFiles = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // let reader = new FileReader();

        if (file.type === "image/heic" || file.type === "image/heif") {
          const convertedImageBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.5,
          });

          const reader = new FileReader();
          reader.readAsDataURL(convertedImageBlob);
          reader.onloadend = () => {
            const base64data = reader.result;
            imgFiles.push(base64data);
            if (imgFiles.length === files.length) {
              setCarousel([...imgFiles]);
              console.log("filesurl", [...imgFiles]);
            }
          };
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            const base64data = reader.result;
            imgFiles.push(base64data);
            if (imgFiles.length === files.length) {
              setCarousel([...imgFiles]);
              console.log("filesurl", [...imgFiles]);
            }
          };
        }

        // reader.onload = () => {
        //   imgFiles[i] = reader.result;
        //   setCarousel([...imgFiles]);
        //   console.log("filesurl", [...imgFiles]);
        // };

        // reader.readAsDataURL(file);
      }
    }
  };

  const handleImageUploadFiles = async (e) => {
    const files = e.target.files;
    console.log("files", files);
    if (files.length !== 0) {
      let imgFiles = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        let convertFile;
        // let reader = new FileReader();

        if (file.type === "image/heic" || file.type === "image/heif") {
          const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.5,
          });

          convertFile = new File([convertedBlob], file.name + ".jpeg", {
            type: convertedBlob.type,
          });
        }

        let formData = new FormData();

        formData.append("img", file.type === "image/heic" || file.type === "image/heif" ? convertFile : file);
        formData.append("display_level", 0);
        // formData.append("url", "string");
        // formData.append("title", "string");
        // formData.append("description", "string");
        formData.append("carousel", props.carouselId);

        updateCarouselImg(formData);
      }
    }
  };

  const updateCarouselImg = (formData) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      API.post("/sites/carousel-image-admin/", formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          console.log("upload data", response.data);
          refetchCarouselImg();
        })
        .catch((error) => {
          alert("Update carousel fail");
          console.error(error);
        });
    }
  };

  const deleteCarouselImg = (id) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      API.delete(`/sites/carousel-image-admin/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          console.log("delete data", response.data);
          refetchCarouselImg();
        })
        .catch((error) => {
          alert("delete carousel fail");
          console.error(error);
        });
    }
  };

  const handleUpdateImgOrder = () => {
    const modifiedData = existingCarousel.map((item, index) => {
      const { display_level, ...rest } = item;
      return {
        ...rest,
        display_level: index, // 수정하려는 속성을 인덱스 값으로 변경
      };
    });
    console.log("handleUpdateImgOrder", modifiedData);
    const promises = [];

    modifiedData.forEach((item) => {
      promises.push(updateCarousel(item));
    });
    Promise.all(promises)
      .then((data) => {
        console.log("handle upload carousel", data);
      })
      .catch((e) => console.log("Error...", e));
  };

  const updateCarousel = (data) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      axios
        .put(
          `${BACKEND_HOST}/sites/carousel-image-admin/${data.key}/`,
          {
            display_level: data.display_level,
            carousel: props.carouselId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          props.navigate(0);
          console.log("upload data", response.data);
        })
        .catch((error) => {
          // alert("Update carousel fail");
          console.error(error);
        });
    }
  };

  const addCarousel = () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      API.post(
        `/sites/carousels/${0}/`,
        { carousel },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

        .then((data) => {
          props.navigate(0);
          console.log(data);
        })
        .catch((error) => {
          alert("Create carousel fail");
          console.error(error);
        });
    }
  };

  useEffect(() => {
    if (!props.carouselId || props.carouselId === -1) {
      return;
    }

    // console.log("existingCarousel", existingCarousel);
    API.get(`/sites/carousel/${props.carouselId}/`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => {
        setExistingCarousel(res.data.images);
        // console.log("existingCarousel res.data", res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.carouselId]);

  const refetchCarouselImg = () => {
    API.get(`/sites/carousel/${props.carouselId}/`, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
      .then((res) => {
        setExistingCarousel(res.data.images);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleOnDragStart = () => {
    console.log("Drag started");
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const newCardGroups = [...carousel];

    const source = result.source;
    const destination = result.destination;

    const [removed] = newCardGroups.splice(source.index, 1);
    newCardGroups.splice(destination.index, 0, removed);

    setCarousel(newCardGroups);
  };

  const handleOnUpdateDragEnd = (result) => {
    if (!result.destination) return;

    const newCardGroups = [...existingCarousel];

    const source = result.source;
    const destination = result.destination;

    const [removed] = newCardGroups.splice(source.index, 1);
    newCardGroups.splice(destination.index, 0, removed);

    setExistingCarousel(newCardGroups);
  };

  const handleOnCarouselOrderDragEnd = (result) => {
    if (!result.destination) return;

    const newCardGroups = [...nowCarouselList];

    const source = result.source;
    const destination = result.destination;

    const [removed] = newCardGroups.splice(source.index, 1);
    newCardGroups.splice(destination.index, 0, removed);

    setNowCarouselList(newCardGroups);
  };

  return (
    <React.Fragment>
      <Modal isOpen={props.visible} toggle={togModal} modalClassName="fadeInUp" centered>
        {props.modalNumber === -1 ? (
          <Row className="align-items-center g-3 mx-2 my-3">
            <Col>
              <div>
                <Label htmlFor="formFile" className="form-label">
                  이미지 추가하기
                </Label>
                <p className="text-muted">이미지 여러 장 선택 가능</p>
                <Input id="formFile" className="form-control" type="file" accept="image/*,.heic,.heif" ref={carouselInputRef} onChange={(e) => handleImageFiles(e)} multiple />
                <p className="mt-2">
                  드래그로 순서를 변경할 수 있습니다.
                  <br />
                  *최대 업로드 가능: 100mb / *가능한 확장자: png,jpg,jpeg,heic
                </p>
              </div>
            </Col>

            <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={handleOnDragStart}>
              <div>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided) => (
                    <div className="card-list" ref={provided.innerRef} {...provided.droppableProps} style={{ display: "flex", padding: "grid", overflow: "auto" }}>
                      {carousel && carousel.length
                        ? carousel.map((url, index) => {
                            return (
                              <Draggable draggableId={`draggable_${index}`} key={index} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      userSelect: "none",
                                      padding: 16,
                                      margin: "0 0 8px 0",
                                      minHeight: "40px",
                                      backgroundColor: "#f0f0f0",
                                      color: "black",
                                      borderWidth: 0,
                                      borderRadius: 4,
                                      display: "inline-block",
                                      position: "relative",
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <Button
                                      style={{ position: "absolute", right: 0 }}
                                      onClick={() => {
                                        setCarousel(carousel.filter((_, i) => i !== index));
                                      }}
                                    >
                                      삭제
                                    </Button>
                                    <img src={url} alt={"img"} style={{ width: "80px", height: "80px", objectFit: "contain" }} data-position={index} />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })
                        : null}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>

            <div className="d-flex justify-content-end mb-3">
              {props.modalNumber < 0 ? (
                <Button
                  color="light ms-2"
                  onClick={() => {
                    addCarousel();
                    props.setVisible(!props.visible);
                  }}
                  disabled={!carousel.length}
                >
                  추가하기
                </Button>
              ) : null}
              <Button
                color="light ms-2"
                onClick={() => {
                  props.setVisible(!props.visible);
                  setCarousel([]);
                }}
              >
                닫기
              </Button>
            </div>
          </Row>
        ) : props.modalNumber >= 0 ? (
          <Row className="align-items-center g-3 mx-2 my-3">
            <Col>
              <div>
                <Label htmlFor="formFile" className="form-label">
                  {`${props.modalNumber + 1}번 홈 이미지 수정`}
                </Label>
                <p className="text-muted">이미지 여러 장 선택 가능</p>
                <Input id="formFile" className="form-control" type="file" accept="image/*,.heic,.heif" ref={carouselInputRef} onChange={(e) => handleImageUploadFiles(e)} multiple />
                <p className="mt-2">
                  드래그로 순서를 변경할 수 있습니다.
                  <br />
                  *최대 업로드 가능: 100mb / *가능한 확장자: png,jpg,jpeg,heic
                </p>

                <DragDropContext onDragEnd={handleOnUpdateDragEnd} onDragStart={handleOnDragStart}>
                  <div>
                    <Droppable droppableId="droppable2" direction="horizontal">
                      {(provided) => (
                        <div className="card-list" ref={provided.innerRef} {...provided.droppableProps} style={{ display: "flex", padding: "grid", overflowX: "scroll", overflowY: "hidden" }}>
                          {existingCarousel && existingCarousel.length > 0
                            ? existingCarousel.map((img, index) => {
                                return (
                                  <Draggable draggableId={`${img.key}`} key={img.key} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          userSelect: "none",
                                          padding: 16,
                                          // margin: "0 0 8px 0",
                                          marginRight: "4px",
                                          marginBottom: "2px",
                                          minHeight: "40px",
                                          backgroundColor: "#f0f0f0",
                                          color: "black",
                                          borderWidth: 0,
                                          borderRadius: 4,
                                          display: "inline-block",
                                          position: "relative",
                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        <Button
                                          style={{ position: "absolute", right: 0 }}
                                          onClick={() => {
                                            deleteCarouselImg(img.key);
                                          }}
                                        >
                                          삭제
                                        </Button>
                                        <img src={img.src} alt={"img"} style={{ width: "80px", height: "80px", objectFit: "contain" }} data-position={index} />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })
                            : null}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </DragDropContext>
              </div>
            </Col>

            <div className="d-flex justify-content-end mb-3">
              <React.Fragment>
                <Button
                  color="soft-danger ms-2"
                  onClick={() => {
                    setDeleteModal(true);
                  }}
                >
                  삭제하기
                </Button>
                <Button
                  color="light ms-2"
                  onClick={() => {
                    handleUpdateImgOrder();
                    props.setVisible(!props.visible);
                  }}
                  disabled={existingCarousel.length === 0}
                >
                  적용
                </Button>
              </React.Fragment>

              <Button
                color="light ms-2"
                onClick={() => {
                  props.setVisible(!props.visible);
                  setCarousel([]);
                  props.navigate(0);
                }}
              >
                닫기
              </Button>
            </div>
          </Row>
        ) : (
          <Row className="align-items-center g-3 mx-2 my-3">
            <Col>
              <div>
                <Label htmlFor="formFile" className="form-label">
                  이미지 그룹 순서 변경
                </Label>
                <p>드래그로 순서를 변경할 수 있습니다.</p>

                <DragDropContext onDragEnd={handleOnCarouselOrderDragEnd} onDragStart={handleOnDragStart}>
                  <div>
                    <Droppable droppableId="droppable3">
                      {(provided) => (
                        <div className="card-list" ref={provided.innerRef} {...provided.droppableProps}>
                          {nowCarouselList && nowCarouselList.length > 0
                            ? nowCarouselList.map((item, index) => {
                                return (
                                  <Draggable draggableId={`draggable3_${index}`} key={index} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          userSelect: "none",
                                          padding: 16,
                                          margin: "0 0 8px 0",
                                          minHeight: "40px",
                                          backgroundColor: "#f0f0f0",
                                          color: "black",
                                          borderWidth: 0,
                                          borderRadius: 4,

                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        {item.id}번 이미지 그룹(현재 {index + 1}번째)
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })
                            : null}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </DragDropContext>
              </div>
            </Col>

            <div className="d-flex justify-content-end mb-3">
              <Button
                color="light ms-2"
                onClick={() => {
                  handleUpdateCarouselOrder();
                  props.setVisible(!props.visible);
                }}
                disabled={!nowCarouselList.length}
              >
                적용
              </Button>
              <Button
                color="light ms-2"
                onClick={() => {
                  props.setVisible(!props.visible);
                  setCarousel([]);
                }}
              >
                닫기
              </Button>
            </div>
          </Row>
        )}
      </Modal>

      {/* Delete Modal */}
      <DeleteCarouselModal type={0} carouselId={props.carouselId} deleteModal={deleteModal} setDeleteModal={setDeleteModal} navigate={props.navigate} />
    </React.Fragment>
  );
};

// 기존의 데이터가 모달의 에디터에 안 들어감 => 아직 해결 안 됨.(2022-11-05)
const FooterModal = (props) => {
  const [content, setContent] = React.useState(props.origin);

  const togModal = () => {
    props.setVisible(!props.visible);
  };

  const updateFooter = () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      API.put(`/sites/footer/${props.site}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: { footer: content },
      })
        .then((response) => {
          props.navigate(0);
        })
        .catch((e) => {
          alert("Update slogan fail");
          console.log(e);
        });
    }
  };

  return (
    <Modal isOpen={props.visible} toggle={togModal} modalClassName="fadeInUp" centered>
      <div className="mx-3 my-3">
        <h3>상세정보 수정</h3>
        <MyEditor value={content} onChange={setContent} noImage={true} />
        <div className="d-flex justify-content-end my-3">
          <Button
            color="light ms-2"
            onClick={() => {
              updateFooter();
              props.setVisible(!props.visible);
            }}
            disabled={!content}
          >
            적용
          </Button>
          <Button
            color="light ms-2"
            onClick={() => {
              props.setVisible(!props.visible);
            }}
          >
            닫기
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const FooterSnsEditModal = (props) => {
  const [deleteTarget, setDeleteTarget] = React.useState(-1);
  const [deleteModal, setDeleteModal] = React.useState(false);

  const togModal = () => {
    props.setVisible(!props.visible);
  };

  const updateSnsLink = (id) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      const url = document.getElementById(`link-${id}`).value;
      API.put(
        `/sites/link/${id}/`,
        { url },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

        .then((data) => {
          props.navigate(0);
        })
        .catch((error) => {
          alert("Update SNS Link fail");
          console.error(error);
        });
    }
  };

  return (
    <React.Fragment>
      <Modal isOpen={props.visible} toggle={togModal} modalClassName="fadeInUp" centered>
        <Row className="align-items-center g-3 mx-2 my-3">
          <Col lg={12}>
            <Label htmlFor="none" className="form-label">
              SNS 수정
            </Label>
            {props.snsList.length !== 0 ? (
              <ListGroup>
                {props.snsList.map((item, idx) => {
                  return (
                    <ListGroupItem key={idx} tag="label">
                      {/* <Input className="form-check-input me-1" type="checkbox" value={item.id} /> */}
                      <i className={`ri-${item.sns}-fill align-middle lh-1 me-2`}></i>
                      {`${item.sns} - 주소: ${item.url}`}
                      <Row className="mt-4">
                        <Col lg={7} xs={6}>
                          <Input type="text" className="form-control" id={`link-${item.id}`} placeholder="수정할 주소를 입력해주세요" />
                        </Col>
                        <Col lg={5} xs={6}>
                          <Button
                            color="light ms-2"
                            onClick={() => {
                              updateSnsLink(item.id);
                            }}
                          >
                            수정
                          </Button>
                          <Button
                            color="soft-danger ms-2"
                            onClick={() => {
                              setDeleteTarget(item.id);
                              setDeleteModal(true);
                            }}
                          >
                            삭제
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            ) : null}
          </Col>
          <div className="d-flex justify-content-end mb-3">
            <Button
              color="light ms-2"
              onClick={() => {
                props.setVisible(!props.visible);
              }}
            >
              닫기
            </Button>
          </div>
        </Row>
      </Modal>

      {/* Delete Modal */}
      <DeleteCarouselModal type={1} snsLinkId={deleteTarget} deleteModal={deleteModal} setDeleteModal={setDeleteModal} navigate={props.navigate} />
    </React.Fragment>
  );
};

const FooterSnsAddModal = (props) => {
  const [selectSnsOption, setSelectSnsOption] = React.useState("");
  const [link, setLink] = React.useState("");

  const togModal = () => {
    props.setVisible(!props.visible);
  };

  const addSns = () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      API.post(
        "/sites/link/0/",
        { url: link, sns: selectSnsOption },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

        .then((data) => {
          props.navigate(0);
        })
        .catch((error) => {
          alert("Create SNS link fail");
          console.error(error);
        });
    }
  };

  return (
    <Modal isOpen={props.visible} toggle={togModal} modalClassName="fadeInUp" centered>
      <Row className="align-items-center g-3 mx-2 my-3">
        <Col lg={12}>
          <Label className="form-label fs-15">SNS 링크 추가</Label>
          <select className="form-select mb-3" aria-label="Default select example" name="sns" onChange={(e) => setSelectSnsOption(e.target.value)}>
            <option value="">옵션을 선택해 주세요</option>
            <option value="facebook">facebook</option>
            <option value="instagram">instagram</option>
          </select>

          <div>
            <Label htmlFor="basicInput" className="form-label">
              SNS 계정 주소를 입력해주세요
            </Label>
            <Input disabled={!selectSnsOption} type="text" className="form-control" id="basicInput" onChange={(e) => setLink(e.target.value)} />
          </div>
        </Col>
        <div className="d-flex justify-content-end mb-3">
          <Button
            color="light ms-2"
            onClick={() => {
              addSns();
              props.setVisible(!props.visible);
            }}
            disabled={!(link && selectSnsOption)}
          >
            적용
          </Button>
          <Button
            color="light ms-2"
            onClick={() => {
              props.setVisible(!props.visible);
            }}
          >
            닫기
          </Button>
        </div>
      </Row>
    </Modal>
  );
};

/* 로그인 타이틀 이미지 모달 */
const LoginTitleImgModal = (props) => {
  const [image, setImage] = React.useState([]);
  const imageInputRef = React.useRef(null);

  const togModal = () => {
    props.setVisible(!props.visible);
  };

  return (
    <Modal isOpen={props.visible} toggle={togModal} modalClassName="fadeInUp" centered>
      <Row className="align-items-center g-3 mx-2 my-3">
        <Col>
          <div>
            <Label htmlFor="formFile" className="form-label">
              로그인 타이틀 이미지 수정
            </Label>
            <Input className="form-control" id="formFile" accept="image/jpg, image/jpeg, image/png" type="file" ref={imageInputRef} />
          </div>
        </Col>
        <div className="d-flex justify-content-end mb-3">
          <Button
            color="light ms-2"
            onClick={() => {
              props.setVisible(!props.visible);
            }}
          >
            적용
          </Button>
          <Button
            color="light ms-2"
            onClick={() => {
              props.setVisible(!props.visible);
            }}
          >
            닫기
          </Button>
        </div>
      </Row>
    </Modal>
  );
};

/* kakaomap 페이지 모달 */
const KakaoMapModal = (props) => {
  const { daum } = window;

  const [address, setAddress] = React.useState("");
  const [detailAddress, setDetailAddress] = React.useState("");

  const togModal = () => {
    props.setVisible(!props.visible);
  };

  const updateCoordinate = () => {
    // props.setAddress(address + detailAddress);
    const accessToken = getAccessToken();
    if (accessToken) {
      const addressResult = address + " " + detailAddress;
      API.put(
        `/sites/location/${SITE_ID}/`,
        { address: addressResult },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

        .then((data) => {
          props.navigate(0);
        })
        .catch((error) => {
          alert("Update map fail");
          console.error(error);
        });
    }
  };

  const addressSearch = () => {
    new daum.Postcode({
      oncomplete: function (data) {
        // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

        // 각 주소의 노출 규칙에 따라 주소를 조합한다.
        // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
        var addr = ""; // 주소 변수
        var extraAddr = ""; // 참고항목 변수

        //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
        if (data.userSelectedType === "R") {
          // 사용자가 도로명 주소를 선택했을 경우
          addr = data.roadAddress;
        } else {
          // 사용자가 지번 주소를 선택했을 경우(J)
          addr = data.jibunAddress;
        }

        // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
        if (data.userSelectedType === "R") {
          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
            extraAddr += data.bname;
          }
          // 건물명이 있고, 공동주택일 경우 추가한다.
          if (data.buildingName !== "" && data.apartment === "Y") {
            extraAddr += extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
          }
          // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if (extraAddr !== "") {
            extraAddr = " (" + extraAddr + ")";
          }
          // 조합된 참고항목을 해당 필드에 넣는다.
          document.getElementById("extraAddress").value = extraAddr;
        } else {
          document.getElementById("extraAddress").value = "";
        }

        // 우편번호와 주소 정보를 해당 필드에 넣는다.
        document.getElementById("postcode").value = data.zonecode;
        document.getElementById("address").value = addr;
        // 커서를 상세주소 필드로 이동한다.
        document.getElementById("detailAddress").focus();
        setAddress(data.address);
      },
    }).open();
  };

  return (
    <Modal isOpen={props.visible} toggle={togModal} modalClassName="fadeInUp" centered>
      <Row className="align-items-center g-3 mx-2 my-3">
        <Col>
          <div className="d-flex mb-3 justify-content-between">
            <input disabled type="text" className="form-control w-50" id="postcode" placeholder="우편번호"></input>
            <Button type="button" onClick={() => addressSearch()}>
              우편번호 찾기
            </Button>
          </div>
          <input type="text" className="form-control mb-3" onChange={(e) => setAddress(e.target.value)} id="address" placeholder="주소" disabled></input>
          <input type="text" className="form-control mb-3" id="detailAddress" onChange={(e) => setDetailAddress(e.target.value)} placeholder="상세주소"></input>
          <input disabled type="text" className="form-control w-25" id="extraAddress" placeholder="참고항목"></input>
        </Col>

        <div className="d-flex justify-content-end mb-3">
          {props.isNotice ? (
            <Button
              color="light ms-2"
              onClick={() => {
                props.setAddress(address + detailAddress);
                props.setVisible(!props.visible);
              }}
            >
              입력
            </Button>
          ) : (
            <Button
              color="light ms-2"
              onClick={() => {
                updateCoordinate();
                props.setVisible(!props.visible);
              }}
            >
              적용
            </Button>
          )}

          <Button
            color="light ms-2"
            onClick={() => {
              props.setVisible(!props.visible);
            }}
          >
            닫기
          </Button>
        </div>
      </Row>
    </Modal>
  );
};

/* detail 페이지 모달*/
const DetailImgModal = (props) => {
  const navigate = useNavigate();
  const imageInputRef = React.useRef(null);

  const [image, setImage] = React.useState([]);

  const togModal = () => {
    props.setVisible(!props.visible);
  };

  const handleImageFiles = async (e) => {
    const files = e.target.files;

    if (files.length !== 0) {
      let imgFiles = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // let reader = new FileReader();
        if (file.type === "image/heic" || file.type === "image/heif") {
          const convertedImageBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.5,
          });

          const reader = new FileReader();
          reader.readAsDataURL(convertedImageBlob);
          reader.onloadend = () => {
            const base64data = reader.result;
            imgFiles.push(base64data);
            if (imgFiles.length === files.length) {
              setImage([...imgFiles]);
            }
          };
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            const base64data = reader.result;
            imgFiles.push(base64data);
            if (imgFiles.length === files.length) {
              setImage([...imgFiles]);
            }
          };
        }

        // reader.onload = () => {
        //   imgFiles[i] = reader.result;
        //   setImage([...imgFiles]);
        // };

        // reader.readAsDataURL(file);
      }
    }
  };

  const updateImage = () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      API.put(
        `/${props.type}/image/${props.itemId}/`,
        { image },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

        .then((data) => {
          props.navigate(0);
        })
        .catch((error) => {
          alert("Update image fail");
          console.error(error);
        });
    }
  };

  return (
    <Modal isOpen={props.visible} toggle={togModal} modalClassName="fadeInUp" centered>
      <Row className="align-items-center g-3 mx-2 my-3">
        <Col>
          <div>
            <Label htmlFor="formFile" className="form-label">
              상세이미지 수정
            </Label>
            <Input className="form-control" id="formFile" accept="image/*,.heic,.heif" type="file" ref={imageInputRef} onChange={(e) => handleImageFiles(e)} multiple />
          </div>
        </Col>
        <div className="d-flex justify-content-end mb-3">
          <Button
            color="light ms-2"
            onClick={() => {
              updateImage();
              props.setVisible(!props.visible);
            }}
          >
            적용
          </Button>
          <Button
            color="light ms-2"
            onClick={() => {
              props.setVisible(!props.visible);
            }}
          >
            닫기
          </Button>
        </div>
      </Row>
    </Modal>
  );
};

const DetailInfoModal = (props) => {
  const [content, setContent] = React.useState("");

  const togModal = () => {
    props.setVisible(!props.visible);
  };

  const updateInfo = () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      API.put(
        `/${props.type}/descript/${props.itemId}/`,
        { descript: content },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

        .then((data) => {
          props.navigate(0);
        })
        .catch((error) => {
          alert("Info update fail");
          console.error(error);
        });
    }
  };

  return (
    <Modal isOpen={props.visible} toggle={togModal} modalClassName="fadeInUp" centered>
      <div className="mx-3 my-3">
        <h3>상세정보 수정</h3>
        <MyEditor value={content} onChange={setContent} />
        <div className="d-flex justify-content-end my-3">
          <Button
            color="light ms-2"
            onClick={() => {
              updateInfo();
              props.setVisible(!props.visible);
            }}
          >
            적용
          </Button>
          <Button
            color="light ms-2"
            onClick={() => {
              props.setVisible(!props.visible);
            }}
          >
            닫기
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const DetailUseAdviceModal = (props) => {
  const [content, setContent] = React.useState("");

  const togModal = () => {
    props.setVisible(!props.visible);
  };
  /* 아직 버튼 적용(함수 호출은 안함) */
  const updateAdvice = () => {
    const accessToken = getAccessToken();
    if (accessToken) {
      API.put(
        `/${props.type}/info/${props.itemId}/`,
        { usingInfo: content },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )

        .then((data) => {
          props.navigate(0);
        })
        .catch((error) => {
          alert("Info update fail");
          console.error(error);
        });
    }
  };

  return (
    <Modal isOpen={props.visible} toggle={togModal} modalClassName="fadeInUp" centered>
      <div className="mx-3 my-3">
        <h3>이용안내 수정</h3>
        <MyEditor value={content} onChange={setContent} />
        <div className="d-flex justify-content-end my-3">
          <Button
            color="light ms-2"
            onClick={() => {
              updateAdvice();
              props.setVisible(!props.visible);
            }}
          >
            적용
          </Button>
          <Button
            color="light ms-2"
            onClick={() => {
              props.setVisible(!props.visible);
            }}
          >
            닫기
          </Button>
        </div>
      </div>
    </Modal>
  );
};

// export default ManagerModal
/* landing */
export { SloganModal, CarouselModal, FooterModal, DetailImgModal, FooterSnsEditModal, FooterSnsAddModal };
/* detail */
export { DetailInfoModal, DetailUseAdviceModal };
/* login */
export { LoginTitleImgModal };
/* map */
export { KakaoMapModal };
