import React from "react";
import { Container, Row, Col, Card, CardHeader, Modal, ModalBody, ModalHeader } from "reactstrap";

import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { API, BACKEND_HOST } from "../../../api";
import { getAccessToken } from "../../../api/auth";

const UnreceivedList = (props) => {
  const navigate = useNavigate();

  const [columns, setColumns] = React.useState([]);
  const [eventProducts, setEventProducts] = React.useState([]);

  const [modal_positionTop, setmodal_positionTop] = React.useState(false);
  function tog_positionTop() {
    setmodal_positionTop(!modal_positionTop);
    clearSelectedOptions();
  }

  const clearSelectedOptions = () => {};

  const receiveEventProducts = async () => {
    const checkboxes = document.querySelectorAll(".customerCheckBox:checked");
    const checkall = document.getElementById("checkBoxAll");

    if (checkboxes.length === 0) {
      alert(`교환권을(를) 선택해주세요.`);
      return;
    }

    const accessToken = getAccessToken();
    if (!accessToken) navigate(-1);

    try {
      for (const checkbox of checkboxes) {
        await API.post(`/${props.type === "1" ? "coupon" : "voucher"}/process/${checkbox.value}/`, null, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        checkbox.checked = false;
      }

      checkall.checked = false;
      // clearSelectedOptions();
      navigate(0);
    } catch (error) {
      console.log(error);
    }
  };

  const checkedAll = () => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".customerCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const accessToken = getAccessToken();

      if (!accessToken) {
        navigate(`/login?path=/reception/${props.type}`);
      } else {
        try {
          const response = await API.get(`/${props.type === "1" ? "coupon" : "voucher"}/user-non-receipt/`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const data = response.data;

          if (data.vouchers.length === 0) return;

          let columnName = Object.keys(data.vouchers[0]);

          const WIDTH_MAP = { id: 50, 교환권: 150, 만료일: 150 };
          let tmpColumns = [
            {
              Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
              Cell: (cellProps) => {
                return <input type="checkbox" className="customerCheckBox form-check-input" value={cellProps.row.original.id} />;
              },
              id: "#",
              width: 20,
            },
          ];

          if (window.innerWidth > 770) {
            columnName.forEach((element) => {
              const obj = {
                Header: element,
                accessor: element,
                width: WIDTH_MAP[element],
              };
              tmpColumns.push(obj);
            });
          } else {
            columnName.forEach((element) => {
              if (element === "교환권") {
                const obj = {
                  Header: element,
                  accessor: element,
                  width: 100,
                };
                tmpColumns.push(obj);
              }
            });
          }

          setColumns(tmpColumns);
          setEventProducts(data.vouchers);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="mt-4">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">미수신 목록</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        {eventProducts.length > 0 ? (
                          <button className="btn btn-soft-secondary me-1" onClick={() => tog_positionTop()}>
                            교환권 받기
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <div className="card-body pt-0">
                  <div>
                    {eventProducts.length ? (
                      <TableContainer
                        columns={columns}
                        data={eventProducts || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={20}
                        className="custom-header-css"
                        isCustomerFilter={true}
                        theadClass="table-light text-muted"
                      />
                    ) : (
                      <Loader />
                    )}
                  </div>

                  <ToastContainer closeButton={false} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        id="topmodal"
        isOpen={modal_positionTop}
        toggle={() => {
          tog_positionTop();
        }}
      >
        <ModalHeader
          className="modal-title"
          id="myModalLabel"
          toggle={() => {
            tog_positionTop();
          }}
        >
          교환권 받기
        </ModalHeader>
        <ModalBody className="text-center p-5">
          <lord-icon src="https://cdn.lordicon.com/nkmsrxys.json" trigger="loop" style={{ width: "120px", height: "120px" }}></lord-icon>
          <div className="mt-4">
            <h4 className="mb-3">선택하신 교환권을 모두 받으시겠습니까?</h4>
            {/* <p className="text-muted mb-4"> The transfer was not successfully received by us. the email of the recipient wasn't correct.</p> */}
            <div className="hstack gap-2 justify-content-center">
              <button
                className="btn btn-soft-success fw-medium"
                onClick={() => {
                  tog_positionTop();
                }}
              >
                <i className="ri-close-line me-1 align-middle"></i> Close
              </button>
              <button
                className="btn btn-success"
                onClick={() => {
                  receiveEventProducts();
                  setmodal_positionTop(false);
                }}
              >
                받기
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UnreceivedList;
