import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "reactstrap";
import { useUserStore } from "../../../store/store";
import styled from "styled-components";

const PhoneLi = styled.li`
  list-style: none;
  padding: 5px 0px;
  background-color: #f5f5f5;
  border-radius: 5px;
  display: inline-block;
  margin: 5px;
  padding: 5px 8px;
  font-size: 12px;
  cursor: pointer;
`;

const DeleteBtn = styled.button`
  background-color: transparent;
  color: #ff5a5f;
  font-weight: bold;
  border: none;
  /* border-radius: 50%; */
  width: 15px;
  height: 15px;
  font-size: 10px;
  margin-left: 10px;
  cursor: pointer;
`;

function telValidator(args) {
  console.log("telValidator", args);
  if (/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(args)) return true;
  return false;
}
const confirm = (message = null, onConfirm, onCancel) => {
  if (!onConfirm || typeof onConfirm !== "function") return;
  if (onCancel && typeof onCancel !== "function") return;
  const confirmAction = () => {
    if (window.confirm(message)) onConfirm();
    else onCancel();
  };
  return confirmAction;
};

export default function GiftPhoneNumList({ info, setInfo, visible, setVisible, needRefresh, sendItem, manyPeople = false, sendCount = 1 }) {
  const [phoneNum, setPhoneNum] = useState("");
  const [user] = useUserStore((state) => [state.user]);
  const [hashTags, setHashTags] = useState([]);

  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    if (phoneNum.length === 10) {
      setPhoneNum(phoneNum.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
    }
    if (phoneNum.length === 13) {
      setPhoneNum(phoneNum.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
    }
  }, [phoneNum]);

  const handleInputChange = (event) => {
    setPhoneNum(event.target.value);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (isValidPhoneNumber(phoneNum) && hashTags.length < info.stock) {
        setHashTags((prevTags) => {
          const uniqueNumbers = new Set([...prevTags, phoneNum]);
          return [...uniqueNumbers];
        });
        setPhoneNum("");
      } else if (hashTags.length >= info.stock) {
        alert(`현재 전송 가능한 쿠폰 개수는 ${info.stock}개입니다.`);
      } else {
        alert("올바른 전화번호 형식이 아닙니다.");
      }
    }
  };

  const handleDeleteTag = (index) => {
    setHashTags((prevTags) => prevTags.filter((tag, i) => i !== index));
  };

  const isValidPhoneNumber = (number) => {
    if (phoneNum.length === 10) {
      setPhoneNum(phoneNum.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"));
      return true;
    }
    if (phoneNum.length === 13) {
      setPhoneNum(phoneNum.replace(/-/g, "").replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
      return true;
    }
    return false;
  };

  return (
    <div>
      <div className="mx-3 my-3">
        <div className="d-flex justify-content-start cursor-pointer btn-ghost" onClick={() => setShowDescription((prev) => !prev)}>
          {showDescription ? "사용방법 닫기 >" : "사용방법 보기 >"}
        </div>
        {showDescription ? (
          <>
            <div className="my-3 d-flex justify-content-center">
              <h4 className="mb-sm-0" style={{ fontFamily: "Gmarket-M", fontSize: "16px" }}>
                전화번호로 선물하기
              </h4>
            </div>
            <div style={{ marginTop: "10px" }}></div>
            <div>
              <p style={{ fontSize: "14px", lineHeight: "24px", textAlign: "center" }}>
                입력하신 전화번호가 기존의 가입된 회원일 경우 쿠폰이 선물됩니다.
                <br />
                가입되지 않은 전화번호일 경우, 초대장이 전송됩니다.
              </p>
            </div>
            <div style={{ marginTop: "20px" }}></div>
          </>
        ) : null}
        <Form>
          {manyPeople ? (
            <>
              <ul style={{ paddingLeft: 0, border: "1px solid #feedea", height: "80px", overflowY: "scroll" }}>
                {hashTags.map((tag, index) => (
                  <PhoneLi key={index} onClick={() => handleDeleteTag(index)}>
                    {tag} <DeleteBtn>X</DeleteBtn>
                  </PhoneLi>
                ))}
              </ul>
              <Input value={phoneNum} onChange={handleInputChange} onKeyDown={handleEnterKeyPress} type="text" className="form-control" id="gift-reserv-phone" placeholder="휴대폰번호" />
            </>
          ) : (
            <Input
              value={phoneNum}
              onChange={(e) => {
                setPhoneNum(e.currentTarget.value);
              }}
              type="text"
              className="form-control"
              id="gift-reserv-phone"
              placeholder="휴대폰번호"
            />
          )}

          {/* <div style={{ marginTop: "5px", color: "GrayText", marginLeft: "5px" }}>받으실 분의 휴대폰 번호를 입력해 주세요.</div> */}
          {manyPeople ? <div style={{ marginTop: "5px", color: "GrayText", marginLeft: "5px" }}>*전화번호 작성후 엔터를 누르면 번호 추가가 됩니다.</div> : null}
        </Form>
      </div>
      <div className="d-flex justify-content-center mx-1 mb-4">
        <Button
          style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
          color="light ms-2 my-2"
          onClick={() => {
            setPhoneNum("");
            setVisible(false);
            setInfo({});
          }}
        >
          닫기
        </Button>

        {manyPeople ? (
          <Button
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
            onClick={() => {
              if (hashTags.length === 0) {
                alert("보내실 번호를 입력해주세요.");
                return;
              }

              if (hashTags.length * sendCount > info.stock) {
                alert("총 발송 가능한 개수는 " + info.stock + "개 입니다.");
                return;
              }

              confirm(
                `쿠폰을 선물하시겠습니까?`,
                () => {
                  if (info) {
                    const phone_num = hashTags.map((phoneNum) => phoneNum.replaceAll("-", ""));

                    console.log("info", info);

                    sendItem(info.id, phone_num)
                      .then((data) => {
                        if (data.result === "ok" || data.status === "ok") {
                          setPhoneNum("");
                          setInfo({});
                          setVisible(false);
                          needRefresh();
                        } else {
                          alert("전송 실패 " + JSON.stringify(data));
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                        alert("전송 실패");
                      });
                  }
                },
                () => {}
              )();
            }}
            className="btn btn-success ms-2 my-2"
          >
            선물하기
          </Button>
        ) : (
          <Button
            style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
            onClick={() => {
              if (!telValidator(phoneNum)) {
                alert("잘못된 번호 형식입니다!");
                return;
              }

              confirm(
                `${phoneNum} 님께 쿠폰을 선물하시겠습니까?`,
                () => {
                  if (info) {
                    const phone_num = phoneNum.replaceAll("-", "");

                    if (user.phone === phone_num) {
                      alert("본인에게는 선물할 수 없습니다.");
                      return;
                    }

                    sendItem(info.id, phoneNum)
                      .then((data) => {
                        if (data.result === "ok" || data.status === "ok") {
                          alert("전송 성공");
                          setPhoneNum("");
                          setInfo({});
                          setVisible(false);
                          needRefresh();
                        } else {
                          alert("전송 실패 " + JSON.stringify(data));
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                        alert("전송 실패");
                      });
                  }
                },
                () => {}
              )();
            }}
            className="btn btn-success ms-2 my-2"
          >
            선물하기
          </Button>
        )}
      </div>
    </div>
  );
}
