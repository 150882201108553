// const REST_API_KEY = "041d4febca7f997a5abc9c280f2526a2" // old key
const REST_API_KEY = "ef64b8197412015fce14fa9015cbdac8"
const REDIRECT_URI = "https://total.amazingticket.site/accounts/kakao"
const { Kakao } = window;

function shareKakao({
    title,
    description,
    imageUrl,
    shareUrl,
    androidExecutionParams,
    iosExecutionParams,
}) {

    // = "amazingcamp://mypage"

    const _link = {
        webUrl: shareUrl,
        mobileWebUrl: shareUrl
    }
    if (androidExecutionParams) {
        _link.androidExecutionParams = androidExecutionParams
    }
    if (iosExecutionParams) {
        _link.iosExecutionParams = iosExecutionParams
    }

    Kakao.Share.sendDefault({
        objectType: "feed",
        content: {
            title: title,
            description: description,
            imageUrl: imageUrl,
            link: _link,
        },
        buttons: [
            {
                title: "바로가기",
                link: _link,
            },
        ],
    });
}

export { REST_API_KEY, REDIRECT_URI, shareKakao }