import React from "react";
import { Card, Table, Button, ButtonGroup, Input, Row, Col, Spinner, Alert } from "reactstrap";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { API } from "../../../api";
import { getAccessToken } from "../../../api/auth";

const Item = styled.div`
  border-radius: 0.5vh;
  box-shadow: 1px 2px 6px 0px hsl(0deg 0% 64% / 22%);
  background-color: white;
  border: 1px solid #e5e7eb;
  /* max-width: 480px; */
  padding: 10px 20px;
  opacity: ${(props) => (props.visible ? "1" : "0.5")};
  :hover {
    cursor: ${(props) => (props.visible ? "pointer" : "default")};
  }
`;

function CouponList({ todayOnly }) {
  const navigate = useNavigate();

  const [coupons, setCoupons] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);

  const handleCoupon = async (couponId) => {
    const accessToken = getAccessToken();

    try {
      const result = await API.post(
        `/coupon/book/download/${couponId}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("result", result.data);
      if (result.data.status === "ok") {
        alert("쿠폰을 다운로드 받았습니다.");
        setdata();
      }
    } catch (error) {
      console.log(error.response?.data);
    }
  };

  React.useEffect(() => {
    setdata();
  }, []);

  const setdata = () => {
    const accessToken = getAccessToken();
    setIsLoading(true);

    API.get(`/coupon/book/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        const data = response.data;
        // console.log("fetching reservations", data);
        setCoupons(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12 d-flex flex-wrap justify-content-center gap-4" style={todayOnly && coupons.length ? { marginBottom: "16px" } : {}}>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
              <Spinner animation="border" variant="primary" />
            </div>
          ) : null}

          {(coupons || []).map((coupon, key) => (
            <Col lg={12} xxl={12} md={12} sm={12} xs={12} key={coupon.id} className="">
              <Item visible={true} className="w-md-480" style={{ backgroundColor: "#fff", opacity: coupon.downloaded ? 0.5 : 1 }}>
                <div className="d-flex justify-content-between px-2 border-bottom align-items-center">
                  <div className="card-title pt-3" style={{ fontWeight: "600", maxWidth: "65%" }}>
                    {coupon.coupon_product.title}
                  </div>
                  <p
                    className="fw-bold mt-2 btn btn-primary"
                    style={{ opacity: coupon.downloaded ? 0.5 : 1 }}
                    onClick={() => {
                      if (coupon.downloaded) {
                        alert("이미 다운로드 받은 쿠폰입니다.");
                      } else {
                        handleCoupon(coupon.id);
                      }
                    }}
                  >
                    {coupon.downloaded ? "다운완료" : "쿠폰받기"}
                    <br />
                    {coupon.remain > 0 ? <span>{coupon.remain}개 다운가능</span> : null}
                  </p>
                </div>

                <div className="d-flex justify-content-between px-2 border-bottom">
                  <div className="card-title pt-3" style={{ fontWeight: "600", color: "#f64757" }}>
                    {coupon.coupon_product.discount_type === "price" ? `${coupon.coupon_product.discount_amount.toLocaleString()}원 할인쿠폰` : `${coupon.coupon_product.discount_rate}% 할인쿠폰`}
                  </div>
                </div>
                <div className="d-flex justify-content-between px-2 border-bottom">
                  <div className="card-title pt-3" style={{ fontWeight: "400", color: "#5d5d5d" }}>
                    {coupon.coupon_product.min_purchase.toLocaleString()}원 이상 구매시 사용가능
                  </div>
                </div>
                <div className="d-flex px-2">
                  <div className="mt-1" style={{ width: "100%" }}>
                    <p className="card-text py-2" style={{ width: "100%" }}>
                      {/* <FiCalendar size={18} color={"#1d1d1d"} /> */}
                      <span style={{ lineHeight: "32px" }}>만료기한 {coupon.coupon_product.expire_date} </span>
                    </p>
                    {coupon.coupon_product.bound_type === "reservation" ? (
                      <>
                        <p className="card-text mb-1">예약가능기간</p>
                        <p className="card-text pb-3">
                          {coupon.start_date} ~ {coupon.end_date}
                        </p>
                      </>
                    ) : null}
                  </div>
                </div>
              </Item>
            </Col>
          ))}

          {!isLoading && coupons.length === 0 ? (
            <div className="d-flex justify-content-center" style={{ alignItems: "center", height: 150 }}>
              다운받을 쿠폰이 없습니다.
            </div>
          ) : null}
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default CouponList;
