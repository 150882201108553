import React, { useCallback, useEffect } from "react";
import Navbar from "../../../Components/Common/navbar";
import { Button, Card } from "reactstrap";
import { API } from "../../../api";
import CardSection from "../../Ticket/CardSection";

export default function AmazingPassPage() {
  const [infoData, setInfoData] = React.useState({});

  const fetchData = useCallback(async () => {
    try {
      const response = await API.get(`/ticket/get-event-amazingpass-info/`);
      const Imgdata = response.data;

      setInfoData(Imgdata.data);
      console.log("get tickets", Imgdata.data.images);
    } catch (error) {
      console.error("Fetching tickets failed", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="layout-wrapper landing " style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
      <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff", minHeight: "100vh" }}>
        <Navbar />
        {infoData.images && infoData.images.map((item, idx) => <img key={idx} src={item.src} alt="pass" style={{ width: "100%" }} />)}
        <div style={{ padding: "50px 10px", display: "flex", alignItems: "center", flexDirection: "column" }}>
          <CardSection amazingpass={true} />
        </div>
      </div>
    </div>
  );
}
