import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, Button, Alert, Form, Label, Input } from "reactstrap";
import { API } from "../../api";
import { getAccessToken } from "../../api/auth";
import axios, { AxiosError } from "axios";

const AmazingPassInfoModal = ({ info, setInfo, visible, setVisible, refetch }) => {
  const togModal = () => {
    setVisible(!visible);
    document.body.style.overflow = "scroll";
  };

  const handleRefund = async (couponData) => {
    if (!couponData?.transfered_ticket) return;

    const confirmed = window.confirm("환불하시겠습니까?\n어메이징패스는 행사 상품으로 전체 환불만 가능합니다. 묶음 중 1장이라도 사용, 선물 시 미사용분에 대해서도 환불이 불가능합니다.");

    if (confirmed) {
      let error_message = "";
      try {
        await requestTicketCancel(couponData.transfered_ticket.id);
        setVisible(false);
        setInfo({});
        refetch();
        return;
      } catch (error) {
        console.log("requestTicketCancel", error);
        if (error.response?.data?.error) {
          //   console.log(error?.response?.data?.error);
          error_message = error?.response?.data?.error;
        }

        // console.log("error_message", error_message);

        alert(`환불실패: ${error_message}`);
      }
    }
  };

  const requestTicketCancel = async (itemId) => {
    const accessToken = getAccessToken();
    const res = await API.post(
      `/ticket/cancel-ticket/`,
      { id: itemId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const _json = res.data;
    console.log(_json);
    if (_json.result !== "ok") {
      throw new Error(_json.error || "취소 요청이 실패하였습니다.");
    }
    return _json;
  };

  return (
    <React.Fragment>
      {Object.keys(info).length !== 0 ? (
        <Modal toggle={togModal} isOpen={visible} modalClassName="fadeInUp" centered onClosed={() => (document.body.style.overflow = "scroll")}>
          <div className="mx-3 my-3">
            <div className="my-3 d-flex justify-content-center  "></div>
            <ul className="list-group">
              <li className="list-group-item">쿠폰이름: {info?.precoupon_name}</li>

              <li className="list-group-item">만료기한 : {moment(info?.expired_date).format("YYYY-MM-DD")}</li>
              <li className="list-group-item">
                예약가능기간: {info.coupon_start_date} ~ {info.coupon_end_date}
              </li>
              <li className="list-group-item" style={{ height: "150px", overflow: "scroll" }}>
                <p style={{ fontWeight: "bold" }}>*사용 가능 패키지*</p>
                {info?.available_package_names.map((item, idx) => (
                  <p key={idx}>{item.name}</p>
                ))}
              </li>

              <li className="list-group-item" style={{ height: "200px", overflow: "scroll" }}>
                <div style={{ textAlign: "left", fontSize: "12px" }} dangerouslySetInnerHTML={{ __html: info.description }} />
              </li>
            </ul>

            <div style={{ marginTop: "24px" }}></div>
          </div>
          <div className="d-flex justify-content-center mx-1 pb-4">
            {info?.transfered_ticket ? (
              <Button
                color="btn btn-light ms-2 my-2 mx-2"
                style={{ fontFamily: "Gmarket-M", backgroundColor: "#fff", borderColor: "#ff5a5f", color: "#ff5a5f" }}
                onClick={() => {
                  handleRefund(info);
                }}
              >
                환불하기
              </Button>
            ) : null}

            <Button
              color="btn btn-light ms-2 my-2 mx-2"
              style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff" }}
              onClick={() => {
                setInfo({});
                setVisible(false);
              }}
            >
              닫기
            </Button>
          </div>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};
export default AmazingPassInfoModal;
