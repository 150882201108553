import React, { useEffect, useRef, useState } from "react";
import { Container, Col, Row, Card, CardBody, Button } from "reactstrap";
import { Link, navigate, useLocation, useNavigate } from "react-router-dom";

import { API, BACKEND_HOST } from "../../api";
import QuantityButtonInput from "../../Components/Common/QuantityButtonInput";
import { useUserStore } from "../../store/store";
import { getAccessToken } from "../../api/auth";

const CardSection = ({ amazingpass = false }) => {
  const navigate = useNavigate();

  const [tickets, setTickets] = React.useState([]);
  const location = useLocation();

  const accessToken = getAccessToken();

  const [ticketQuantity, setTicketQuantity] = useState([]);
  const [user] = useUserStore((state) => [state.user]);
  const scrollRef = useRef(null);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const button = scrollRef.current;
      const scrollLocation = document.documentElement.scrollTop;
      let windowHeight = window.innerHeight; // 스크린 창
      let fullHeight = document.body.scrollHeight; //  margin 값은 포함 x

      if (scrollLocation + windowHeight >= fullHeight - 500) {
        setIsButtonVisible(false);
      } else {
        setIsButtonVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToRef = (ref) => {
    if (ref.current) {
      window.scrollTo(ref.current.offsetTop, document.body.scrollHeight);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get(`/ticket/products/`);
        const data = response.data;

        console.log("get tickets2", data);

        if (amazingpass) {
          if (user && user.membership !== "") {
            setTickets(
              data
                .filter((item) => item.state === "어메이징패스")
                .map((item, idx) => {
                  if (item.membership_price !== 0 && item.membership_price <= item.price) {
                    return { ...item, price: item.membership_price };
                  } else {
                    return item;
                  }
                })
            );
          } else {
            setTickets(data.filter((item) => item.state === "어메이징패스"));
          }
        } else {
          if (user && user.membership !== "") {
            setTickets(
              data
                .filter((item) => item.state !== "어메이징패스")
                .map((item, idx) => {
                  if (item.membership_price !== 0 && item.membership_price <= item.price) {
                    return { ...item, price: item.membership_price };
                  } else {
                    return item;
                  }
                })
            );
          } else {
            setTickets(data.filter((item) => item.state !== "어메이징패스"));
          }
        }

        setTicketQuantity(
          data.map((item, idx) => {
            return { id: item.id, quantity: 0 };
          })
        );
      } catch (error) {
        console.error("Fetching tickets failed", error);
      }
    };

    fetchData();
  }, [user]);

  // useEffect(() => {
  //   console.log("tickets", tickets);
  // }, [tickets]);

  const ticket_purchase_count = ticketQuantity
    .map((item) => {
      return item.quantity;
    })
    .reduce((prev, curr, obj) => {
      return prev + curr;
    }, 0);

  return (
    <React.Fragment>
      {/* <div className="mt-5" style={{ height: "30px" }}></div> */}
      <Container>
        <Row className="mt-4 d-flex flex-wrap" style={{ paddingBottom: "160px" }}>
          {tickets.map((item, idx) => {
            return (
              <Col key={idx} xxl={6} xl={6} md={6} sm={6} xs={6}>
                <Card className="">
                  <div
                    onClick={() => {
                      if (item.state === "품절") return;
                      if (amazingpass && !accessToken) {
                        alert("로그인이 필요합니다.");
                        navigate(`/login?path=${location.pathname}`);
                        return;
                      }
                      if (item.state !== "품절") navigate(`/detail/ticket/${item.id}`, { state: { selectedDate: null, selectedSpotId: null } });
                    }}
                  >
                    <img className="card-img-top img-fluid" src={`${item.thumbnail}`} alt="Card cap" style={{ height: "200px", objectFit: "contain" }} />
                  </div>
                  <CardBody className="d-flex flex-column justify-content-center align-items-center">
                    <div style={{ fontFamily: "Gmarket-M", fontSize: "13px", textAlign: "center" }}>{item.name}</div>
                    {/* {(item.remaining_stock != 9999 && item.remaining_stock > 0) ? <div>(남은 타켓: {item.remaining_stock}개)</div> : null} */}
                    <div className="card-text mt-2 mb-3" style={{ fontFamily: "Gmarket-M" }}>
                      {user && user.membership !== "" ? (
                        <p style={{ color: "red", textAlign: "center" }}>
                          {/* <strike style={{ color: "#6d6d6d" }}>{item.price.toLocaleString()} 원</strike> */}
                          {/* <br /> */}
                          멤버십 가격 <br /> {item.membership_price.toLocaleString()} 원
                        </p>
                      ) : (
                        <>{item.price.toLocaleString()} 원</>
                      )}
                    </div>

                    {!amazingpass ? (
                      <QuantityButtonInput
                        quantity={ticketQuantity[idx].quantity}
                        onChange={(value) => {
                          if (value > item.remaining_stock) {
                            alert("구매 가능 개수를 초과하였습니다.");
                            return;
                          }
                          const _ticketQuantity = [...ticketQuantity];
                          _ticketQuantity[idx].quantity = value;
                          setTicketQuantity(_ticketQuantity);
                        }}
                      />
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
        {amazingpass ? (
          <Row
            className={`position-fixed d-flex flex-row justify-content-center`}
            style={{ maxWidth: "620px", bottom: "50px", left: "50%", width: "95%", transform: "translate(-50%)", zIndex: 999, margin: "0 auto" }}
          >
            <div ref={scrollRef}>
              <Button
                style={{
                  border: "none",
                  color: "white",
                  background: "#F6475F",
                  width: "100%",
                  borderRadius: "12px",
                  fontSize: "16px",
                  padding: "12px",
                  fontWeight: "bold",
                  visibility: isButtonVisible ? "visible" : "hidden",
                }}
                onClick={() => scrollToRef(scrollRef)}
              >
                구매하기
              </Button>
            </div>
          </Row>
        ) : (
          <Row
            className="position-fixed d-flex flex-row justify-content-center"
            // style={{ bottom: "20px", left: "calc(50vw + 6px)", width: "calc(100vw - 40px)", transform: "translate(-50%)", zIndex: 999 }}
            style={{ maxWidth: "620px", bottom: "100px", left: "50%", width: "95%", transform: "translate(-50%)", zIndex: 999, margin: "0 auto" }}
          >
            <Button
              disabled={!ticket_purchase_count}
              style={{ border: "none", color: "white", background: "#F6475F", width: "100%", borderRadius: "12px", fontSize: "16px", padding: "12px", fontWeight: "bold" }}
              onClick={() => {
                navigate(`/order-ticket-multi`, {
                  state: {
                    products: tickets.map((item, idx) => ({ ...item, quantity: ticketQuantity[idx].quantity })).filter((item) => !!item.quantity),
                  },
                });
              }}
            >
              총 {ticket_purchase_count}개 결제하기
            </Button>
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
};
export default CardSection;
