import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Common/navbar";
import styled from "styled-components";
import { API, BACKEND_HOST } from "../../../api";
import { Button, Col } from "reactstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getAccessToken, isUserLogin } from "../../../api/auth";
import { SITE_ID } from "../../../assets/meta/meta";

const TicketWrapper = styled.div`
  width: 90%;
  min-height: 176px;
  position: relative;
  background-color: #fff;
  border: 1px solid #f6475f;
  padding: 16px 16px 20px 20px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 62px;
    z-index: 1;
    border-bottom: solid 25px rgba(0, 0, 0, 0);
    border-top: solid 25px rgba(0, 0, 0, 0);
    border-left: solid 25px rgba(0, 0, 0, 0);
    border-right: solid 11px #f6475f;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    right: -1px;
    top: 62px;
    z-index: 1;
    border-bottom: solid 25px rgba(0, 0, 0, 0);
    border-top: solid 25px rgba(0, 0, 0, 0);
    border-left: solid 25px rgba(0, 0, 0, 0);
    border-right: solid 11px #fff0f2;
  }
`;

const TicketContent = styled.div`
  width: 100%;
  padding: 10px;
`;

const GiftReservationPage = () => {
    const location = useLocation();

    const accessToken = getAccessToken();
    const [reservItem, setReservItem] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const { id } = useParams();
    const navigate = useNavigate();

    const [siteName, setSiteName] = React.useState("");
    const [siteLogo, setSiteLogo] = React.useState("");
    const [siteSlogan, setSiteSlagon] = React.useState("");
    useEffect(() => {
        API.get(`/sites/camps/${SITE_ID}/`)
            .then((response) => response.data)
            .then((data) => {
                setSiteName(data.name);
                setSiteLogo(data.logo);
                setSiteSlagon(data.slogan);
                console.log("get site", data);
            })
            .catch((error) => console.log(error));
    }, []);

    React.useEffect(() => {
        fetchReservItem();
    }, [accessToken, id]);

    const fetchReservItem = () => {
        setIsLoading(true);
        API.get(`/package/share-gift/${id}/`)
            .then((response) => response.data)
            .then((data) => {
                console.log("get tickets", data);
                setReservItem(data);
            })
            .catch((e) => {
                console.log(e);
                setError(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <div className="layout-wrapper landing " style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
            <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff0f2", minHeight: "100vh" }}>
                <Navbar />

                {!isUserLogin() ? (
                    <div style={{ padding: "50px 10px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <h2 style={{ textAlign: "center", fontSize: "24px", fontWeight: "600", marginBottom: "25px" }}>
                            <span style={{ color: "#ff5a5f" }}>선물받기</span>
                            <br />
                            패키지예약
                        </h2>
                        <TicketWrapper>
                            <TicketContent>

                                <Col style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                    <p style={{ fontSize: "18px", fontWeight: "600", color: "#999" }}>로그인 하여 선물을 확인하세요.</p>
                                </Col>

                                <Col>
                                    <Button
                                        style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff", float: "right" }}
                                        onClick={() => {
                                            navigate(`/login?path=${location.pathname}`);
                                        }}
                                    >
                                        로그인 하기
                                    </Button>
                                </Col>
                            </TicketContent>
                        </TicketWrapper>
                    </div>
                ) : (
                    <div style={{ padding: "50px 10px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        {!isLoading && reservItem && reservItem !== null ? (
                            <>
                                <h2 style={{ textAlign: "center", fontSize: "24px", fontWeight: "600", marginBottom: "25px" }}>
                                    <span style={{ color: "#ff5a5f" }}>선물받기</span>
                                    <br />
                                    패키지예약
                                </h2>

                                <div style={{ width: "100%", paddingInline: "32px", marginBottom: "12px" }}>
                                    <span style={{ fontWeight: "600", fontSize: "16px" }}>{reservItem?.sender} </span>
                                    <span>님이 선물을 보냈습니다.</span>
                                </div>


                                <TicketWrapper>
                                    <TicketContent>

                                        <Col style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                            {reservItem?.image ? <div style={{ marginRight: "14px" }}>
                                                <img src={reservItem.image} alt="Voucher" style={{ width: "100%", marginBottom: "10px", maxWidth: "96px" }} />
                                            </div> : null}
                                            <div>
                                                <p style={{ fontWeight: "600", fontSize: "20px" }}>{reservItem?.name}</p>

                                                <p style={{ marginTop: "10px", color: "#999" }}>예약일: {reservItem?.reservationDate}</p>
                                            </div>
                                        </Col>


                                        {!reservItem.recipient ? (
                                            <Button
                                                style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff", opacity: 1, float: "right" }}
                                                onClick={() => {
                                                    API.put(`/package/receive-reservation-gift/${id}/`, {}).then((response) => {
                                                        console.log("receive-reservation-gift", response);
                                                        alert("선물을 받았습니다.");
                                                    }).catch((e) => {
                                                        console.log(e);
                                                        alert("선물을 받을 수 없습니다.");
                                                    }).finally(() => {
                                                        fetchReservItem();
                                                    });
                                                }}
                                            >
                                                선물받기
                                            </Button>
                                        ) : (
                                            <Button style={{ fontFamily: "Gmarket-M", backgroundColor: "#ff5a5f", borderColor: "#ff5a5f", color: "#fff", float: "right", opacity: 0.5 }} disabled>
                                                이미 선물을 받으셨습니다.
                                            </Button>
                                        )}
                                    </TicketContent>
                                </TicketWrapper>
                                <div style={{ textAlign: "start", width: "90%", margin: "30px auto", lineHeight: "28px", color: "#000" }}>
                                    <p style={{ fontWeight: "600" }}>유의사항</p>
                                    <div dangerouslySetInnerHTML={{ __html: reservItem?.descript }}></div>
                                </div>
                            </>
                        ) : isLoading ? (
                            <div>로딩중...</div>
                        ) : (
                            <div>정보를 찾을 수 없습니다.</div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default GiftReservationPage;
