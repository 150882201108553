import React, { useEffect } from "react";
import { Container, Row, Col, Card, CardHeader, Modal, ModalBody, ModalHeader, Button, Input, ListGroup, ListGroupItem, Label } from "reactstrap";

import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { API, BACKEND_HOST } from "../../../api";

const NoticeList = (props) => {
  const navigate = useNavigate();

  const [columns, setColumns] = React.useState([]);
  const [notices, setNotices] = React.useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.get(`/notice/content/`);

        const data = response.data;

        if (data.length === 0) return;
        console.log(data);

        let columnName = Object.keys(data[0]);

        const WIDTH_MAP = { id: 20, 제목: 250, 작성일: 150, 작성자: 40 };
        let tmpColumns = [];

        // tablet 기준 제일 큰 픽셀값으로 기준값 변경해야함.
        if (window.innerWidth > 770) {
          columnName.forEach((element) => {
            const obj = {
              Header: element,
              accessor: element,
              width: WIDTH_MAP[element],
            };
            if (element === "제목") {
              // eslint-disable-next-line react/display-name
              obj.Cell = (cellProps) => {
                return (
                  <a className="text-decoration-none text-dark" href={`/notice/detail/${cellProps.row.original.id}`}>
                    {cellProps.row.original["제목"]}
                  </a>
                );
              };
            }
            tmpColumns.push(obj);
          });
        } else {
          columnName.forEach((element) => {
            // break 문 넣기
            if (element === "제목") {
              tmpColumns.push({
                Header: element,
                accessor: element,
                Cell: (cellProps) => (
                  <a className="text-decoration-none text-dark" href={`/notice/detail/${cellProps.row.original.id}`}>
                    {cellProps.row.original["제목"]}
                  </a>
                ),
                width: 300,
              });
            }
          });
        }

        setColumns(tmpColumns);
        setNotices(data);
      } catch (error) {
        console.error(error);
      }
    };

    // 함수를 호출하세요
    fetchData();
  }, []);

  return (
    <div>
      <Container className="mt-4">
        <Row className="justify-content-center">
          <Col>
            <Card id="customerList">
              <CardHeader className="border-0">
                <Row className="g-4 align-items-center">
                  <div className="col-sm-auto">
                    <div>
                      <h4 className="card-title mb-0 flex-grow-1">문의</h4>
                    </div>
                  </div>
                  <div className="col-sm-3 ms-auto">
                    <div className="d-flex justify-content-sm-end gap-2">
                      <Button
                        className="btn btn-soft-primary add-btn me-1"
                        onClick={() => {
                          navigate(`/notice/form/${0}`);
                        }}
                        id="write-btn"
                      >
                        글쓰기
                      </Button>
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <div className="card-body pt-0">
                <div>
                  {notices.length ? (
                    <TableContainer
                      columns={columns}
                      data={notices || []}
                      isGlobalFilter={true}
                      isAddUserList={false}
                      customPageSize={20}
                      className="custom-header-css"
                      isCustomerFilter={true}
                      theadClass="table-light text-muted"
                    />
                  ) : (
                    <Loader />
                  )}
                </div>

                <ToastContainer closeButton={false} />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NoticeList;
