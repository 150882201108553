import React from "react";

import Navbar from "../../../Components/Common/navbar";
import InquiryFormSection from "./InquiryFormSection";

const InquiryForm = () => {
  return (
    <div className="layout-wrapper landing" style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
      <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
        {/* <Navbar activeBar={5} /> */}
        <InquiryFormSection />
      </div>
    </div>
  );
};

export default InquiryForm;
