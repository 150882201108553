import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, Label, Input, Table } from "reactstrap";
import classnames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";

import BgFrame from "../../../Components/Common/BgFrame";

import tossPayments from "../../../common/payments/toss/api";
import { SUBDOMAIN } from "../../../assets/meta/meta";
import { API, BACKEND_HOST } from "../../../api";

import CouponTicketSelectModal from "../../../Components/modal/CouponTicketSelectModal";
import CouponReservationSelectModal from "../../../Components/modal/CouponReservationSelectModal";
import { h } from "gridjs";
import { calcCouponSaleAmount } from "../../../common/utils/coupon_util";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { getAccessToken } from "../../../api/auth";

const OrderProcess = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [width, setWidth] = React.useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [user, setUser] = useState({
    phoneNum: null,
  });
  const [name, setName] = useState("");
  const [cancelRuleAgree, setCancelRuleAgree] = React.useState(false);
  const [purchaseAllAgree, setPurchaseAllAgree] = React.useState(false);
  const [purchaseOneAgree, setPurchaseOneAgree] = React.useState(false);

  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [couponModal, setCouponModal] = useState(false);
  const [selectCoupon, setSelectCoupon] = useState([]);
  const [targetSeat, setTargetSeat] = useState({});
  const [tabList, setTabList] = useState([false, true]);

  const handleTabClick = (index) => {
    setTabList((prevTabList) => {
      const newTabList = [...prevTabList];
      newTabList[index] = !newTabList[index]; // toggle 로직
      return newTabList;
    });
  };

  const toggleTicketDiscountCouponSelection = (product_id, coupon_id, discount_amount, discount_type, min_purchase, discount_rate, max_discount = 0) => {
    setSelectCoupon((prev) => {
      let _prev = [...prev];
      const item = { coupon_id: coupon_id, product_id: product_id, discount_amount, discount_type, min_purchase, discount_rate, max_discount };
      const selected = _prev.find((item) => item.product_id === product_id && item.coupon_id === coupon_id);
      const blocked = _prev.find((item) => item.product_id !== product_id && item.coupon_id === coupon_id);

      if (blocked) {
        return _prev;
      } else {
        _prev = _prev.filter((item) => item.product_id !== product_id);
        if (!selected) {
          return [..._prev, item];
        } else {
          return _prev;
        }
      }
    });
  };

  const toggleReservationDiscountCouponSelection = (product_id, coupon_id, spot_id, discount_amount, discount_type, min_purchase, discount_rate, max_discount = 0) => {
    setSelectCoupon((prev) => {
      let _prev = [...prev];
      const item = { coupon_id: coupon_id, product_id: product_id, spot_id: spot_id, discount_amount: discount_amount, discount_type, min_purchase, discount_rate, max_discount };
      const selected = _prev.find((item) => item.product_id === product_id && item.coupon_id === coupon_id && item.spot_id === spot_id);
      const blocked = _prev.find((item) => item.product_id === product_id && item.coupon_id === coupon_id && item.spot_id !== spot_id);

      if (blocked) {
        return _prev;
      } else {
        _prev = _prev.filter((item) => item.spot_id !== spot_id);
        if (!selected) {
          return [..._prev, item];
        } else {
          return _prev;
        }
      }
    });
  };

  // useEffect(() => {
  //   console.log("location.state", location.state);
  // }, [location]);

  const toggleAllAgree = (agree) => {
    setPurchaseAllAgree(agree);
    setPurchaseOneAgree(agree);
  };
  const togglePurchaseAgreeOptions = (agree) => {
    // 임시 코드 => 하위 동의항목이 더 생기면 바꿔야함.
    setPurchaseAllAgree(agree);
    setPurchaseOneAgree(agree);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  };

  const optionsPrice = location.state.options
    .map((item) => {
      return parseInt(item.price);
    })
    .reduce((sum, currValue) => {
      return sum + currValue;
    }, 0);

  const totalOrderPrice = () => {
    let _total = 0;

    if (location.state.type === 1) {
      const _price = location.state.product.price * location.state.quantity;
      const _coupon = selectCoupon.find((coupon) => coupon.product_id === location.state.product.id);
      const saleAmount = _coupon ? calcCouponSaleAmount(_coupon, _price) : 0;
      _total += _price - saleAmount;
    } else {
      if (selectCoupon.length > 0) {
        location.state.spotId.forEach((spot) => {
          const _coupon = selectCoupon.find((coupon) => coupon.spot_id === spot.id);
          const _price = location.state.product.price;
          const saleAmount = _coupon ? calcCouponSaleAmount(_coupon, _price) : 0;
          _total += _price - saleAmount;
        });
        _total += optionsPrice;
      } else {
        _total = location.state.product.price * (location.state.type === 0 ? location.state.spotId.length : location.state.quantity) + optionsPrice;
      }
    }

    return _total;
  };

  const totalTaxfreePrice = location.state.options
  .map((item) => {
    if (item.taxfree) {
      return parseInt(item.price);
    } else {
      return 0;
    }
  })
  .reduce((sum, currValue) => {
    return sum + currValue;
  }, 0);

  useEffect(() => {
    console.log("location.state", location.state);
  }, [location.state]);

  const processPayment = async () => {
    try {
      const accessToken = getAccessToken();

      if (!accessToken) {
        alert("로그인이 필요한 서비스입니다.");
        navigate(`/login`);
        return;
      }

      if (totalOrderPrice() === 0) {
        alert("결제가능한 최소금액은 1,000원입니다.");
        return;
      }

      const response = await API.post(
        `/${location.state.type === 0 ? "package/pre-order/" : selectCoupon.length === 0 ? "ticket/pre-order/" : "ticket/pre-order-multi/"}`,
        {
          spots: location.state.type === 0 ? location.state.spotId : undefined,
          startDate: location.state.type === 0 ? location.state.selectDate : undefined,
          options: location.state.type === 0 ? location.state.options.map((item) => ({ spot_id: item.spotId, option_id: item.optionId })) : undefined,
          coupons: location.state.type === 0 ? selectCoupon.map((item) => ({ coupon_id: item.coupon_id, spot_id: item.spot_id })) : undefined,
          ticket_id: location.state.type !== 0 && selectCoupon.length === 0 ? location.state.product.id : undefined,
          quantity: location.state.type !== 0 && selectCoupon.length === 0 ? location.state.quantity : undefined,
          tickets:
            location.state.type !== 0 && selectCoupon.length > 0
              ? [
                {
                  product_id: location.state.product.id,
                  quantity: location.state.quantity,
                  coupon_id: selectCoupon.find((coupon) => coupon.product_id === location.state.product.id)
                    ? selectCoupon.find((coupon) => coupon.product_id === location.state.product.id).coupon_id
                    : "",
                },
              ]
              : undefined,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = response.data;
      console.log("data >>> ", data);

      if ("error" in data) {
        alert(data.error);
        if (location.state.type === 0) {
          navigate(`/detail/package/${location.state.product.id}`, {
            state: {
              selectedDate: location.state.selectDate,
              selectedSpotId: location.state.spotId,
            },
          });
        } else {
          navigate(`/ticket`);
        }
        return;
      }

      tossPayments.requestPayment("카드", {
        amount: totalOrderPrice(),
        taxFreeAmount: totalTaxfreePrice,
        orderId: data.orderId,
        orderName: `${location.state.product.name}`,
        customerName: name,
        successUrl: `${BACKEND_HOST}/${location.state.type === 0 ? "package" : "ticket"}/order/`,
        failUrl: `https://${SUBDOMAIN}.amazingticket.site/${location.state.type === 0 ? "reservation" : "ticket"}`,
      });
    } catch (error) {
      console.log(error);
      alert("지금은 구매가능 시간이 아니거나 품절된 상품입니다.");
      navigate(`${location.state.type === 0 ? "/reservation" : "/ticket"}`);
    }
  };

  const logOut = () => {
    alert("허용되지 않은 접근입니다! 다시 로그인 해주세요.");
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const accessToken = getAccessToken();

      if (accessToken) {
        try {
          let decoded = jwtDecode(accessToken);
          const userId = decoded["user_id"];

          const response = await API.get(`/accounts/users/${userId}/`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const data = response.data;

          if (!data["전화번호"]) {
            logOut();
          } else {
            setUser({ phoneNum: data["전화번호"], name: data["이름"] });
            setName(data["이름"]);
          }
        } catch (error) {
          console.log(error);
          logOut();
        }
      } else {
        setUser({
          phoneNum: null,
        });
      }
    };

    fetchData();
  }, [location]);

  React.useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  const addQuantityToTickets = (tickets, quantity) => {
    return tickets.map((ticket) => ({
      ...ticket,
      quantity: quantity,
    }));
  };

  // useEffect(() => {
  //   console.log("location.state", { ...location.state.product, quantity: location.state.quantity });
  // }, [location]);

  return (
    <BgFrame>
      <React.Fragment>
        <div>
          <div>
            <Row>
              <Col xl={12}>
                <div>
                  <div>
                    <div>
                      <div
                        style={{ height: "50px", border: "1px solid #e9ebec", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 20px" }}
                        onClick={() => handleTabClick(0)}
                      >
                        <h5 className="" style={{ fontFamily: "Gmarket-M", margin: 0 }}>
                          주문자
                        </h5>
                        {tabList[0] ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                      </div>
                      <div style={{ border: "1px solid #e9ebec", padding: "20px 20px", display: tabList[0] ? "block" : "none" }}>
                        <Row className="gy-3">
                          <p style={{ fontWeight: "bold", margin: "10px 0 5px 0" }}>{name === "" ? "로그인 후 구매가능합니다." : name}</p>
                          <p style={{ margin: 0, fontFamily: "Gmarket-M" }}>{user.phoneNum ? user.phoneNum.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3") : ""}</p>
                        </Row>
                      </div>
                    </div>

                    <div style={{ width: "100%", height: "10px" }}></div>

                    <div>
                      <div
                        style={{ height: "50px", border: "1px solid #e9ebec", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 20px" }}
                        onClick={() => handleTabClick(1)}
                      >
                        <h5 className="" style={{ fontFamily: "Gmarket-M", margin: 0 }}>
                          주문상품
                        </h5>
                        {tabList[1] ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                      </div>
                      <div style={{ border: "1px solid #e9ebec", padding: "20px 20px", display: tabList[1] ? "block" : "none" }}>
                        <Row className="gy-3">
                          {location.state.type === 1 ? (
                            <div className="">
                              <div>
                                <h5 className="mb-1" style={{ fontFamily: "Gmarket-M" }}>
                                  쿠폰 적용
                                </h5>
                                <p className="mb-4" style={{ color: "#f6475f", fontWeight: 500 }}>
                                  쿠폰을 적용하시면 할인된 금액으로 결제됩니다.
                                </p>
                              </div>
                              <br />

                              <div className="form-check card-radio">
                                <div className="" htmlFor="orderInfo01">
                                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <span className="mb-2 fw-semibold text-uppercase" style={{ fontFamily: "Gmarket-M" }}>
                                      {location.state.product.name}
                                      <span className="mb-2 text-uppercase" style={{ marginLeft: "5px" }}>
                                        ({location.state.product.site})
                                      </span>
                                    </span>
                                    <div className="fw-bold mt-2 btn btn-primary" onClick={() => setCouponModal(true)}>
                                      쿠폰선택하기
                                    </div>
                                  </div>

                                  <span className="fw-normal text-wrap mt-1 mb-1 d-block">{location.state.selectDate !== null ? `예약일 ${location.state.selectDate}` : null}</span>
                                  {location.state.type !== 0 ? <span className="fw-normal text-wrap mb-1 d-block">수량 {location.state.quantity}</span> : null}

                                  {location.state.spotId !== null ? (
                                    <span className="fw-normal text-wrap my-3 d-block">
                                      <span className="fw-normal text-wrap mb-1 d-block fw-semibold">좌석 및 옵션</span>
                                      {location.state.spotId.map((spot, idx, obj) => {
                                        const _options = location.state.options.filter((value) => value.spotId === spot.id);
                                        return (
                                          <div key={idx} className="fw-normal text-wrap mb-1 mx-1" style={{ borderBottom: "1px solid #ededed", padding: "10px 0" }}>
                                            <span>
                                              <span style={{ fontFamily: "Gmarket-M", color: "#f6475f" }}>{spot.name}</span>번 {location.state.product.price.toLocaleString()}원
                                            </span>
                                            <div className="d-block" style={{ textAlign: "right" }}>
                                              {_options.map((option, oidx, oobj) => {
                                                return (
                                                  <p key={oidx} className="text-muted mb-0 fs-14 mb-1">
                                                    {/* {oidx === 0 ? "- " : ""} */}
                                                    {option.name}
                                                    <span style={{ marginLeft: "5px" }}>
                                                      {option.price.toLocaleString()}원{/* {oidx !== oobj.length - 1 ? ", " : ""} */}
                                                    </span>
                                                  </p>
                                                );
                                              })}
                                            </div>

                                            {selectCoupon && selectCoupon.length > 0
                                              ? selectCoupon.map((coupon) => {
                                                const coupenSelected = selectCoupon.find((coupon) => coupon.product_id === location.state.product.id && coupon.spot_id === spot.id);
                                                return (
                                                  <div key={coupon.id} className="fw-normal text-wrap mb-1 mx-1" style={{ padding: "10px 0" }}>
                                                    {coupenSelected ? (
                                                      <>
                                                        <span className="d-block" style={{ textAlign: "right" }}>
                                                          {calcCouponSaleAmount(coupenSelected, location.state.product.price * location.state.quantity).toLocaleString()}원 할인
                                                          {/* {coupon.discount_type === "price" ? coupon.discount_amount.toLocaleString() + "원 할인" : coupon.discount_amount + "% 할인"} */}
                                                        </span>
                                                      </>
                                                    ) : null}
                                                  </div>
                                                );
                                              })
                                              : null}
                                          </div>
                                        );
                                      })}
                                    </span>
                                  ) : null}

                                  <p style={{ textAlign: "right", marginTop: "10px" }}>
                                    총 금액 <span className="w-normal text-wrap mb-1" style={{ fontFamily: "GMarket-M", color: "#F64760" }}>{` ${totalOrderPrice().toLocaleString()}원`}</span>
                                  </p>
                                </div>
                              </div>

                              <hr />
                            </div>
                          ) : (
                            <Col lg={12} sm={12}>
                              <div className="">
                                {/* <Input id="orderInfo01" name="orderInfo" type="radio" className="form-check-input" defaultChecked /> */}
                                <div className="" htmlFor="orderInfo01">
                                  <span className="mb-2 fw-semibold text-uppercase" style={{ fontFamily: "Gmarket-M" }}>
                                    {location.state.product.name}
                                    <span className="mb-2 text-uppercase" style={{ marginLeft: "5px" }}>
                                      ({location.state.product.site})
                                    </span>
                                  </span>

                                  <span className="fw-normal text-wrap mt-1 mb-1 d-block">{location.state.selectDate !== null ? `예약일 ${location.state.selectDate}` : null}</span>
                                  {location.state.type !== 0 ? <span className="fw-normal text-wrap mb-1 d-block">수량 {location.state.quantity}</span> : null}

                                  {location.state.spotId !== null ? (
                                    <span className="fw-normal text-wrap my-3 d-block">
                                      <span className="fw-normal text-wrap mb-1 d-block fw-semibold">좌석 및 옵션</span>
                                      {location.state.spotId.map((spot, idx, obj) => {
                                        const _options = location.state.options.filter((value) => value.spotId === spot.id);
                                        return (
                                          <div key={idx} className="fw-normal text-wrap mb-1 mx-1" style={{ borderBottom: "1px solid #ededed", padding: "10px 0" }}>
                                            <div>
                                              <span>
                                                <span style={{ fontFamily: "Gmarket-M", color: "#f6475f" }}>{spot.name}</span>번 {location.state.product.price.toLocaleString()}원
                                              </span>
                                            </div>
                                            <div className="d-block" style={{ textAlign: "right" }}>
                                              {_options.map((option, oidx, oobj) => {
                                                return (
                                                  <p key={oidx} className="text-muted mb-0 fs-14 mb-1">
                                                    {/* {oidx === 0 ? "- " : ""} */}
                                                    {option.name}
                                                    <span style={{ marginLeft: "5px" }}>
                                                      {option.price.toLocaleString()}원{/* {oidx !== oobj.length - 1 ? ", " : ""} */}
                                                    </span>
                                                  </p>
                                                );
                                              })}
                                              <p
                                                className="fw-bold mt-2 btn btn-primary"
                                                onClick={() => {
                                                  setCouponModal(true);

                                                  setTargetSeat({
                                                    spot_id: spot.id,
                                                    product_id: location.state.product.id,
                                                    price: location.state.product.price,
                                                    options: _options,
                                                    reserve_date: location.state.selectDate,
                                                  });
                                                }}
                                              >
                                                쿠폰선택하기
                                              </p>
                                              {selectCoupon && selectCoupon.length > 0
                                                ? selectCoupon
                                                  .filter((coupons) => coupons.product_id === location.state.product.id && coupons.spot_id === spot.id)
                                                  .map((coupenSelected) => {
                                                    return (
                                                      <div key={coupenSelected.id} className="fw-normal text-wrap mb-1 mx-1" style={{ padding: "10px 0" }}>
                                                        {coupenSelected ? (
                                                          <>
                                                            <span>쿠폰 적용 </span>
                                                            <span className="d-block" style={{ textAlign: "right", fontWeight: 600 }}>
                                                              {calcCouponSaleAmount(coupenSelected, location.state.product.price * location.state.quantity).toLocaleString()}원 할인
                                                            </span>
                                                          </>
                                                        ) : null}
                                                      </div>
                                                    );
                                                  })
                                                : null}
                                            </div>
                                            {/* {idx !== obj.length - 1 ? ", " : ""} */}
                                          </div>
                                        );
                                      })}
                                    </span>
                                  ) : null}
                                  <p style={{ textAlign: "right", marginTop: "10px" }}>
                                    총 금액 <span className="w-normal text-wrap mb-1" style={{ fontFamily: "GMarket-M", color: "#F64760" }}>{` ${totalOrderPrice().toLocaleString()}원`}</span>
                                  </p>
                                </div>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                    </div>

                    <div style={{ width: "100%", height: "10px" }}></div>

                    <div>
                      <div
                        style={{ height: "50px", border: "1px solid #e9ebec", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 20px" }}
                      // onClick={() => handleTabClick(2)}
                      >
                        <h5 className="" style={{ fontFamily: "Gmarket-M", margin: 0 }}>
                          취소 및 환불 규정
                        </h5>
                        {/* {tabList[2] ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />} */}
                      </div>
                      <div style={{ border: "1px solid #e9ebec", padding: "20px 20px" }}>
                        <Row className="gy-3">
                          <Col sm={12}>
                            {location.state.type !== 0 ? (
                              <label className="form-check-label" htmlFor="-">
                                사용기간 내의 미사용 100% 환불
                                <br />
                                (유효기간 이후 환불불가)
                                {location.state.product.bundle_count && location.state.product.bundle_count > 1 ? (
                                  <div>
                                    - 묶음상품은 전체취소(=전체환불) 가능합니다. 다만, 묶음 중 1장이라도 사용, 선물 시 환불 불가합니다.
                                    <br />
                                    - 부분취소 불가합니다.
                                    <br />
                                    - 유효기간은 구입일로 부터 1년입니다.
                                    <br />
                                    - 유효기간 만료 전까지 사용 또는 선물(양도) 해주세요.
                                    <br />
                                  </div>
                                ) : null}
                              </label>
                            ) : (
                              <div className="table-responsive">
                                <Table className="table-bordered table-nowrap align-middle mb-0">
                                  <tbody>
                                    <tr>
                                      <td className="fw-medium">이용 3일 전까지</td>
                                      <td>취소수수료없음</td>
                                    </tr>
                                    <tr>
                                      <td className="fw-medium">이용 2일 전</td>
                                      <td>총결제금액의 30% 차감</td>
                                    </tr>
                                    <tr>
                                      <td className="fw-medium">이용 1일 전</td>
                                      <td>총결제금액의 50% 차감</td>
                                    </tr>
                                    <tr>
                                      <td className="fw-medium">이용 당일</td>
                                      <td>환불불가능</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            )}
                          </Col>
                        </Row>

                        <Col sm={12} className="py-3">
                          <div className="form-check">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="cancelRuleAgree"
                              checked={cancelRuleAgree}
                              onChange={(e) => {
                                setCancelRuleAgree(e.target.checked);
                              }}
                            />
                            <label className="form-check-label" htmlFor="cancelRuleAgree">
                              동의하기
                            </label>
                          </div>
                        </Col>

                        <Col sm={12} className="">
                          <div className="form-check">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="purchaseOneAgree"
                              checked={purchaseOneAgree}
                              onChange={(e) => {
                                togglePurchaseAgreeOptions(e.target.checked);
                              }}
                            />
                            <label className="form-check-label" htmlFor="purchaseOneAgree">
                              (필수)구매조건 확인 및 결제진행에 동의
                            </label>
                          </div>
                        </Col>
                      </div>
                    </div>

                    <div className="d-f lex align-items-start gap-3 mt-4 jus pb-3">
                      <button
                        type="button"
                        className="btn ms-auto nexttab"
                        style={{ backgroundColor: "#f6475f", color: "#fff", fontFamily: "Gmarket-M", margin: "0 auto", width: "100%", padding: "12px 0" }}
                        onClick={() => {
                          processPayment();
                        }}
                        disabled={!cancelRuleAgree || !purchaseAllAgree}
                      >
                        결제하기
                        <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2" style={{ backgroundColor: "#f6475f", color: "#fff", marginLeft: "10px" }}></i>
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {location.state.type === 1 ? (
          <CouponTicketSelectModal
            setVisible={setCouponModal}
            visible={couponModal}
            ticket={{ ...location.state.product, quantity: location.state.quantity }}
            selectCoupon={selectCoupon}
            setSelectCoupon={setSelectCoupon}
            toggleTicketDiscountCouponSelection={toggleTicketDiscountCouponSelection}
            navigate={navigate}
          />
        ) : (
          <CouponReservationSelectModal
            setVisible={setCouponModal}
            visible={couponModal}
            seats={targetSeat}
            selectCoupon={selectCoupon}
            setSelectCoupon={setSelectCoupon}
            toggleReservationDiscountCouponSelection={toggleReservationDiscountCouponSelection}
            navigate={navigate}
          />
        )}
      </React.Fragment>
    </BgFrame>
  );
};

export default OrderProcess;
