import { useCallback, useEffect, useState } from "react";

export default function useElementSize() {
    const [ref, setRef] = useState(null);

    const [elementSize, setElementSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleResize = () => {
        // console.log("handleResize", ref);
        if (ref) {
            setElementSize({
                width: ref.offsetWidth,
                height: ref.offsetHeight,
            });
            // console.log("handleResize", ref.offsetWidth, ref.offsetHeight);
        }
    };

    useEffect(() => {
        handleResize();
        if (window) {
            window.addEventListener('resize', handleResize);
        }
        return () => window.removeEventListener('resize', handleResize);
    }, [ref]);

    return [elementSize, setRef];
}