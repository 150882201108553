import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Row, Col, Button, Table } from "reactstrap";
import { API, BACKEND_HOST } from "../../../api";

import Navbar from "../../../Components/Common/navbar";
import { getAccessToken } from "../../../api/auth";

const MultiOrderDone = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();

  const [orders, setOrders] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const accessToken = getAccessToken();

      if (accessToken) {
        try {
          const response = await API.get(`/package/user-order/?order_id=${orderId}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const data = response.data;
          setOrders(data.orders);
        } catch (error) {
          console.log(error);
        }
      } else {
        navigate(`/`);
      }
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff", minHeight: "100vh" }}>
          <Navbar />

          <h3 className="border-success text-center my-3 fw-bold mt-4" style={{ fontFamily: "Gmarket-M" }}>
            주문완료
          </h3>
          <Card>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <p className="table-responsive" style={{ textAlign: "center", fontSize: "16px" }}>
                    주문이 완료되었습니다.
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <div className="d-flex justify-content-center">
            <Button onClick={() => navigate("/my-page")} color="success" style={{ fontFamily: "Gmarket-M", backgroundColor: "#f6475f", boxShadow: "none", border: "none" }}>
              주문확인하러 가기
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default MultiOrderDone;
