import React, { useEffect } from "react";

import Navbar from "../../../Components/Common/navbar";
import Home from "./home";
import Footer from "./footer";
import { useUserStore } from "../../../store/store";
import MainAppDownload from "../../MainAppDownload";
import NavBottomBar from "../../../Components/Common/NavBottomBar";

// import { SitesApi } from "../../../api/dist";

const Index = ({ isWebPurchase, isWebPurchaseTicket, isManaged }) => {
  const [permission] = useUserStore((state) => [state.permission]);

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  // useEffect(() => {
  //   const api = new SitesApi();
  //   api.sitesCampsList().then((res) => {
  //     const data = res.data;
  //     console.log("api.sitesCampsList", data);
  //   }).catch((e) => console.log(e));
  // }, []);

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa", minHeight: "100vh" }}>
        <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
          <Navbar activeBar={0} permission={permission} />

          {!isWebPurchase && !isWebPurchaseTicket && isManaged && <MainAppDownload />}

          <Home permission={permission} />
          <Footer permission={permission} />
          <NavBottomBar activeBar={0} />
          {/* <button onClick={() => toTop()} className="btn btn-danger btn-icon landing-back-top" id="back-to-top">
            <i className="ri-arrow-up-line"></i>
          </button> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Index;
