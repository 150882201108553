import React from "react";
import styled from "styled-components";
import { Card, CardBody, CardHeader, Col, Collapse, Container, Row, Button } from 'reactstrap';
import { Link } from "react-router-dom";

const Item = styled.div`
    border-radius: 0.5vh;
    background-color:white;
    opacity:${props=>props.visible?"1":"0.5"};
    :hover{
        cursor: ${props=>props.visible?"pointer":"default"};
    }
`;

const Coupon = () => {
    const [coupon, setCoupon] = React.useState([])
    
    return (
        <React.Fragment>
            <Row>
                <Col className="col-12  d-flex flex-wrap justify-content-center">
                    {
                        (coupon.length !== 0) ? 
                        coupon.map((item, idx) => {
                            return (
                                <Col lg={5} xxl={5} md={7} sm={9} xs={10}  key={idx} className="mx-3 my-2 rounded-3">
                                     {item.state==="사용안함"?
                                     <Item visible={true}  className="bg-white">
                                        <div className="d-flex justify-content-between px-2 border-bottom">
                                            <div className="card-title pt-3">{item.name}</div>
                                            {item.state==="사용안함"?<p className="text-secondary fw-bold pt-3">사용가능</p>:<p className="text-muted pt-3">사용불가</p>} 
                                        </div>
                                        <div className="d-flex py-3">
                                            <div className="avatar-md mx-2">
                                                <div className="avatar-title bg-soft-success text-success fs-2 rounded">
                                                    <i className="ri-gift-fill"></i>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <p className="card-text text-muted"> 상태 : {item.state} </p>
                                                <p className="card-text text-muted">만료일 : {item.expire_date}  </p>
                                            </div>
                                        </div>
                                    </Item>
                                    :
                                    <Item visible={false} className="bg-white">
                                        <div className="d-flex justify-content-between px-2 border-bottom">
                                            <div className="card-title pt-3">{item.name}</div>
                                            {item.state==="사용안함"?<p className="text-secondary fw-bold pt-3">사용가능</p>:<p className="text-muted pt-3">사용불가</p>} 
                                        </div>
                                        <div className="d-flex py-3">
                                            <div className="avatar-md mx-2">
                                                <div className="avatar-title bg-soft-success text-success fs-2 rounded">
                                                    <i className="ri-gift-fill"></i>
                                                </div>
                                            </div>
                                            <div className="mt-1">
                                                <p className="card-text text-muted"> 상태 : {item.state} </p>
                                                <p className="card-text text-muted">만료일 : {item.expire_date}  </p>
                                            </div>
                                        </div>
                                    </Item>
                                    }
                                </Col>
                            )
                        })
                        :<div className="d-flex justify-content-center" style={{alignItems: 'center', height: 150}}>쿠폰함이 비어있습니다.</div>
                    }
                </Col>
            </Row>
        </React.Fragment>
    )
}
export default Coupon