import axios from "axios";
import { getAccessToken, removeAccessToken, setAccessToken } from "./auth";
import { SUBDOMAIN } from "../assets/meta/meta";

export const NODE_ENV = process.env.NODE_ENV;
export const BACKEND_HOST = NODE_ENV === "development" ? "https://devtotal.amazingticket.site" : "https://total.amazingticket.site";

export const API = axios.create({
  baseURL: BACKEND_HOST,
  timeout: 1000 * 5, // 5 seconds
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use(
  (config) => {
    // Modify the request config (e.g., add headers, authentication token)
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    // Handle request error
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

export const updateAccessToken = (token) => {
  setAccessToken(token);
  API.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const USER_API = axios.create({
  baseURL: BACKEND_HOST,
  timeout: 1000 * 5, // 5 seconds
  headers: {
    "Content-Type": "application/json",
  },
});

USER_API.interceptors.request.use(
  (config) => {
    // Modify the request config (e.g., add headers, authentication token)
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    // Handle request error
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

USER_API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 502 || status === 503 || (status >= 500 && status < 600)) {
        alert("서버가 불안정합니다. 잠시 후 다시 시도해주세요.");
        return Promise.reject(new Error("Server Error"));
      } else {
        alert(`예상치 못한 오류가 발생했습니다. 잠시 후 다시 시도해주세요. ${status} ${data.message || ""}`);
        console.error("Response error:", error.response);
        removeAccessToken();
        return Promise.reject(new Error("Unexpected Error"));
      }
    } else if (error.request) {
      // No response received
      alert("서버 응답이 없습니다. 잠시 후 다시 시도해주세요.");
      console.error("No response from server:", error.request);
      return Promise.reject(new Error("No response from server"));
    } else {
      // Error during setting up the request
      alert("요청을 설정하는 중에 오류가 발생했습니다.");
      console.error("Request setup error:", error.message);
      return Promise.reject(error);
    }
  }
);

export const SITE_URL = `https://${SUBDOMAIN}.amazingticket.site`;