import React from 'react';
import Navbar from './Components/Common/navbar';

const NotFoundPage = () => {
    return (
        <div className="layout-wrapper landing " style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
            <Navbar />

            <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff0f2", minHeight: "100vh" }}>
                <div style={{ padding: "50px 10px", display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <div>
                        <h1>404</h1>
                        <p>페이지를 찾을 수 없습니다!</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;