import React, { useMemo, useState } from "react";
import { Nav, NavItem, Navbar } from "reactstrap";
import { Link } from "react-router-dom";
import { HiOutlineTicket } from "react-icons/hi";
import { FiCalendar } from "react-icons/fi";
import { FiShoppingCart } from "react-icons/fi";
import { FiHome } from "react-icons/fi";
import { FiMessageSquare } from "react-icons/fi";
import { FiMessageCircle } from "react-icons/fi";

import { useUserStore } from "../../store/store";
import styled from "styled-components";

const MenuName = styled.p`
  text-align: center;
  margin-top: 4px;
  margin-bottom: 0;
  font-size: 12px;
  font-family: "Gmarket-M", sans-serif;
  color: ${(props) => (props.active ? "#ff5a5f" : "#d1d1d1")};
`;

export default function NavBottomBar(props) {
  const [isWebPurchase, isWebPurchaseTicket, isWebShopping] = useUserStore((state) => [state.isWebPurchase, state.isWebPurchaseTicket, state.isWebShopping]);
  const [activeNavBar, setActiveNavBar] = useState(props.activeBar);

  const navTabName = useMemo(() => {
    return [
      { name: "홈", url: "/", canAccess: true, visible: true },
      { name: "예약", url: "/reservation", canAccess: true, visible: isWebPurchase },
      { name: "쇼핑", url: "/shop", canAccess: true, visible: isWebShopping },
      { name: "티켓", url: "/ticket", canAccess: true, visible: isWebPurchaseTicket },
      { name: "문의", url: "/inquiry", canAccess: true, visible: true },
    ];
  }, [isWebPurchase, isWebPurchaseTicket, isWebShopping]);

  return (
    <div>
      <Navbar className="border-top" color="white" style={{ position: "fixed", bottom: 0, zIndex: 998, maxWidth: "640px", width: "100%", padding: "12px 0px" }}>
        <Nav className="d-flex flex-row justify-content-around w-100" style={{ padding: 0 }}>
          {navTabName.map((tab, idx) => {
            return (
              <React.Fragment key={tab.url}>
                {tab.name === "홈" ? (
                  <NavItem style={tab.visible ? { display: "flex", alignItems: "center", justifyContent: "center" } : { display: "none" }} key={idx}>
                    <Link to={tab.url}>
                      <FiHome size={22} color={activeNavBar === 0 ? "#ff5a5f" : "#d1d1d1"} />
                      <MenuName style={{ color: activeNavBar === 0 ? "#ff5a5f" : "#d1d1d1" }}>{tab.name}</MenuName>
                    </Link>
                  </NavItem>
                ) : tab.name === "예약" ? (
                  <NavItem style={tab.visible ? { display: "flex", alignItems: "center", justifyContent: "center" } : { display: "none" }} key={idx}>
                    <Link to={tab.url}>
                      <FiCalendar size={22} color={activeNavBar === 1 ? "#ff5a5f" : "#d1d1d1"} />
                      <MenuName style={{ color: activeNavBar === 1 ? "#ff5a5f" : "#d1d1d1" }}>{tab.name}</MenuName>
                    </Link>
                  </NavItem>
                ) : tab.name === "쇼핑" ? (
                  <NavItem
                    style={
                      tab.visible
                        ? {
                            height: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                          }
                        : { display: "none" }
                    }
                    key={idx}
                  >
                    <Link to={tab.url} style={{ transform: "translate(0px,47.5px)" }}>
                      <div
                        style={{
                          backgroundColor: "#ff5a5f",
                          transform: "translate(0px,-40px)",
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FiShoppingCart size={20} color="#fff" />
                      </div>
                      <MenuName style={{ color: "#ff5a5f", transform: "translate(0px,-37.5px)" }}>{tab.name}</MenuName>
                      {/* <p style={{ color: "#ff5a5f", fontFamily: "Gmarket-M", textAlign: "center", marginTop: "5px" }}>{tab.name}</p> */}
                    </Link>
                  </NavItem>
                ) : tab.name === "티켓" ? (
                  <NavItem style={tab.visible ? { display: "flex", alignItems: "center", justifyContent: "center" } : { display: "none" }} key={idx}>
                    <Link to={tab.url}>
                      <HiOutlineTicket size={22} color={activeNavBar === 2 ? "#ff5a5f" : "#d1d1d1"} />
                      <MenuName style={{ color: activeNavBar === 2 ? "#ff5a5f" : "#d1d1d1" }}>{tab.name}</MenuName>
                    </Link>
                  </NavItem>
                ) : tab.name === "문의" ? (
                  <NavItem style={tab.visible ? { display: "flex", alignItems: "center", justifyContent: "center" } : { display: "none" }} key={idx}>
                    <Link to={tab.url}>
                      <FiMessageCircle size={22} color={activeNavBar === 5 ? "#ff5a5f" : "#d1d1d1"} />
                      <MenuName style={{ color: activeNavBar === 5 ? "#ff5a5f" : "#d1d1d1" }}>{tab.name}</MenuName>
                    </Link>
                  </NavItem>
                ) : null}
              </React.Fragment>
            );
          })}
        </Nav>
      </Navbar>

      {/* <nav className="navbar fixed-bottom navbar-light" role="navigation">
        <Nav className="w-100">
          <div className=" d-flex flex-row justify-content-around w-100">
            <NavItem key={`tab-${1}`}>
              <Link to={"/"} className="nav-link" activeClassName="active">
                <div className="row d-flex flex-column justify-content-center align-items-center">
                  <div>test</div>
                </div>
              </Link>
            </NavItem>
          </div>
        </Nav>
      </nav> */}
    </div>
  );
}
