import React from "react";
import B2BInfoImg from "../../../assets/images/b2b-info.jpg";
import Navbar from "../../../Components/Common/navbar";

export default function B2BPreCouponInfo() {
  return (
    <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa" }}>
      <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
        <Navbar />
        <img src={B2BInfoImg} alt="banner" style={{ objectFit: "cover", width: "100%" }} />
      </div>
    </div>
  );
}
