import React from "react";

import Navbar from "../../../Components/Common/navbar";
import InquiryListSection from "./InquiryListSection";
import NavBottomBar from "../../../Components/Common/NavBottomBar";

const InquiryList = () => {
  return (
    <div className="layout-wrapper landing" style={{ minHeight: "100vh", backgroundColor: "#fff9fa" }}>
      <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
        <Navbar activeBar={5} />
        <InquiryListSection />
        <NavBottomBar activeBar={5} />
      </div>
    </div>
  );
};

export default InquiryList;
