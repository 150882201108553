import React from "react";

import { Link, useNavigate } from "react-router-dom";
import classnames from "classnames";
import { Card, Nav, NavItem, NavLink, Row, Col, CardBody, Container } from "reactstrap";

import Navbar from "../../../Components/Common/navbar";
import B2BBanner from "../../../assets/images/b2b-banner.png";
import B2BPreCouponList from "./B2BPreCouponList";
import B2BPreCouponSentList from "./B2BPreCouponSentList";

const B2BPreCouponTabView = () => {
  const navigate = useNavigate();

  // Custom Tabs Bordered
  const [customActiveTab, setcustomActiveTab] = React.useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <div className="layout-wrapper landing" style={{ backgroundColor: "#fff9fa" }}>
      <div style={{ maxWidth: "640px", margin: "0 auto", backgroundColor: "#fff" }}>
        <Navbar />
        <Link to="/my-page/b2b-info/precoupon">
          <img src={B2BBanner} alt="banner" style={{ objectFit: "cover", width: "100%" }} />
        </Link>
        <Container fluid style={{ minHeight: "100vh", backgroundColor: "white", paddingBottom: "100px" }}>
          <Row className="justify-content-center mt-4">
            <Col xxl={12} xl={12} md={12} sm={12}>
              <Card className="bg-white bg-opacity-50">
                <CardBody style={{ padding: 0 }}>
                  <Nav tabs className="nav nav-tabs nav-tabs-custom nav-danger nav-justified mb-3 bg-body bg-opacity-10">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer", fontSize: "0.8rem" }}
                        className={classnames({
                          active: customActiveTab === "1",
                        })}
                        onClick={() => {
                          toggleCustom("1");
                        }}
                      >
                        예약권 발송
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer", fontSize: "0.8rem" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        선물한 예약권
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardBody>
                <div>
                  {customActiveTab === "1" ? <B2BPreCouponList navigate={navigate} /> : null}
                  {customActiveTab === "2" ? <B2BPreCouponSentList navigate={navigate} /> : null}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default B2BPreCouponTabView;
