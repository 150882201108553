import React from "react";

import Navbar from "../../../Components/Common/navbar";
import NoticeEditor from "./NoticeEditor";

const NoticeForm = () => {

    return (
        <div className="layout-wrapper landing" style={{height: "150vh"}}>
            <Navbar />
            <NoticeEditor />
        </div>
    );
}

export default NoticeForm;